import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { background } from "../../assets/images";
import {
  CardLogin,
  CardForgotPassword,
  Header,
  Footer,
} from "../../components";

const useStyles = makeStyles((theme)=>({
  background_img: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    [`${theme.breakpoints.down('md')} and (orientation:landscape )`]: {
      minHeight :"90vw"
    },
    [`${theme.breakpoints.down('sm')} and (orientation:landscape )`]: {
      minHeight :"70vw"
    },
    [`${theme.breakpoints.down('xs')} and (orientation:landscape )`]: {
      minHeight :"80vw"
    },
  }
  }));

const LoginView = () => {
  const classes = useStyles();
  const [show, setShow] = React.useState(true);

  function loginCardToggle() {
    setShow(!show);
  }

  return (
    <>
      <Header />
      <div className={classes.background_img}>
        {show ? (
          <CardLogin onForgotPassword={loginCardToggle} />
        ) : (
          <CardForgotPassword onCancel={loginCardToggle} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default LoginView;
