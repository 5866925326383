export const SET_USER_PROFILE = "SET_USER_PROFILE";

const initialState = {
  userProfileOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      const { userProfileOpen } = action;
      return {
        ...state,
        userProfileOpen,
      };
    default:
      return state;
  }
};

export const setUserProfileDialog = (userProfileOpen = false) => ({
  type: SET_USER_PROFILE,
  userProfileOpen,
});
