/*global _appConstants */
import * as axios from "axios";
import * as qs from "querystring";
import jwt from "jwt-decode";

export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";
export const STORE_OAUTH_TOKEN = "_storeOAuthToken";
export const STORE_OTP_DATA = "_storeOtpData";
export const STORE_ACTIVE_INDEX = "_storeActiveIndex";
export const STORE_ALL_ROLES = "_storeAllRoles";
export const STORE_LOGIN_DATA = "_storeLoginData";
export const STORE_OEM_DATA = "_storeOemData";
export const CLEAR_ALL = "_clearAll";
const _storeActiveIndex = (oActiveIndex) => ({
  type: STORE_ACTIVE_INDEX,
  oActiveIndex,
});
const _storeAllRoles = (roles) => ({
  type: STORE_ALL_ROLES,
  roles,
});

const _storeOAuthToken = (oAuthData) => ({
  type: STORE_OAUTH_TOKEN,
  oAuthData,
});
const _storeLoginData = (loginUserData) => ({
  type: STORE_LOGIN_DATA,
  loginUserData,
});
const _storeOemData = (oemData) => ({
  type: STORE_OEM_DATA,
  oemData,
});
const _clearAll = () => ({ type: CLEAR_ALL });
const _storeOtpData = (otpData) => ({ type: STORE_OTP_DATA, otpData });

export function getOAuthToken(username, password) {
  return (dispatch) => {
    delete axios.defaults.headers.common["Authorization"];

    const data = {
      username,
      password,
      grant_type: "password",
    };

    // const url =
    //   "https://ownermanual.bosch-automotive.com/ownersmanual/oauth/token";

    return axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/oauth/token",
        qs.stringify(data),
        _appConstants.useCommonDefaults.appAuthorization,
        _appConstants.useCommonDefaults.app_headers
      )
      .then(function (response) {
        if (response.data && response.data.access_token) {
          axios.defaults.headers.common["Authorization"] =
            response.data.token_type + " " + response.data.access_token;
          const tokenDetails = jwt(response.data.access_token);
          console.log(
            "tokenDetails: getOAuthToken: " + response.data.access_token
          );
          dispatch(_storeOAuthToken(response.data));
        }
        return response.data;
      })
      .catch(function (err) {
        if (err.toString().toLowerCase().indexOf("network error") > -1) {
          console.log("Here");
          let data = {
            error: err,
            error_description: "Network Errror/Internet Connectivity Issue",
          };
          err.response = { data };
        }
        //for a vbgny CORS/Error cases
        return err.response.data;
      });
  };
}

export function refreshOAuthToken(refreshToken) {
  return (dispatch, state) => {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["realmName"];
    var loginBody = _appConstants.useCommonDefaults.appAuthorization;
    const refreshOauthData = Object.assign({}, loginBody);
    delete refreshOauthData.username;
    delete refreshOauthData.password;
    delete refreshOauthData.auth;
    delete refreshOauthData["client_role"];

    refreshOauthData.refresh_token = refreshToken;
    refreshOauthData.grant_type = "refresh_token";

    axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/oauth/token",
        qs.stringify(refreshOauthData),
        _appConstants.useCommonDefaults.appAuthorization,
        _appConstants.useCommonDefaults.app_headers
      )
      .then(function (response) {
        if (response.data && response.data.access_token) {
          axios.defaults.headers.common["Authorization"] =
            response.data.token_type + " " + response.data.access_token;
          axios.defaults.headers.common["realmName"] =
            _appConstants.useCommonDefaults.REALM_NAME;
          dispatch(_storeOAuthToken(response.data));
          return true;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}
export function forgotPassword(username) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/forgotPassword`,
        {
          userName: username,
        }
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        if (err.toString().toLowerCase().indexOf("network error") > -1) {
          console.log("Here", err);
          let data = {
            error: err,
            message: "Network Errror/Internet Connectivity Issue",
          };
          err.response = { data };
        }
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

// export function createData() {
//   return (dispatch) => {
//     return axios
//       .post(
//         `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user`,
//         {
//           firstName: "first",
//           lastName: "last",
//           userName: "first@bosch.com",
//           role: {
//             uid: "2a24d5a36e017705016e01c107d701bb",
//           },
//           make: {
//             uid: "4a24d5a36e017705016e01c107d701bb",
//           },
//         }
//       )
//       .then(function (response) {
//         if (response.status == "200") {
//           return true;
//         } else {
//           return false;
//         }
//       })
//       .catch(function (err) {
//         //for any CORS/Error cases
//         return false;
//       });
//   };
// }

export function register(registrationId, password) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/resetPassword/${registrationId}`,
        { password: password }
      )
      .then(function (response) {
        if (response.status == "200") {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        if (err.toString().toLowerCase().indexOf("network error") > -1) {
          console.log("Here");
          let data = {
            error: err,
            message: "Network Errror/Internet Connectivity Issue",
          };
          err.response = { data };
        }
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getRole() {
  return (dispatch) => {
    return axios
      .get("https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/role")
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getUsersAll(makeID, page, tableSize) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user?make_id=${makeID}&filter_attribute=name:&page=${page}&size=${tableSize}`
      )
      .then(function (response) {
        return response.data;
        // if (response.data) {
        //   return response.data;
        // } else {
        //   return response.data;
        // }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getLoginUser(uid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/${uid}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch(_storeLoginData(response.data));
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function updateUsers(uid, firstName, lastName, status) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/${uid}`,
        {
          firstName: firstName,
          lastName: lastName,
          isActive: status,
        }
      )
      .then(function (response) {
        if (response.data) {
          console.log("response.data", response.data);
          return response.data;
        } else {
          console.log("response", response);
          return response.data;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function updateUsersPolicy(uid, data) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/${uid}`,
        data
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function createUser(firstName, lastName, userName, roleUid, makeUid) {
  return (dispatch) => {
    return axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user",
        {
          firstName: firstName,
          lastName: lastName,
          userName: userName,
          role: {
            uid: roleUid,
          },
          make: {
            uid: makeUid,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getCountry() {
  return (dispatch) => {
    return axios
      .get(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/country"
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getParentOems(makeUid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make/all/?option=add_oem&makeUid=${makeUid}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getState(countryUid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/state/${countryUid}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getCity(stateUid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/city/${stateUid}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function createPlant(
  plantCode,
  plantName,
  address,
  country,
  state,
  city,
  makeID
) {
  return (dispatch) => {
    return axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/plant",
        {
          plantCode: plantCode,
          plantName: plantName,
          address: address ? address : null,
          country: {
            uid: country ? country : null,
          },
          state: {
            uid: state ? state : null,
          },
          city: {
            uid: city ? city : null,
          },
          make: {
            uid: makeID,
          },
        }
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function uploadDoc(languageUid, vehicleModelUid, formData) {
  return (dispatch) => {
    return axios
      .post(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/upload?languageUid=${languageUid}&vehicleModelUid=${vehicleModelUid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function uploadOemDetails(formData) {
  return (dispatch) => {
    return axios
      .post(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getLanguage() {
  return (dispatch) => {
    return axios
      .get(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/language"
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getMakeId() {
  return (dispatch) => {
    return axios
      .get("https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/make")
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getModelVariant(makeId) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/modelVariant/${makeId}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getData(url) {
  return (dispatch) => {
    return axios
      .get(url)
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getManualLanguage(vID) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/language/${vID}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getPlants(makeId) {
  return (dispatch) => {
    return axios
      .get(
        // `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/plant?make_id=${makeId}`,
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/plant?page=0&size=999`,
        {
          params: {
            make_id: makeId,
          },
        }
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getUsers(makeId, roleId) {
  const url = `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user?role_id=${roleId}&make_id=${makeId}&filter_attribute=name:&page=0&size=999`;
  return (dispatch) => {
    return (
      axios
        // <<<<<<< HEAD
        //       .get(
        //         `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user`,
        //         {
        //           params: {
        //             role_id: roleId,
        //             make_id: makeId,
        //           },
        //         }
        //       )
        // =======
        .get(url)
        // >>>>>>> origin/develop/integration
        .then(function (response) {
          if (response.data) {
            return response.data;
          } else {
            return false;
          }
        })
        .catch(function (err) {
          //for any CORS/Error cases
          return false;
        })
    );
  };
}

export function createInspection(data) {
  return (dispatch) => {
    return axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job",
        data
        // qs.stringify(data)
        // _appConstants.useCommonDefaults.app_staticUser,
        // _appConstants.useCommonDefaults.app_headers
      )
      .then(function (response) {
        return response.status;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}
export function getReportsData(makeId, object) {
  let url = "";
  if (object) {
    const objectData = _.cloneDeep(object);
    url = `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/make?make_id=${makeId}&page=0&size=999&filter_attribute=technician_id:${objectData.technicianId};plant_id:${objectData.plantId};job_status:${objectData.jobStatus};model_name:${objectData.model_Name};market_id:${objectData.marketId};year_id:${objectData.yearId}`;
  } else {
    url = `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/make?make_id=${makeId}&page=0&size=999`;
  }
  return (dispatch) => {
    return axios
      .get(url)
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        return false;
      });
  };
}

export function cancelUpdateJob(jobId, body) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/${jobId}`,
        body
      )
      .then(function (response) {
        if (response) {
          return response;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        return false;
      });
  };
}
export function ClickHandler(index) {
  return (dispatch) => {
    dispatch(_storeActiveIndex(index));
    return true;
  };
}
export function multiTabCookieHandler(data) {
  return (dispatch) => {
    dispatch(_storeOAuthToken(data));
    return true;
  };
}
export function getAllModel(makeId) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/model/all/${makeId}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}
export function getBasicDetailsbyJobid(jobId) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/basic/info/${jobId}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        console.log(err.response);
        if (err.response.status === 400) {
          return err.response.data;
        }
        return false;
      });
  };
}
export function getAllRole() {
  return (dispatch) => {
    return axios
      .get(`https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/role`)
      .then(function (response) {
        if (response.data) {
          dispatch(_storeAllRoles(response.data));
          return true;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}
export function getJobById(jobId) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/${jobId}`
      )
      .then(function (response) {
        if (response.data) return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}
export function downloadAPK() {
  return (dispatch) => {
    // return axios
    //   .get(
    //     "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/mobile/app/download"
    //   )
    //   .then(function (response) {
    //     console.log(response);
    //     if (response) {
    //       return response;
    //     } else {
    //       return false;
    //     }
    //   })
    //   .catch(function (err) {
    //     //for any CORS/Error cases
    //     return false;
    //   });
    return axios({
      url: "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/mobile/app/download",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "BQAA.apk"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
}
export function getAllSecretQuestions() {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/secret/question`
      )
      .then(function (response) {
        if (response.data) return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function patchSecretanswer(registrationId, question, answer) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/resetPassword/${registrationId}`,
        {
          secretQuestionMasterUid: question,
          secretAnswer: answer,
        }
      )
      .then(function (response) {
        if (response.status == "200") {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        if (err.toString().toLowerCase().indexOf("network error") > -1) {
          console.log("Here");
          let data = {
            error: err,
            message: "Network Errror/Internet Connectivity Issue",
          };
          err.response = { data };
        }
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}
export function uploadPolicy(docType, formData) {
  return (dispatch) => {
    return axios
      .post(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/consent/upload?documentType=${docType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}
export function uploadUtilityExcel(docType, formData) {
  return (dispatch) => {
    return axios
      .post(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/${docType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getBoshKitData() {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/boshKitData?page=0&size=999`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function updateBoshKitData(
  id,
  totalVolume,
  monthlyEstimatedVolumes,
  kitPrice,
  entryDate
) {
  return (dispatch) => {
    return axios
      .patch(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/boshKitData/${id}`,
        {
          totalVolume: totalVolume,
          monthlyEstimatedVolumes: monthlyEstimatedVolumes,
          kitPrice: kitPrice,
          entryDate: entryDate,
        }
      )
      .then(function (response) {
        if (response.data) {
          return true;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getTweddleData() {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/tweddle/erp?page=0&size=999`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getTweddleInvoiceData(
  shippedDate,
  shipmentNumber,
  shippedQuantity,
  partNumber,
  status,
  dueDate,
  startDate,
  endDate
) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/tweddle?filter_attribute=shipped_date:${shippedDate};shipment_number:${shipmentNumber};shipped_quantity:${shippedQuantity};part_number:${partNumber};status:${status};due_date:${dueDate};start_date:${startDate};end_date:${endDate}&page=0&size=999`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function getInvoiceDetails(tweddleShipmentDetailsUid, partNumber) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/tweddle/tweddleTransactionDetails/${tweddleShipmentDetailsUid}/${partNumber}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}

export function postSentInvoiceData(invoice) {
  return (dispatch) => {
    return axios
      .post(
        "https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/tweddle/status",
        invoice
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getOemDashboard(makeUid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make/all/?option=show_oem&makeUid=${makeUid}`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}
export function getAllOems() {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make?page=0&size=999`
      )
      .then(function (response) {
        if (response.data) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return false;
      });
  };
}
export function addVehicle(data, makeId) {
  //localhost:48055/ownersmanual/api/v1/vehicle/make

  http: console.log("data, makeId", data, makeId);
  return (dispatch) => {
    return axios
      .post(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make/model/${makeId}`,
        data
        // qs.stringify(data)
        // _appConstants.useCommonDefaults.app_staticUser,
        // _appConstants.useCommonDefaults.app_headers
      )
      .then(function (response) {
        return response.status;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getVehicleDetails(makeId) {
  console.log("data", makeId);
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/model/${makeId}?page=0&size=999`
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases
        return err.response.data;
      });
  };
}

export function getCompanyImage(makeUid) {
  return (dispatch) => {
    return axios
      .get(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/make/image?makeUid=${makeUid}`
      )
      .then(function (response) {
        console.log("getCompanyImage Success", response);
        return response.data;
      })
      .catch(function (err) {
        //for any CORS/Error cases

        console.log("getCompanyImage error", err);
        return err.response.data;
      });
  };
}
export function downloadTweddle() {
  return (dispatch) => {
    return axios({
      url: `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/tweddle/excel`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "tweddle.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response.data;
    });
  };
}
export function downloadMaster() {
  return (dispatch) => {
    return axios({
      url: `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/boshKitData/excel`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "master.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response.data;
    });
  };
}
