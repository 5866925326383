import React, { useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/styles/styles";
import { omLogo } from "../../assets/images";
import { useDispatch } from "react-redux";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { useHistory } from "react-router-dom";
import { OAuth as OAuthActions } from "../../js/actions";

const CardForgotPassword = ({ onCancel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");

  const onChange = useCallback((event) => {
    let valid;
    if (event.target.id === "email") {
      // case "email":
      setEmail(event.target.value);
      valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        event.target.value
      );
      if (!valid && event.target.value.length !== 0) {
        setEmailHelper("Invalid email address");
      } else {
        setEmailHelper("");
      }
      // break;
    }
  }, []);

  const submit = () => {
    dispatch(OAuthActions.forgotPassword(email)).then(function (success) {
      if (success["error"] === undefined) {
        history.push({
          pathname: "/passkey",
          state: { registrationId: success.verificationToken },
        });
        // history.push("/passkey", { registrationId: success.verificationToken });
        //
        // dispatch(
        //   setAlertDialog(
        //     true,
        //     null,
        //     "success",
        //     "Forgot Password:",
        //     "Password reset successful. Please check your registered Email Id.",
        //     "Ok",
        //     ""
        //   )
        // );
      } else dispatch(setAlertDialog(true, null, "error", "Forgot Password:", "Password reset unsuccessful. Please check your username.\nDetails Of Failure: " + success.message, "Ok", ""));
    });
  };

  return (
    <div className={classes.parentDiv}>
      <div className={classes.loginCard}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className={classes.cardHeader}>
              <img
                src={omLogo}
                alt="login_logo"
                className={classes.omLogoCard}
              />
            </div>
          </Grid>
          <Grid item>
            <Typography
              style={{ color: "#005691" }}
              variant="h3"
              align="center"
            >
              Forgot Password
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              Please enter your Username.
              {/* <br />
              We will send an email with further instructions as to how to reset
              the password. */}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              error={emailHelper.length !== 0}
              helperText={emailHelper}
              id="email"
              label="Username"
              placeholder="Enter your Email Id"
              value={email}
              onChange={(event) => onChange(event)}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3}>
              <Grid item xs>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={submit}
                  disabled={email.length === 0 || emailHelper.length !== 0}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(CardForgotPassword);
