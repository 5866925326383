import React, { useState } from "react";
import "./newPassword.css";
import { omLogo } from "../../assets/images";
function passwordValidation(password) {
  const regEx = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return regEx.test(password);
}
const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const submit = () => {
    if (newPassword === confirmPassword) {
      setPasswordMismatch(true);
      const body = {
        password: newPassword,
      };
      console.log(body);
    } else {
      setPasswordMismatch(true);
    }
  };
  return (
    <div className="login">
      <div className="logo">
        <img src={omLogo} />
      </div>
      <form id="login-form">
        <fieldset>
          <div>
            <label>
              New Password<sup>*</sup>
            </label>
            <div className="clear"></div>
            <input
              type="password"
              required
              onChange={(e) => {
                // setUserName(e.target.value);
                setNewPassword(e.target.value);
                // setUserNameInvalid(uservalidation(userName));
                setPasswordInvalid(passwordValidation(newPassword));
              }}
            />
          </div>
          <div>
            <label>
              Confirm Password<sup>*</sup>
            </label>
            <div className="clear"></div>
            <input
              type="password"
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <button className="login-submit" onClick={submit}>
            Submit
          </button>
          <div>
            {/* {
                            userName && !userNameInvalid ? <p className="form-error"><sub>*</sub> please enter a valid user name</p> : null
                        } */}
            {newPassword && !passwordInvalid ? (
              <p className="form-error">
                <sub>*</sub> password should contain atleast 8 characters.
              </p>
            ) : null}
            {passwordMismatch && confirmPassword ? (
              <p className="form-error">
                <sub>*</sub> password Mismatch.
              </p>
            ) : null}
          </div>
        </fieldset>
      </form>
    </div>
  );
};
export default React.memo(NewPassword);
