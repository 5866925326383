import { createMuiTheme } from "@material-ui/core/styles";

import Bosch_Sans from "../fonts/BoschSans-Regular.woff2";

const BoschSans = {
  fontFamily: "Bosch Sans Regular",
  fontStyle: "regular",
  fontDisplay: "swap",
  src: `
    url(${Bosch_Sans}) format('woff2')
  `,
};

export default createMuiTheme({
  palette: {
    primary: {
      main: "#005691",
    },
    secondary: {
      main: "#008ECF",
    },
    error: {
      main: "#EA0016",
    },
    success: {
      main: "#78BE20",
    },
    warning: {
      main: "#FCAF17",
    },
    info: {
      main: "#00A8B0",
    },
  },

  //basic typography weights
  typography: {
    fontFamily: "Bosch Sans Regular,Ariel",
    h1: {
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "1.75rem",
    },
    h3: {
      fontSize: "1.375rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "0.75rem",
    },
    caption: {
      color: "#005691",
      fontSize: "0.75rem",
    },
    button: {
      fontSize: "0.75rem",
    },
    displayInline: {
      color: "white",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiGrid: {
      container: {
        // padding: "1rem",
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        text: {
          color: "white",
        },
      },
    },
    MuiTypography: {
      root: {
        // padding: "0.25rem",
      },
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
        textTransform: "none",
        fontSize: "0.75em !important",
      },
    },
    MuiIconButton: {
      root: {
        fontWeight: "bold",
        textTransform: "none",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "1rem",
        fontWeight: "bold",
      },
      formControl: {
        position: "relative",
      },
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiInputBase: {
      root: {
        margin: "0.5rem 0 !important",
        padding: "0.25rrem",
        backgroundColor: "#ecf0f1",
      },
      input: { fontSize: "0.75em !important" },
    },
    MuiTextField: {
      root: {
        marginTop: "0.5rrem",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "white",
        color: "gray",
      },
    },
    MuiDrawer: {
      paper: {
        position: "relative !important",
        backgroundColor: "#293036 !important",
      },
    },
    MuiPaper: {
      // backgroundColor: "transparent",
      elevation: 12,
    },
  },
  props: {
    MuiButton: {
      root: {
        margin: "0.5em !important",
      },
      disableRipple: true,
      color: "primary",
    },
    MuiIconButton: {
      root: {
        margin: "1rem",
      },
      disableRipple: true,
      color: "primary",
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
      },
    },

    MuiCard: {
      elevation: 12,
    },
  },
});
