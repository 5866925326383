/*global _appConstants */
import * as axios from "axios";
import TokenStorage from "../../js/utils/tokenStorage";

export const STORE_LOGIN_USER = "_storeLoginUser";
export const CLEAR_LOGIN_USER = "_clearLoginUser";
export const UPDATE_LOGIN_USER = "_updateLoginUser";

const _storeLoginUser = (loginUserData) => ({
  type: STORE_LOGIN_USER,
  loginUserData,
});
const _clearLoginUser = () => ({ type: CLEAR_LOGIN_USER });

const _updateLoginUser = (profilePic) => ({
  type: UPDATE_LOGIN_USER,
  profilePic,
});

export function updateLoginUserData(profilePic) {
  return (dispatch, state) => {
    dispatch(_updateLoginUser(profilePic));
  };
}

export function getLoginUser() {
  return (dispatch, state) => {
    return axios
      .get(process.env.REACT_APP_HOST + _appConstants.apiURL.loginUser)
      .then(function (response) {
        if (response.data.role && response.data.role.roleName) {
          dispatch(_storeLoginUser(response.data));
          return response.data.role.roleName;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        return false;
      });
  };
}

export function getLoginUserNewTab() {
  return (dispatch, state) => {
    axios.defaults.headers.common["Authorization"] =
      "bearer " + TokenStorage.getToken();
    axios.defaults.headers.common["realmName"] =
      process.env.REACT_APP_REALM_NAME;
    return axios
      .get(process.env.REACT_APP_HOST + _appConstants.apiURL.loginUser)
      .then(function (response) {
        if (response.data.role && response.data.role.roleName) {
          dispatch(_storeLoginUser(response.data));
          return response.data.role.roleName;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        //for any CORS/Error cases
        //{"uid":"55c39169-8f4c-4aed-96d5-dfa9d8dec1b6","firstName":"Fleet Manager","lastName":"Fleet Manager","emailId":"abc@gmail.com","altEmaildId":null,"phoneNumber":"+919844310030","altPhoneNumber":null,"emergencyNumbers":null,"address1":null,"address2":null,"state":null,"country":null,"pincode":null,"role":{"uid":"8a0df9117303ee0a017303fb68cb000a","roleName":"FLEET_MANAGER","features":["ATT_V","GRP_D","GRP_C","DRI_R","USR_C","TRP_U","TRP_V","TRP_C","VEH_R","TRP_D","GRP_U","GRP_R","USR_U","USR_V"]}}
        //{"uid":"097b49a7-6c78-4085-9ed3-140a66ff1d0f","firstName":"John","lastName":"J","emailId":"test@gmail.com","altEmaildId":"test@gmail.com","phoneNumber":"+919844310004","altPhoneNumber":"+919844310004","emergencyNumbers":null,"address1":"#2/224, 4th Block","address2":"Silkboard, bangalore","state":"Karnataka","country":"India","pincode":"560068","role":{"uid":"8a0df9117303ee0a017303fb68cb000b","roleName":"FLEET_OWNER","features":["VEH_C","VEH_D","ATT_V","DRI_D","SUB_AL","DRI_R","ATT_U","USR_C","VEH_R","VEH_U","DRI_U","ATT_A","DRI_C","ATT_D","USR_U","USR_V"]}}
        //{"uid":"d524aadd-495e-4565-9a16-8739615bf207","firstName":"Admin","lastName":"Admin","emailId":"abc@gmail.com","altEmaildId":null,"phoneNumber":"+919844310030","altPhoneNumber":null,"emergencyNumbers":null,"address1":null,"address2":null,"state":null,"country":null,"pincode":null,"role":{"uid":"8a0df9117303ee0a017303fb68cb000c","roleName":"SUPER_ADMIN","features":["MCP_D","MAS_C","MAP_D","BUS_A","BUS_D","MCP_U","USR_C","USR_D","USR_U","BUS_V","USR_V","BUS_U"]}}
        return false;
      });
  };
}

// Password update from the profile page
export function updatePassword(oldPassword, newPassword, confirmPassword) {
  return (dispatch, state) => {
    const headers = Object.assign({}, axios.defaults.headers.common, {
      realmName: process.env.REACT_APP_REALM_NAME,
    });
    let updatePasswordData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    let updatePasswordAPI =
      process.env.REACT_APP_HOST + _appConstants.apiURL.updatePassword;

    return axios
      .request({
        method: "post",
        url: updatePasswordAPI,
        headers: headers,
        data: updatePasswordData,
      })
      .then(function (response) {
        if (
          response &&
          response.status &&
          (response.status === 201 || response.status === 204)
        ) {
          return "Password change successful";
        } else {
          return "Password change failed";
        }
      })
      .catch(function (err) {
        if (
          err.response.data.message
            .toLowerCase()
            .includes("invalid user credentials")
        ) {
          err.response.data.message = "invalid user credentials";
        }
        return "Password change failed. " + err.response.data.message;
      })
      .finally(function () {});
  };
}

export function clearLoginUserData() {
  return (dispatch, state) => {
    if (axios.defaults.headers.common["Authorization"]) {
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["realmName"];
    }
    // debugger;
    return new Promise((resolve, reject) => {
      // debugger;
      resolve(dispatch(_clearLoginUser()));
    });
  };
}

export function editUser(params) {
  return (dispatch, state) => {
    const headers = Object.assign({}, axios.defaults.headers.common, {
      realmName: process.env.REACT_APP_REALM_NAME,
    });
    let editUserUrl =
      process.env.REACT_APP_HOST +
      _appConstants.apiURL.editUser.replace("$uuid", params["uid"]);

    return axios
      .request({
        url: editUserUrl,
        method: "put",
        data: params,
        headers: headers,
      })
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch(_storeLoginUser(response.data));
          return response;
        } else {
          return "Unable to Update Driver details";
        }
      })
      .catch((err) => {
        return (
          err?.response?.data?.message || "Unable to Update Driver details"
        );
      });
  };
}

export function forgotPassword(emailId) {
  return (dispatch, state) => {
    let forgotPasswordData = {
      emailId: emailId,
      realmName: process.env.REACT_APP_REALM_NAME,
    };
    let forgotPasswordAPI =
      process.env.REACT_APP_FORGOT_PASSWORD_URL +
      _appConstants.apiURL.forgotPassword;

    return axios
      .request({
        method: "post",
        url: forgotPasswordAPI,
        data: forgotPasswordData,
      })
      .then(function (response) {
        if (response && response.status && response.status === 200) {
          return "please check Email for further steps";
        } else {
          return "Password change failed";
        }
      })
      .catch(function (err) {
        if (err?.response?.data?.message) return err.response.data.message;
        else return "Password change failed";
      })
      .finally(function () {});
  };
}
