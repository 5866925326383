import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { createInspectionIcon, createInspectionJob } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "../../js/utils/Context";

import * as _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
}));
const CreateInspection = (props) => {
  let roleID;
  const [vehicleModelUid, setVehicleModelUid] = useState("");
  const dispatch = useDispatch();
  const [technicians, setTechnicians] = useState([]);
  const [model, setModel] = useState("");
  const [modelData, setModelData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [variant, setVariant] = useState("");
  const [market, setMarket] = useState("");
  const [year, setYear] = useState("");
  const [language, setLanguage] = useState("");
  const [plant, setPlant] = useState("");
  const [strtDte, setStrtDte] = useState("");
  const [minED, setMinED] = useState("");
  const [endDte, setEndDte] = useState("");
  const [modelSelected, setModelSelected] = useState(false);
  const [variantSelected, setVariantSelected] = useState(false);
  const [marketSelected, setMarketSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);
  const [stDateSelected, setStDateSelected] = useState(false);
  const [fixedTechnicians, setFixedTechnicians] = useState([]);
  const [technicianArray, setTechnicianArray] = useState([...fixedTechnicians]);
  const userRole = useSelector((state) => state.OAuth.roles);
  const classes = useStyles();
  const history = useHistory();
  const [dateHelper, setDateHelper] = useState("");
  const [endDateHelper, setEndDateHelper] = useState("");
  const [makeSelected, setMakeSelected] = useContext(Context);

  useEffect(async () => {
    //

    // roleID = userRole.find((c) => c.name === "Technician").uid;
    //
    // if (userRole == undefined) {

    await dispatch(OAuthActions.getRole()).then(function (roles) {
      if (roles) {
        roleID = roles.find((c) => c.name === "Technician").uid;
      }
    });
    // }
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //   }
    // });
    await dispatch(OAuthActions.getModelVariant(makeSelected.uid)).then(
      function (mdlData) {
        if (mdlData) {
          setModelData(mdlData.vehicleModelVariants);
        }
      }
    );
    await dispatch(OAuthActions.getPlants(makeSelected.uid)).then(function (
      pltData
    ) {
      if (pltData) {
        debugger;
        setPlantData(
          pltData?._embedded?.plantLists === undefined
            ? []
            : pltData?._embedded?.plantLists
        );
      }
    });
    await dispatch(OAuthActions.getUsers(makeSelected.uid, roleID)).then(
      function (tchnData) {
        if (tchnData) {
          const activeUser = tchnData?._embedded?.userLists.filter(
            (m) => m.isActive === true
          );
          setTechnicians(activeUser === undefined ? [] : activeUser);
        }
      }
    );
  }, [userRole, makeSelected]);
  const refreshPage = () => {
    setModel("");
    setVariant("");
    setMarket("");
    setYear("");
    setLanguage("");
    setPlant("");
    setStrtDte("");
    setMinED("");
    setEndDte("");
    setTechnicianArray([]);
    setFixedTechnicians([]);
    setModelSelected(false);
    setVariantSelected(false);
    setMarketSelected(false);
    setYearSelected(false);
    setStDateSelected(false);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
    if (event.target.value) {
      setModelSelected(true);
      const varDt = modelData.filter((m) => m.model === event.target.value);
      setVariantData(varDt[0].variants);
      if (event.target.value !== model) {
        setVariantSelected(false);
        setMarketSelected(false);
        setYearSelected(false);
        setVariant("");
        setMarket("");
        setYear("");
        setLanguage("");
      }
    } else {
      setModelSelected(false);
      setVariantSelected(false);
      setMarketSelected(false);
      setYearSelected(false);
      setVariant("");
      setMarket("");
      setYear("");
      setLanguage("");
    }
  };
  const handleVariantChange = (event) => {
    setVariant(event.target.value);
    if (event.target.value) {
      setVariantSelected(true);
      let mrktUrl = variantData.filter((m) => m.name === event.target.value);
      mrktUrl = mrktUrl[0].links[0].href;
      dispatch(OAuthActions.getData(mrktUrl)).then(function (mrktData) {
        if (mrktData) {
          setMarketData(mrktData);
        }
      });
      if (event.target.value !== variant) {
        setMarketSelected(false);
        setYearSelected(false);
        setMarket("");
        setYear("");
        setLanguage("");
      }
    } else {
      setVariantSelected(false);
      setMarketSelected(false);
      setYearSelected(false);
      setMarket("");
      setYear("");
      setLanguage("");
    }
  };
  const handleMarketChange = (event) => {
    setMarket(event.target.value);
    if (event.target.value) {
      setMarketSelected(true);
      let yrUrl = marketData.filter((m) => m.uid === event.target.value);
      yrUrl = yrUrl[0].links[0].href;
      dispatch(OAuthActions.getData(yrUrl)).then(function (yrData) {
        if (yrData) {
          setYearData(yrData);
        }
      });
      if (event.target.value !== market) {
        setYearSelected(false);
        setYear("");
        setLanguage("");
      }
    } else {
      setMarketSelected(false);
      setYearSelected(false);
      setYear("");
      setLanguage("");
    }
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
    if (event.target.value) {
      setYearSelected(true);
      let vmUID = yearData.filter((m) => m.uid === event.target.value);
      vmUID = vmUID[0].vehicleModelDetailsUid;
      setVehicleModelUid(vmUID);
      dispatch(OAuthActions.getManualLanguage(vmUID)).then(function (lngData) {
        if (lngData) {
          setLanguageData(lngData);
        }
      });
      if (event.target.value !== year) {
        setLanguage("");
      }
    } else {
      setYearSelected(false);
      setLanguage("");
    }
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handlePlantChange = (event) => {
    setPlant(event.target.value);
  };
  const handleStartDate = (event) => {
    if (event.target.value) {
      setStrtDte(event.target.value);
      const strtDaate = new Date(event.target.value).getTime();
      const tdyDaate = new Date(today).getTime();
      if (strtDaate >= tdyDaate) {
        setDateHelper("");
        setStDateSelected(true);
        setEndDte("");
        let date = new Date(event.target.value);
        date.setDate(date.getDate() + 1);
        date = moment(date).format("YYYY-MM-DD");
        setMinED(date);
      } else {
        // setStrtDte('');
        setDateHelper("Start date can't be previous date");
        setEndDte("");
        setStDateSelected(false);
      }
    } else {
      setStDateSelected(false);
    }
  };
  const handleEndDate = (event) => {
    setEndDte(event.target.value);
    const endDaate = new Date(event.target.value).getTime();
    const stDaate = new Date(strtDte).getTime();
    if (endDaate <= stDaate) {
      setEndDateHelper("End Date can't be less than start date");
    } else {
      setEndDateHelper("");
    }
  };
  let today = new Date();
  today = moment(today).format("YYYY-MM-DD");

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={createInspectionIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Create Inspection:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img
              src={createInspectionJob}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Model"
                  id="model-select"
                  value={model}
                  onChange={handleModelChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {modelData.length &&
                    modelData.map((data, i) => {
                      console.log("data: ", data, " i:", i);
                      return (
                        <MenuItem key={data.model} value={data.model}>
                          {data.model}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Variant"
                  id="variant-select"
                  value={variant}
                  onChange={handleVariantChange}
                  disabled={!modelSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {variantData.length &&
                    variantData.map((data) => (
                      <MenuItem key={data.name} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Market"
                  id="market-select"
                  value={market}
                  onChange={handleMarketChange}
                  disabled={!variantSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {marketData.length &&
                    marketData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Year of manufacturing"
                  id="year-select"
                  value={year}
                  onChange={handleYearChange}
                  disabled={!marketSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {yearData.length &&
                    yearData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.yearOfManufacturing}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Language"
                  id="language-select"
                  value={language}
                  onChange={handleLanguageChange}
                  disabled={!yearSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {languageData.length &&
                    languageData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.language}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Plant"
                  id="plant-select"
                  value={plant}
                  onChange={handlePlantChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {plantData.length &&
                    plantData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.plantName}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  error={dateHelper.length !== 0}
                  helperText={dateHelper}
                  label="Start date"
                  type="date"
                  value={strtDte}
                  inputProps={{
                    min: today,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleStartDate}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  error={endDateHelper.length !== 0}
                  helperText={endDateHelper}
                  label="End Date"
                  type="date"
                  value={endDte}
                  inputProps={{
                    min: minED,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEndDate}
                  disabled={!stDateSelected}
                />
              </Grid>
              <Grid item xs={9} sm={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      options={technicians}
                      value={technicianArray}
                      onChange={(event, newValue) => {
                        // if (newValue.length && newValue.length < 2) {
                        //     setFixedTechnicians([newValue[0]]);
                        // }
                        // if (newValue.length && newValue.length >= 2) {
                        //     setFixedTechnicians([]);
                        // }
                        setTechnicianArray([
                          ...fixedTechnicians,
                          ...newValue.filter(
                            (option) => fixedTechnicians.indexOf(option) === -1
                          ),
                        ]);
                      }}
                      getOptionLabel={(option) =>
                        `${option.firstName} ${option.lastName}`
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={`${option.firstName} ${option.lastName}`}
                            {...getTagProps({ index })}
                            disabled={fixedTechnicians.indexOf(option) !== -1}
                          />
                        ))
                      }
                      // style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          label="Technicians"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        const technicianList = [];
                        technicianArray.map((data) => {
                          technicianList.push(data.uid);
                        });
                        const data = {
                          languageUid: language,
                          vehicleModelUid,
                          plantUid: plant,
                          technicianList,
                          expectedStartDate: new Date(strtDte).setHours(
                            0,
                            0,
                            0,
                            0
                          ),
                          expectedEndDate: new Date(endDte).setHours(
                            0,
                            0,
                            0,
                            0
                          ),
                        };
                        dispatch(OAuthActions.createInspection(data)).then(
                          function (status) {
                            if (status) {
                              if (status === 201) {
                                dispatch(
                                  setSnackbar(
                                    true,
                                    "success",
                                    "Inspection Job Successfully Created"
                                  )
                                );
                                history.push("/dashboard");
                              } else
                                dispatch(
                                  setAlertDialog(
                                    true,
                                    null,
                                    "error",
                                    "Inspection Job:",
                                    "Inspection job creation unsuccessful.\nDetails Of Failure: " +
                                      status.message,
                                    "Ok",
                                    ""
                                  )
                                );
                              refreshPage();
                            }
                          }
                        );
                      }}
                      disabled={
                        !model ||
                        !variant ||
                        !market ||
                        !year ||
                        !language ||
                        !plant ||
                        !strtDte ||
                        !endDte ||
                        !technicianArray.length ||
                        endDateHelper.length !== 0
                      }
                    >
                      Create Inspection Job
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default CreateInspection;
