import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Grid, CssBaseline } from "@material-ui/core";
import { boschLogo, boschBanner } from "../../../src/assets/images";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    boxShadow: "initial",
  },
  appBar: {
    background: "transparent",
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    display: "inline-block",
    height: "2rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  banner: {
    height: "1.5vh",
  },
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <img
          className={classes.banner}
          alt="banner"
          src={boschBanner}
          height="1vh"
        />
        <Grid container alignItems="center" container direction="row">
          <Grid item xs={12}>
            <Toolbar>
              <Grid alignItems="center" container direction="row">
                <Grid item sm={8} xs={6}>
                  {/* <img className={classes.logo} alt="OMimg" src={boschLogo} /> */}
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

export default Header;
