import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { OAuth as OAuthActions } from "../../../../js/actions";
import { useDispatch } from "react-redux";

const CustomBreadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const dispatch = useDispatch();
  const pathnames = pathname.split("/").filter((x) => x);

  String.prototype.toTitle = function () {
    return this.replaceAll(/(^|\s)\S/g, function (t) {
      return t.toUpperCase();
    });
  };
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {pathnames.length > 0 ? (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(OAuthActions.ClickHandler(0));
            history.push("/dashboard");
          }}
        >
          Home
        </Link>
      ) : (
        <Typography> </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>
            {name.replaceAll("-", " ").toTitle()}
          </Typography>
        ) : (
          <Link
            key={name}
            style={{ cursor: "pointer" }}
            onClick={() => history.push(routeTo)}
          >
            {name.replaceAll("-", " ").toTitle()}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(CustomBreadcrumbs);
