import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { background } from "../../assets/images";
import { NewUserCard, Header, Footer } from "../../components";

const useStyles = makeStyles((theme)=>({
  background_img: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    [`${theme.breakpoints.down('md')} and (orientation:landscape )`]: {
      minHeight :"80vw"
    },
    [`${theme.breakpoints.down('sm')} and (orientation:landscape )`]: {
      minHeight :"80vw"
    },
    [`${theme.breakpoints.down('sm')} and (orientation:portrait)`]: {
      height :"110vh"
    },
    [`${theme.breakpoints.down('xs')} and (orientation:landscape )`]: {
      height :"90vw"
    },
    [`${theme.breakpoints.down('xs')} and (orientation:portrait)`]: {
      height :"110vh"
    },
  }
  }));

const NewUser = () => {
  const classes = useStyles();

  let url = window.location.href;
  let last_section = url.replace(/\/+$/, "").split("/").pop();

  console.log("last_section", last_section);

  return (
    <>
      <Header />
      <div className={classes.background_img}>
        <NewUserCard id={last_section} />
      </div>
      <Footer />
    </>
  );
};

export default NewUser;
