import React, { useState } from "react";
// import { omLogo } from "../../assets/images";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MaterialTable, { MTableEditRow } from "material-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useCustomStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2rem 0",
    height: "100%",
    backgroundColor: "white",

    border: "2px solid whitesmoke",
    fontSize: "0.75rem",
  },
  IconDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "transparent",
    cursor: "default",
    pointerEvents: "none",
  },
  editRow: {
    backgroundColor: "#01579B",
    color: "white !important",
  },

  MTablePaginationInner: {
    MuiButtonBase: {
      backgroundColor: "red",
    },
  },
  MTableAction: {
    backgroundColor: "red",
  },
}));
const CustomTable = ({
  isReadOnly,
  isOnclick,
  dataSet,
  columns,
  updateData,
  onRowClick,
  actions,
  exportOption,
  searchState,
  tableLayout,
  fixedColumns,
  exportFileName,
  selection,
  selectionProps,
}) => {
  const classes = useCustomStyles();
  const [state, setState] = useState({
    showLoading: false,

    tableIcons: {
      Export: (props) => (
        <Grid item style={{ fontSize: "15px" }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<CloudDownloadOutlinedIcon />}
          >
            Export
          </Button>
        </Grid>
      ),
    },
  });
  const searchFlag = searchState !== undefined && searchState ? true : false;
  return (
    <div className={classes.root}>
      <MaterialTable
        title=""
        // tableLayout="fixed"
        style={{ width: "100%", padding: "1rem", boxShadow: "none" }}
        // onRowClick={(event, rowData) => {
        //   if (isOnclick) {
        //     new Promise((resolve) => {
        //       setTimeout(() => {
        //         resolve();
        //         onRowClick(event, rowData);
        //       });
        //     });
        //   }
        // }}
        components={{
          EditRow: (props) => {
            return (
              <MTableEditRow
                className={classes.editRow}
                {...props}
                onEditingCanceled={(mode, rowData) => {
                  props.onEditingCanceled(mode);
                }}
              />
            );
          },
        }}
        page={0}
        count={10}
        columns={columns}
        data={dataSet}
        icons={state.tableIcons}
        isLoading={false}
        actions={actions}
        editable={
          isReadOnly
            ? null
            : {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    updateData(newData, oldData);
                    resolve();
                  }, 1000),
              }
        }
        options={{
          selection: selection == undefined ? false : selection,
          selectionProps: selectionProps,
          fixedColumns: fixedColumns,
          tableLayout: tableLayout == undefined ? "auto" : tableLayout,
          showTitle: true,
          actionsColumnIndex: -1,
          showFirstLastPageButtons: false,
          exportButton: exportOption,
          exportAllData: true,
          exportFileName: exportFileName,
          headerStyle: {
            fontSize: "1rem",
            fontWeight: "bold",
            padding: "1rem 0",
            paddingLeft: "1rem",
          },
          paginationType: "stepped",
          search: searchFlag,
          toolbar: searchFlag,

          actionsCellStyle: () => {
            return { backgroundColor: "#F5F5F5" };
          },
        }}
      />
    </div>
  );
};

export default React.memo(CustomTable);
