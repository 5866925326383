import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { contentUploadIcon, contentUpload } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useDispatch } from "react-redux";
import { OAuth as OAuthActions } from "../../js/actions";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
}));
const PolicyUpload = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("");

  // const [yearSelected, setYearSelected] = useState(false);
  const [file, setFile] = useState(null);
  const [localPath, setLocalPath] = useState("");
  const classes = useStyles();
  const history = useHistory();

  const bindScreen = (e) => {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  useEffect(async () => {}, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      setFile(file);
      setLocalPath(
        file.name + "(" + (file.size / (1024 * 1024)).toFixed(2) + " MB )"
      );
      console.log(file);
    }
  };

  const submit = () => {
    let formData = new FormData();
    formData.append("file", file);
    window.addEventListener("beforeunload", bindScreen, true);
    console.log("value", value);
    dispatch(OAuthActions.uploadPolicy(value, formData)).then(function (
      success
    ) {
      window.removeEventListener("beforeunload", bindScreen, true);
      if (success["error"] === undefined) {
        dispatch(setSnackbar(true, "success", "Upload document Successful"));
        history.push("/dashboard");
      } else {
        dispatch(
          setAlertDialog(
            true,
            null,
            "error",
            "Document Upload:",
            "Document upload was unsuccessful. Please check if the provided file is a PDF and try again.\nDetails Of Failure: " +
              success.message,
            "Ok",
            ""
          )
        );
      }
    });
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={contentUploadIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Policy Upload:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img
              src={contentUpload}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Document Type:</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="PRIVACY_POLICY"
                      control={<Radio />}
                      label="Privacy Policy"
                    />
                    <FormControlLabel
                      value="TERMS_AND_CONDITIONS"
                      control={<Radio />}
                      label="Terms and conditions"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={9} sm={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <input
                      accept="application/pdf"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      onChange={onFileChange}
                    />
                    <TextField
                      fullWidth
                      required
                      id="filled-required"
                      label="Local Path"
                      value={localPath}
                      InputProps={{
                        endAdornment: (
                          <>
                            <label htmlFor="contained-button-file">
                              <Button
                                size="large"
                                variant="contained"
                                component="span"
                                startIcon={<AttachmentIcon />}
                              >
                                Browse
                              </Button>
                            </label>
                          </>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        const data = {
                          value,
                          localPath,
                        };
                        console.log(data);
                        submit();
                      }}
                      disabled={!value || !localPath}
                    >
                      Upload Content
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default PolicyUpload;
