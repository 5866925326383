import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListStyles from "./ListStyles";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileDialog } from "../../../../../../js/reducers/userProfileDialog";
import { OAuth as OAuthActions } from "../../../../../../js/actions";
import { Context } from "../../../../../../js/utils/Context";
import { setSnackbar } from "../../../../../../js/reducers/snackbar";

ListMenuNav.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  pages: PropTypes.array.isRequired,
};

export default function ListMenuNav(props) {
  const dispatch = useDispatch();
  const classes = ListStyles();
  const activeLocationPath = window.location.pathname;
  const { history, isOpen, pages } = props;
  // let activeRouterChildIndex = -1;
  let activeRouterChildOpen = false;

  const [makeSelected, setMakeSelected] = useContext(Context);

  const actRouterIndex = (pathName) => {
    let rIndex = -1;
    pages.map((menu, mIndex) => {
      if (menu.href === (pathName || activeLocationPath)) {
        rIndex = mIndex;
      } else if (menu.subMenus && menu.subMenus.length > 0 && rIndex < 0) {
        const tempIndex = menu.subMenus.findIndex(
          (sm) => sm.href === activeLocationPath
        );
        if (tempIndex >= 0 && rIndex < 0) {
          rIndex = mIndex;
          // activeRouterChildIndex = tempIndex;
          activeRouterChildOpen = true;
        }
      }
    });
    return pages && !pages[rIndex] ? rIndex - 1 : rIndex;
  };

  // const activeRouterIndex = actRouterIndex();
  // const [open, setOpen] = React.useState(activeRouterChildOpen);
  const open = activeRouterChildOpen;
  // const [activeIndex, setActiveIndex] = React.useState(activeRouterIndex);
  const indexActive = useSelector((state) => state.OAuth || {});
  const menuClickHandler = (mIndex, cIndex, ignorePush) => {
    // debugger;
    if (makeSelected.uid == "") {
      dispatch(
        setSnackbar(
          true,
          "info",
          "Please select an OEM before starting to utilize the application's features."
        )
      );
      return;
    }
    if (indexActive.index === mIndex && typeof cIndex === "undefined") {
      if (indexActive.authData.role === "ANALYST") {
        if (
          mIndex === 1 &&
          history.location.pathname === "/inspection-job-report/job-details"
        ) {
          history.goBack();
          return;
        }
        return;
      } else {
        if (
          (mIndex === 1 &&
            history.location.pathname === "/manage-users/add-user") ||
          (mIndex === 6 &&
            history.location.pathname === "/assign-Transfer-job/job-details") ||
          (mIndex === 7 &&
            history.location.pathname === "/inspection-job-report/job-details")
        ) {
          history.goBack();
          return;
        }
        return;
      }
      // if ((mIndex === 1 && history.location.pathname === '/manage-users/add-user') || (mIndex === 6 && history.location.pathname === '/assign-Transfer-job/job-details') || (mIndex === 7 && history.location.pathname === '/inspection-job-report/job-details')) {
      //   history.goBack();
      //   return;
      // }
      // return;
    }
    // setActiveIndex(mIndex);
    dispatch(OAuthActions.ClickHandler(mIndex));
    if (pages[mIndex].href) {
      //
      if (history.location.pathname.lastIndexOf("/") !== 0) {
        history.location.pathname = "/";
      }
      history.push(pages[mIndex].href);
    } else {
      // debugger;
    }
  };

  const profileClickHandler = () => {
    //
    dispatch(setUserProfileDialog(true));
  };

  useEffect(() => {
    const pushedPathIndex = actRouterIndex(window.location.pathname);
    if (pushedPathIndex !== indexActive.index) {
      if (pushedPathIndex >= 0 && indexActive.index > pushedPathIndex) {
        menuClickHandler(pushedPathIndex, 0);
      }
      // else if (indexActive.index === 0 && pushedPathIndex < 0) {
      //   switch (window.location.pathname) {
      //     default:
      //       break;
      //   }
      // }
    }
    //return () => { document.removeEventListener('mousedown', handleClickOutside);} //component will unmount
  }, [window.location.pathname]);

  return (
    <List component="nav" className={classes.root}>
      {pages.map((page, index) => (
        <React.Fragment key={page.title}>
          <ListItem
            button
            className={clsx(
              classes.item,
              indexActive.index === index ? classes.selected : ""
            )}
            onClick={() => menuClickHandler(index)}
          >
            <ListItemIcon
              className={
                page.title === "Profile"
                  ? classes.icon
                  : classes.profileListItem
              }
            >
              <img
                src={
                  require(`../../../../../../assets/images/svg/${page.icon}_white.svg`)
                    .default
                }
                style={{ height: "2vh", width: "2vw" }}
              />
              {/* <Icon name={name} stroke="white" width="100" /> */}
            </ListItemIcon>
            <ListItemText
              primary={isOpen ? page.title : ""}
              className={classes.itemTextWrap}
            />
            <span
              className={clsx(
                classes.itemToggleIcon,
                open && index === indexActive.index
                  ? classes.ProfileSelected
                  : "",
                isOpen ? "" : "visibleHidden"
              )}
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}

      <React.Fragment key={"Profile"}>
        <ListItem
          button
          className={clsx(classes.item, classes.profileStyle)}
          onClick={() => profileClickHandler()}
        >
          <ListItemIcon className={classes.profileListItem}>
            <img
              src={
                require(`../../../../../../assets/images/svg/username_icon.svg`)
                  .default
              }
              className={classes.iconImage}
            />
          </ListItemIcon>
          <ListItemText
            primary={isOpen ? "User Profile" : ""}
            className={classes.itemTextWrap}
          />
          <span
            className={clsx(
              classes.itemToggleIcon,
              open === indexActive.index ? "expand" : "",
              isOpen ? "" : "visibleHidden"
            )}
          />
        </ListItem>
        <Divider />
      </React.Fragment>
    </List>
  );
}
