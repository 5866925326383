import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import {
  RouteWithLayout,
  CorporateInformation,
  DataProtectionPolicy,
} from "./components";
import { Main as MainLayout } from "./layouts";
import { createBrowserHistory } from "history";

import {
  LoginView,
  Dashboard,
  NotFound,
  OnboardNewPlant,
  AddUser,
  NewUser,
  ContentUpload,
  UserProfile,
  ManageUsers,
  ManageRoles,
  AddRole,
  // NoInternet,
  CreateInspection,
  ManageInspection,
  InspectionDashboard,
  ManageInspectionDetails,
  InspectionJobReport,
  JobDetails,
  Passkey,
  PolicyUpload,
  UtilityUpload,
  ManageBoschMasterData,
  ManageTweddleData,
  Instructions,
  AddOem,
  ManageOem,
  AddVehicle,
  ManageVehicle,
  ManageInvoiceData,
  ViewInvoiceDetails,
} from "./views";

const browserHistory = createBrowserHistory();

const Routes = () => {
  // const [connection, setConnection] = React.useState(true);
  // window.addEventListener("offline", function (e) {
  //   console.log("offline");
  //   setConnection(false);
  //   return <NoInternet />;
  // });

  // window.addEventListener("online", function (e) {
  //   console.log("online");
  //   setConnection(true);
  // });
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route component={LoginView} exact path="/login" />
      <Route component={Passkey} exact path="/passkey" />
      <Route component={NewUser} exact path="/register/*" />
      <Route
        component={DataProtectionPolicy}
        exact
        path="/DataProtectionNotice"
      />
      <Route
        component={CorporateInformation}
        exact
        path="/CorporateInformation"
      />
      <RouteWithLayout
        history={browserHistory}
        component={Dashboard}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        history={browserHistory}
        component={AddUser}
        exact
        layout={MainLayout}
        path="/manage-users/add-user"
      />
      <RouteWithLayout
        history={browserHistory}
        component={OnboardNewPlant}
        exact
        layout={MainLayout}
        path="/onboard-new-plant"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ContentUpload}
        exact
        layout={MainLayout}
        path="/content-upload"
      />
      <RouteWithLayout
        history={browserHistory}
        component={UserProfile}
        exact
        layout={MainLayout}
        path="/user-profile"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageUsers}
        exact
        layout={MainLayout}
        path="/manage-users"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageRoles}
        exact
        layout={MainLayout}
        path="/manage-roles"
      />
      <RouteWithLayout
        history={browserHistory}
        component={AddRole}
        exact
        layout={MainLayout}
        path="/manage-roles/add-role"
      />
      <RouteWithLayout
        history={browserHistory}
        component={InspectionDashboard}
        exact
        layout={MainLayout}
        path="/inspection-job-dashboard"
      />
      <RouteWithLayout
        history={browserHistory}
        component={CreateInspection}
        exact
        layout={MainLayout}
        path="/create-inspection-job"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageInspection}
        exact
        layout={MainLayout}
        path="/assign-Transfer-job"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageInspectionDetails}
        exact
        layout={MainLayout}
        path="/assign-Transfer-job/job-details"
      />
      <RouteWithLayout
        history={browserHistory}
        component={InspectionJobReport}
        exact
        layout={MainLayout}
        path="/inspection-job-report"
      />
      <RouteWithLayout
        history={browserHistory}
        component={JobDetails}
        exact
        layout={MainLayout}
        path="/inspection-job-report/job-details"
      />
      <RouteWithLayout
        history={browserHistory}
        component={NotFound}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <RouteWithLayout
        history={browserHistory}
        component={PolicyUpload}
        exact
        layout={MainLayout}
        path="/policy-upload"
      />
      <RouteWithLayout
        history={browserHistory}
        component={UtilityUpload}
        exact
        layout={MainLayout}
        path="/utility-upload"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageBoschMasterData}
        exact
        layout={MainLayout}
        path="/manage-bosch-master-data"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageTweddleData}
        exact
        layout={MainLayout}
        path="/manage-tweddle-data"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageTweddleData}
        exact
        layout={MainLayout}
        path="/manage-tweddle-data"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageOem}
        exact
        layout={MainLayout}
        path="/manage-oem"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ManageVehicle}
        exact
        layout={MainLayout}
        path="/manage-vehicle"
      />
      <RouteWithLayout
        history={browserHistory}
        component={AddOem}
        exact
        layout={MainLayout}
        path="/manage-oem/add-oem"
      />
      <RouteWithLayout
        history={browserHistory}
        component={AddVehicle}
        exact
        layout={MainLayout}
        path="/manage-vehicle/add-vehicle"
      />
      //-----
      <RouteWithLayout
        history={browserHistory}
        component={ManageInvoiceData}
        exact
        layout={MainLayout}
        path="/manage-invoice-data"
      />
      <RouteWithLayout
        history={browserHistory}
        component={ViewInvoiceDetails}
        exact
        layout={MainLayout}
        path="/manage-invoice-data/invoice-details"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
