import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { assignTransferJobIcon } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setSnackbar } from "../../js/reducers/snackbar";
import {
  OAuth as OAuthActions,
  UserProfile as UserProfileActions,
} from "../../js/actions";
import { useDispatch } from "react-redux";
import MaterialTable, { MTableEditRow } from "material-table";
import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { CustomTable } from "../../components";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import * as _ from "lodash";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import Tooltip from "@material-ui/core/Tooltip";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    // border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  IconDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "transparent",
    cursor: "default",
    pointerEvents: "none",
  },

  avatar: {
    color: theme.palette.getContrastText("#01579B"),
    backgroundColor: "#01579B",
    width: "25px",
    height: "25px",
  },
  editRow: {
    backgroundColor: "#01579B",
    color: theme.palette.getContrastText("#01579B"),
  },
  new: {
    backgroundColor: "#0B5C4A",
    color: "#fff",
  },
  inprogress: {
    backgroundColor: "#249BBE",
    color: "#fff",
  },
  completed: {
    backgroundColor: "#ffcdd2",
    color: "#fff",
  },
  cancelled: {
    backgroundColor: "#000000",
    // padding: "0.75em",
    color: "#fff",
  },
  MTablePaginationInner: {
    MuiButtonBase: {
      backgroundColor: "red",
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 30,
    textTransform: "none",
  },
}));
const ManageInspection = (props) => {
  // let makeID;
  const [selectedRow, setSelectedRow] = useState({});
  let roleID;
  const [useEffectRecall, setUseEffectRecall] = useState(false);
  const [makId, setMakId] = useState("");
  const dispatch = useDispatch();
  const [model, setModel] = useState("");
  const [modelData, setModelData] = useState([]);
  const [market, setMarket] = useState("");
  const [marketData, setMarketData] = useState([]);
  const [year, setYear] = useState("");
  const [yearData, setYearData] = useState([]);
  const [plant, setPlant] = useState("");
  const [plantData, setPlantData] = useState([]);
  const [status, setStatus] = useState("");
  const [technician, setTechnician] = useState("");
  const [technicianData, setTechnicianData] = useState([]);
  const [jobId, setJobId] = useState("");
  const [openIdleDialog, setopenIdleDialog] = useState(false);
  const classes = useStyles();
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [state, setState] = useState({
    dataSet: [],
    showLoading: false,
  });
  const columns = [
    // {
    //   title: "Job ID",
    //   field: "jobId",
    //   editable: "never",
    //   cellStyle: {
    //     backgroundColor: "#F5F5F5",
    //   },
    // },
    {
      title: "Model",
      field: "modelName",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
        width: "120px",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.modelName}
          size="small"
        />
      ),
    },
    {
      title: "Market",
      field: "market",
      editable: "never",
      editComponent: (rowData) => (
        <TextField
          id="GRD_edit-country"
          variant="outlined"
          defaultValue={rowData.rowData.market}
          size="small"
        />
      ),
    },
    {
      title: "Year",
      field: "year",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.year}
          size="small"
        />
      ),
    },
    {
      title: "Inspection Date",
      field: "inspectionDate",
      editable: "never",
      cellStyle: {
        width: "120px",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={moment(rowData.rowData.inspectionDate).format(
            "DD/MM/YYYY"
          )}
          size="small"
        />
      ),
    },
    {
      title: "Technician",
      field: "technician",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      render: (rowData) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Avatar size={25} round={true} className={classes.avatar}></Avatar>
          </Grid>
          {rowData.technicians.length && (
            <Grid item xs>
              {rowData.technicians.map((value, i) =>
                rowData.technicians.length !== i + 1 ? (
                  <Typography>{`${value},`}</Typography>
                ) : (
                  <Typography>{`${value}`}</Typography>
                )
              )}
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      title: "Plant Details",
      field: "plantDetails",
      editable: "never",
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.plantDetails}
          size="small"
        />
      ),
    },
    {
      title: "Status",
      field: "status",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      lookup: {
        COMPLETED: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.completed}
          >
            <Grid item>
              <DoneAllOutlinedIcon />
            </Grid>
            <Grid item>Completed</Grid>
          </Grid>
        ),
        NEW: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.new}
          >
            <Grid item>
              <DescriptionOutlinedIcon />
            </Grid>
            <Grid item>New</Grid>
          </Grid>
        ),
        IN_PROGRESS: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.inprogress}
          >
            <Grid item>
              <HourglassEmptyOutlinedIcon />
            </Grid>
            <Grid item>In Progress</Grid>
          </Grid>
        ),
        CANCELLED: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.cancelled}
          >
            <Grid item>
              <HighlightOffOutlinedIcon />
            </Grid>
            <Grid item>Cancelled</Grid>
          </Grid>
        ),
      },

      editComponent: (rowData) => (
        <Select
          defaultValue={rowData.rowData.status}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={1}>Present</MenuItem>
          <MenuItem value={0}>Absent</MenuItem>
        </Select>
      ),
    },
  ];
  const action = [
    (rowData) => {
      return rowData.status !== "CANCELLED" && rowData.status !== "Cancelled"
        ? {
            icon: () => (
              <Tooltip title="Cancel Job" arrow>
                <HighlightOffOutlinedIcon color="primary" />
              </Tooltip>
            ),
            disable: false,
            onClick: async (event, rowData) => {
              setSelectedRow(rowData);
              setopenIdleDialog(true);
            },
          }
        : null;
    },
    (rowData) => {
      return rowData.status !== "CANCELLED" && rowData.status !== "Cancelled"
        ? {
            icon: () => (
              <Tooltip title="Reassign Or Transfer Job" arrow>
                <Avatar size={25} round={true} className={classes.editRow}>
                  <SwapHorizIcon />
                </Avatar>
              </Tooltip>
            ),
            disable: false,
            onClick: async (event, rowData) => {
              props.history.push({
                pathname: _appConstants.useCommonDefaults.navRef.inspection,
                rowdata: rowData,
              });
            },
          }
        : null;
    },
  ];
  // const userRole = useSelector((state) => state.OAuth.roles);
  useEffect(async () => {
    setUseEffectRecall(false);
    // let makeID = "";
    // console.log("not undefined");
    // if (userRole == undefined) {
    //
    await dispatch(OAuthActions.getRole()).then(function (roles) {
      if (roles) {
        roleID = roles.find((c) => c.name === "Technician").uid;
      }
    });
    // }

    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     setMakId(makeId.uid);
    //   }
    // });
    if (!jobId) {
      const filters = {
        technicianId: technician ? technician.uid : "",
        plantId: plant ? plant.uid : "",
        model_Name: model ? model.modelName : "",
        marketId: market ? market.uid : "",
        jobStatus: status ? status.id : "",
        yearId: year ? year.uid : "",
      };
      await dispatch(
        OAuthActions.getReportsData(makeSelected.uid, filters)
      ).then(function (data) {
        if (data) {
          let totalData = [];
          if (data._embedded && data._embedded.jobBasicDetailsDtoes) {
            const filterData = data._embedded.jobBasicDetailsDtoes.filter(
              (m) => m.status === "NEW" || m.status === "IN_PROGRESS"
            );
            totalData = filterData;
            totalData.find((d) => {
              d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
              // const length = d.technicians.length;
              // let name = "";
              // d.technicians.map((data, i) => {
              //   if (length !== i + 1) {
              //     name = `${name} ${data}, `;
              //   } else {
              //     name = `${name} ${data}`;
              //   }
              // });
              // d.techName = name;
              d.makeId = makeSelected.uid;
            });
          }
          setState((prevState) => {
            return { ...prevState, dataSet: totalData };
          });
        }
      });
    } else {
      setModel("");
      setStatus("");
      setMarket("");
      setYear("");
      setPlant("");
      setTechnician("");
      await dispatch(OAuthActions.getBasicDetailsbyJobid(jobId)).then(function (
        data
      ) {
        let totalData = [];
        if (
          data &&
          data.status !== "COMPLETED" &&
          data.status !== "CANCELLED"
        ) {
          totalData = [data];
          totalData.find((d) => {
            d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
            d.makeId = makeSelected.uid;
          });
          // setState((prevState) => {
          //   return { ...prevState, dataSet: totalData };
          // });
        }
        setState((prevState) => {
          return { ...prevState, dataSet: totalData };
        });
      });
    }
    await dispatch(OAuthActions.getAllModel(makeSelected.uid)).then(function (
      data
    ) {
      if (data) {
        setModelData(data);
      }
    });
    await dispatch(
      OAuthActions.getData(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/market/all`
      )
    ).then(function (data) {
      if (data) {
        setMarketData(data);
      }
    });
    await dispatch(
      OAuthActions.getData(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/year/all`
      )
    ).then(function (data) {
      if (data) {
        setYearData(data);
      }
    });
    await dispatch(OAuthActions.getPlants(makeSelected.uid)).then(function (
      pltData
    ) {
      if (pltData) {
        setPlantData(
          pltData?._embedded?.plantLists === undefined
            ? []
            : pltData?._embedded?.plantLists
        );
      }
    });
    await dispatch(OAuthActions.getUsers(makeSelected.uid, roleID)).then(
      function (tchnData) {
        if (tchnData) {
          setTechnicianData(
            tchnData?._embedded?.userLists === undefined
              ? []
              : tchnData?._embedded?.userLists
          );
        }
      }
    );
  }, [useEffectRecall, makeSelected]);
  const filterCall = async () => {
    if (!jobId) {
      const filters = {
        technicianId: technician ? technician.uid : "",
        plantId: plant ? plant.uid : "",
        model_Name: model ? model.modelName : "",
        marketId: market ? market.uid : "",
        jobStatus: status ? status.id : "",
        yearId: year ? year.uid : "",
      };
      await dispatch(
        OAuthActions.getReportsData(makeSelected.uid, filters)
      ).then(function (data) {
        if (data) {
          let totalData = [];
          if (data._embedded && data._embedded.jobBasicDetailsDtoes) {
            // totalData = data._embedded.jobBasicDetailsDtoes;
            const filterData = data._embedded.jobBasicDetailsDtoes.filter(
              (m) => m.status === "NEW" || m.status === "IN_PROGRESS"
            );
            totalData = filterData;
            totalData.find((d) => {
              d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
              // const length = d.technicians.length;
              // let name = "";
              // d.technicians.map((data, i) => {
              //   if (length !== i + 1) {
              //     name = `${name} ${data}, `;
              //   } else {
              //     name = `${name} ${data}`;
              //   }
              // });
              // d.techName = name;
              d.makeId = makeSelected.uid;
            });
          }
          setState((prevState) => {
            return { ...prevState, dataSet: totalData };
          });
        }
      });
    } else {
      setModel("");
      setMarket("");
      setYear("");
      setPlant("");
      setStatus("");
      setTechnician("");
      await dispatch(OAuthActions.getBasicDetailsbyJobid(jobId)).then(function (
        data
      ) {
        let totalData = [];
        if (
          data &&
          data["error"] === undefined &&
          data.status !== "COMPLETED" &&
          data.status !== "CANCELLED"
        ) {
          totalData = [data];
          totalData.find((d) => {
            d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
            // const length = d.technicians.length;
            // let name = "";
            // d.technicians.map((data, i) => {
            //   if (length !== i + 1) {
            //     name = `${name} ${data}, `;
            //   } else {
            //     name = `${name} ${data}`;
            //   }
            // });
            // d.techName = name;
            d.makeId = makeSelected.uid;
          });
          // setState((prevState) => {
          //   return { ...prevState, dataSet: totalData };
          // });
        }
        setState((prevState) => {
          return { ...prevState, dataSet: totalData };
        });
        if (data["error"] !== undefined) {
          dispatch(
            setAlertDialog(
              true,
              null,
              "error",
              "Job Id Search:",
              "Job Id search unsuccessful.\nDetails Of Failure: " +
                data.message,
              "Ok",
              ""
            )
          );
        }
      });
    }
  };
  const customTableData = {
    isReadOnly: true,
    // isOnclick: true,
    dataSet: state.dataSet,
    columns: columns,
    // exportOption: false,
    actions: action,
    // onRowClick: onRowClick,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={assignTransferJobIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Assign Or Transfer Job to Technician:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={1}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <Grid item xs={12} sm={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              justify="space-around"
              spacing={1}
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={model}
                  options={modelData}
                  getOptionLabel={(option) => option.modelName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Model"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      // setModel(newValue.modelName);
                      setModel(newValue);
                    } else {
                      setModel("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={market}
                  options={marketData}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Market"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMarket(newValue);
                    } else {
                      setMarket("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={year}
                  options={yearData}
                  getOptionLabel={(option) => option.year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Year"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setYear(newValue.uid);
                    } else {
                      setYear("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={plant}
                  options={plantData}
                  getOptionLabel={(option) => option.plantName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Plant"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPlant(newValue);
                    } else {
                      setPlant("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={status}
                  options={_appConstants.statusData}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Status"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setStatus(newValue);
                    } else {
                      setStatus("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={technician}
                  options={technicianData}
                  getOptionLabel={
                    (option) =>
                      option && option.firstName
                        ? `${option.firstName} ${option.lastName}`
                        : ""
                    // `${option.firstName} ${option.lastName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Technician"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setTechnician(newValue);
                    } else {
                      setTechnician("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Job ID"
                  onChange={(event) => {
                    setJobId(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} style={{ bottom: "0" }}>
                <IconButton variant="contained" onClick={filterCall}>
                  <PageviewIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomTable {...customTableData} />
        <Dialog open={openIdleDialog} keepMounted>
          <DialogTitle>Confirm</DialogTitle>
          <hr></hr>
          <DialogContent>
            Are you sure you want to cancel this job.
          </DialogContent>
          <hr></hr>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => {
                setopenIdleDialog(false);
              }}
              variant="contained"
            >
              No
            </Button>
            <Button
              className={classes.button}
              onClick={async () => {
                setopenIdleDialog(false);
                await dispatch(
                  OAuthActions.cancelUpdateJob(selectedRow.jobId, {
                    status: "CANCELLED",
                  })
                ).then(function (data) {
                  if (data.status === 200) {
                    dispatch(
                      setSnackbar(true, "success", "Job Cancelled Successfully")
                    );
                    setUseEffectRecall(true);
                  }
                });
              }}
              variant="contained"
            >
              yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default ManageInspection;
