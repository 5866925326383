import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { createInspectionIcon, createInspectionJob } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { setSnackbar } from "../../js/reducers/snackbar";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "../../js/utils/Context";

import * as _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  newTable: {
    borderBottom: "none",
    padding: "2px",
  },
  spanColor: {
    marginLeft: "10px",
    color: "gray",
  },
}));
const ManageInspection = (props) => {
  // let makeID;
  let roleID;
  const history = useHistory();
  const dispatch = useDispatch();
  const [technicians, setTechnicians] = useState([]);
  // const [fixedTechnicians, setFixedTechnicians] = useState([]);
  const [technicianArray, setTechnicianArray] = useState([]);
  const classes = useStyles();
  // const userRole = useSelector((state) => state.OAuth.roles);
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [details, setDetails] = useState({
    jobId: "",
    status: "",
    inspectionDate: "",
    year: "",
    modelName: "",
    market: "",
    plantDetails: "",
    endDate: "",
  });
  if (props && props.location && props.location.rowdata === undefined) {
    history.replace("/assign-Transfer-job");
  }

  useEffect(async () => {
    // setDetails(props.location.rowdata);
    if (props && props.location && props.location.rowdata !== undefined) {
      await dispatch(OAuthActions.getRole()).then(function (roles) {
        if (roles) {
          roleID = roles.find((c) => c.name === "Technician").uid;
        }
      });
      let technicianList = {};
      // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
      //   if (makeId) {
      //     makeID = makeId.uid;
      //   }
      // });

      await dispatch(
        OAuthActions.getBasicDetailsbyJobid(props.location.rowdata.jobId)
      ).then(function (tchnData) {
        if (tchnData) {
          setDetails(tchnData);
          technicianList = tchnData.technicianList;
        }
      });

      await dispatch(OAuthActions.getUsers(makeSelected.uid, roleID)).then(
        function (tchnData) {
          if (tchnData) {
            const activeUser = tchnData._embedded.userLists.filter(
              (m) => m.isActive === true
            );
            setTechnicians(activeUser);
            const preSelectedTechnician = [];
            technicianList.map((data) => {
              // preSelectedTechnician.push(activeUser.find(c => c.uid === data.uid));
              tchnData._embedded.userLists.find((c) => {
                if (c.uid === data.uid) {
                  preSelectedTechnician.push(data);
                }
              });
            });
            setTechnicianArray([...preSelectedTechnician]);
          }
        }
      );
    }
  }, [makeSelected]);
  // let today = new Date();
  // today = moment(today).format("YYYY-MM-DD");

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={createInspectionIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Inspection Job Details:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={2}>
            <img src={createInspectionJob} style={{ height: "16vh" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1 style={{ color: "#005691", fontSize: "15px" }}>Job Details</h1>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Job Id
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {details.jobId}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Job Status
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      <Grid
                        container
                        direction="row"
                        style={{
                          border: "1px solid #005691",
                          width: "100px",
                          color: "#005691",
                        }}
                      >
                        <Grid item style={{ marginTop: "4px" }}>
                          {details.status === "CANCELLED" ? (
                            <HighlightOffOutlinedIcon
                              style={{ fontSize: 18 }}
                            />
                          ) : null}
                          {details.status === "NEW" ? (
                            <DescriptionOutlinedIcon style={{ fontSize: 18 }} />
                          ) : null}
                          {details.status === "IN_PROGRESS" ? (
                            <HourglassEmptyOutlinedIcon
                              style={{ fontSize: 18 }}
                            />
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          style={{
                            marginTop: "6px",
                            marginLeft: "2px",
                            fontSize: "10px",
                          }}
                        >
                          {details.status === "IN_PROGRESS"
                            ? "IN PROGRESS"
                            : details.status}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Inspection Date
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {details.inspectionDate
                        ? moment(details.inspectionDate).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        <Grid
          direction="row"
          container
          style={{ backgroundColor: "whitesmoke", marginTop: "4px" }}
        >
          <p style={{ marginLeft: "12px" }}>MORE DETAILS</p>
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <span className={classes.spanColor}>Model: </span>
                      {details.modelName}
                    </TableCell>
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <span className={classes.spanColor}>Market: </span>
                      {details.market}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <span className={classes.spanColor}>Year: </span>
                      {details.year}
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      <span className={classes.spanColor}>Plant: </span>
                      {details.plantDetails}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <span className={classes.spanColor}>Start: </span>
                      {details.inspectionDate
                        ? moment(details.inspectionDate).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <span className={classes.spanColor}>End: </span>
                      {details.endDate
                        ? moment(details.endDate).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          direction="row"
          container
          style={{ backgroundColor: "whitesmoke", marginTop: "4px" }}
        >
          <p style={{ marginLeft: "12px" }}>
            Assign Additional Technician or Transfer technician
          </p>
        </Grid>
        <Grid container style={{ marginLeft: "12px" }}>
          <Grid item sm={12}>
            <Autocomplete
              multiple
              options={technicians}
              value={technicianArray}
              onChange={(event, newValue) => {
                // if (newValue.length && newValue.length < 2) {
                //     setFixedTechnicians([newValue[0]]);
                // }
                // if (newValue.length && newValue.length >= 2) {
                //     setFixedTechnicians([]);
                // }
                let newValueSet = newValue.reduce((accumulator, current) => {
                  if (!accumulator.some((x) => x.uid === current.uid)) {
                    accumulator.push(current);
                  }
                  return accumulator;
                }, []);
                // let newValueSet = [...new Set(newValue.map((item) => {if}))];

                // console.log(
                //   technicianArray.some(
                //     (technician) => technician.uid === newValue.uid
                //   )
                // );
                setTechnicianArray([
                  ...new Set([
                    // ...fixedTechnicians,
                    ...newValueSet,
                    // ...newValueSet.filter(
                    //   (option) => fixedTechnicians.indexOf(option) === -1
                    // ),
                  ]),
                ]);
              }}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={`${option.firstName} ${option.lastName}`}
                    {...getTagProps({ index })}
                    // disabled={fixedTechnicians.indexOf(option) !== -1}
                  />
                ))
              }
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Technicians"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "12px" }}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  const technicianList = [];
                  technicianArray.map((data) => {
                    technicianList.push(data.uid);
                  });
                  const data = {
                    technicianList,
                  };
                  dispatch(
                    OAuthActions.cancelUpdateJob(details.jobId, data)
                  ).then(function (data) {
                    if (data.status === 200) {
                      dispatch(
                        setSnackbar(
                          true,
                          "success",
                          "Technician Updated Successfully"
                        )
                      );
                      history.goBack();
                    } else {
                      dispatch(
                        setSnackbar(
                          true,
                          "error",
                          "Technician update Unsuccessful.Please try again later."
                        )
                      );
                    }
                  });
                }}
                disabled={!technicianArray.length}
              >
                Update Inspection Job
              </Button>
            </Grid>
            <Grid item>
              <Button size="large" variant="outlined" onClick={history.goBack}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageInspection;
