import { deleteCookie, getCookie, setCookie } from "./cookies";

export default {
  storeToken: (accessToken) => {
    setCookie("auth", accessToken);
  },

  storeRefreshToken: (refreshToken) => {
    setCookie("refresh", refreshToken);
  },

  storeUserInfo: (userInfo) => {
    setCookie("user_name", userInfo.firstName + " " + userInfo.lastName);
  },
  storeMultiTabData: (data) => {
    setCookie("access_token", data.access_token);
    setCookie("expires_in", data.expires_in);
    setCookie("jti", data.jti);
    setCookie("refreshAuth", data.refresh_token);
    setCookie("role", data.role);
    setCookie("scope", data.scope);
    setCookie("token_type", data.token_type);

    if (data.make_uid != undefined) setCookie("make", data.make_uid);
    console.log("data sotre cookie", data.make);
    setCookie("user_uid", data.user_uid);
  },

  clearAll: () => {
    deleteCookie("auth");
    deleteCookie("refresh");
    deleteCookie("tokenExpTime");
  },
  getRefreshToken() {
    return getCookie("refresh");
  },
  getMultiTabData() {
    return {
      access_token: getCookie("access_token"),
      expires_in: getCookie("expires_in"),
      jti: getCookie("jti"),
      scope: getCookie("scope"),
      token_type: getCookie("token_type"),
      user_name: getCookie("user_name"),
      user_uid: getCookie("user_uid"),
      refresh_token: getCookie("refreshAuth"),
      role: getCookie("role"),
    };
  },
  getToken() {
    return getCookie("auth");
  },

  setTokenExpirationTime(expTime) {
    setCookie("tokenExpTime", expTime);
  },

  getTokenExpirationTime() {
    return getCookie("tokenExpTime");
  },

  setNavigationPath(path) {
    setCookie("navigationPath", path);
  },

  getNavigationPath() {
    return getCookie("navigationPath");
  },

  setPreviousNavigationPath(path) {
    setCookie("previousNavigationPath", path);
    console.log(path);
  },

  getPreviousNavigationPath() {
    return getCookie("previousNavigationPath");
  },

  setCurrentMakeSelected(selectedMakeUid) {
    setCookie("currentMakeSelected", JSON.stringify(selectedMakeUid));
    console.log("selectedMakeUid", JSON.stringify(selectedMakeUid));
  },

  getCurrentMakeSelected() {
    return getCookie("currentMakeSelected");
  },

  getMake() {
    return getCookie("make");
  },

  clearAllCookies() {
    // This function will attempt to remove a cookie from all paths.
    var pathBits = location.pathname.split("/");
    var pathCurrent = " path=";

    // do a simple pathless delete first.
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";

    for (var i = 0; i < pathBits.length; i++) {
      pathCurrent += (pathCurrent.substr(-1) != "/" ? "/" : "") + pathBits[i];
      document.cookie =
        name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";";
    }
  },
};
