/*global _appConstants */
import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import TokenStorage from "./js/utils/tokenStorage";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { UserProfile as UserProfileActions } from "./js/actions";

const AuthLayout = (props) => {
  const { history } = props;

  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);
  const [openIdleDialog, setopenIdleDialog] = useState(false);
  const onIdle = () => {
    TokenStorage.getToken() ? logout() : console.log("Not Logged");
  };

  const closeDialogBox = () => {
    setopenIdleDialog(false);
  };

  const logout = () => {
    dispatch(UserProfileActions.clearLoginUserData()).then(function () {
      history.push(_appConstants.useCommonDefaults.navRef.login);
    });
    setopenIdleDialog(true);
  };
  return (
    <div>
      <Dialog open={openIdleDialog} keepMounted>
        <DialogTitle></DialogTitle>
        <hr></hr>
        <DialogContent>
          You have been inactive for more than 60 minutes. You have been logged
          out. Please login again.
        </DialogContent>
        <hr></hr>
        <DialogActions>
          <Button onClick={closeDialogBox} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <IdleTimer ref={idleTimerRef} timeout={60 * 60 * 1000} onIdle={onIdle} />
    </div>
  );
};

export default AuthLayout;
