/* eslint-disable no-case-declarations */

import { OAuth as OAuthActions } from "../actions";
import TokenStorage from "../utils/tokenStorage";

let initialState = {};
var OAuth = (state = initialState, action) => {
  switch (action.type) {
    case OAuthActions.STORE_OAUTH_TOKEN:
      TokenStorage.storeToken(action.oAuthData.access_token);
      TokenStorage.storeMultiTabData(action.oAuthData);
      localStorage.setItem("token_name", action.oAuthData.access_token);
      const username = localStorage.getItem("token_name");
      console.log(username);
      return Object.assign({}, state, { authData: action.oAuthData });
    case OAuthActions.STORE_ACTIVE_INDEX:
      return Object.assign({}, state, { index: action.oActiveIndex });
    case OAuthActions.STORE_ALL_ROLES:
      return Object.assign({}, state, { roles: action.roles });
    case OAuthActions.STORE_LOGIN_DATA:
      // TokenStorage.clearAll();
      // Object.assign({}, initialState);
      TokenStorage.storeUserInfo(action.loginUserData);
      // debugger;
      return Object.assign({}, state, { loginUserData: action.loginUserData });

    default:
      break;
  }

  return state;
};

export default OAuth;
