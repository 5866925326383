/* global _appConstants */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ErrorBoundary from "./ErrorBoundary";
import { Topbar, Sidebar, Breadcrumbs, ScrollToTop } from "./components";
import { Grid, Paper, Button } from "@material-ui/core";
import TokenStorage from "../../js/utils/tokenStorage";
import { OAuth as OAuthActions } from "../../js/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Typography,
  // TextField,
  // InputAdornment,
  // IconButton,
  Checkbox,
} from "@material-ui/core";
import { FooterTabs } from "../../../src/components/index";

import { UserProfile } from "../../components";
import { UserProfile as UserProfileActions } from "../../js/actions";
import { useHistory } from "react-router-dom";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { Context } from "../../js/utils//Context.js";
import { withRouter } from "react-router-dom";

import ReactGA from "react-ga";

ReactGA.initialize("GTM-5RK9R65");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuClosed: {
    maxWidth: "5em",
  },
  dashboardWidth: {
    maxWidth: "calc(100% - 5em)",
    flexBasis: "calc(100% - 5em)",
    height: "100vh",
    overflow: "auto",
  },
  dashboardWidthOpen: {
    height: "100vh",
    overflow: "auto",
  },
  dialogStyle: {
    maxWidth: "100%",
    flexBasis: "calc(100%)",
    height: "100vh",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: "1vh 1vw",
    height: "100%",
  },
  headerHeight: {
    display: "flex",
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  makeWrap: {
    padding: "2px",
    position: "relative",
    background: "linear-gradient(to right, #EA0016, #B90276,#50237F)",
  },
  module: {
    backgroundColor: "white",
    padding: "2px",
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();

  const [make, setMake] = useState({ uid: "", name: "Not Selected" });

  const [role, setRole] = useState({});
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let intervlToRefreshToken;
  const [OpenDialogue, setOpenDialogue] = React.useState(true);
  const [termsOfUse, setTermsOfUse] = React.useState(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const oAuthData = useSelector((state) => state.OAuth.authData || {});

  const data = useSelector((state) => {
    // debugger;
    console.log(
      "state.OAuth.loginUserData useSelector",
      state.OAuth.loginUserData
    );
    return state.OAuth.loginUserData || {};
  });

  const handlePrivacyUpdate = async () => {
    // setOpenDialogue(false);
    await dispatch(
      OAuthActions.updateUsersPolicy(data.uid, {
        hasAcceptedTnc: true,
        hasAcceptedPrivacyPolicy: true,
      })
    ).then(function (userDetails) {
      if (userDetails["error"] === undefined) {
        setTermsOfUse(false);
        setPrivacyPolicy(false);
        dispatch(setSnackbar(true, "success", "Update Successful"));
        handleClose();
        dispatch(OAuthActions.getLoginUser(data.uid)).then(function (flag) {});
      } else dispatch(setAlertDialog(true, null, "error", "Update User:", "Update unsuccessful.\nDetails Of Failure: " + userDetails.message, "Ok", ""));
    });
  };
  const handleTermsClose = () => {
    setOpenTerms(false);
  };
  const handlePrivacyClose = () => {
    setOpenPrivacy(false);
  };
  const handleClickOpen = () => {
    setOpenTerms(true);
  };
  const handleClickPrivacyOpen = () => {
    setOpenPrivacy(true);
  };
  const logout = () => {
    handleClose();
    localStorage.removeItem("token_name");
    dispatch(UserProfileActions.clearLoginUserData()).then(function () {
      history.push(_appConstants.useCommonDefaults.navRef.login);
    });
  };
  const handleClose = () => {
    setOpenDialogue(false);
  };

  useEffect(() => {
    const val = TokenStorage.getCurrentMakeSelected();
    if (val.uid != undefined || val.uid != "") {
      setMake(val);
    }
    console.log("Main.js useEffect", val);

    console.log("React GA trigger");

    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(OAuthActions.getLoginUser(data.uid)).then(function (flag) {
      console.log("Main.js Flag", flag);
      console.log("data.role", data.role.name);
      const val = JSON.parse(
        JSON.stringify(JSON.parse(TokenStorage.getCurrentMakeSelected()))
      );
      if (val.uid != undefined || val.uid != "") {
        console.log("New change", val);
        setMake(val);
      } else setMake(data.make != undefined || data.make != null ? data.make : { uid: "", name: "Not Selected" });
    });
  }, []);

  useEffect(() => {
    if (data.make != undefined || data.make != null) {
      console.log("Main.js: useEffect data", data.make.name);
      const makeDetails = data.make;
      console.log("Main.js: useEffect data makeDetails", makeDetails);
      setMake(makeDetails);
    } else setMake({ uid: "", name: "Not Selected" });
  }, data);
  useEffect(() => {
    //

    console.log("Main.js: Oauth Data", oAuthData);

    console.log("Main.js: data Data", data);

    if (data != undefined) {
      if (data.make != null) {
        console.log("Main.js: data.make.name", data.make.name);
        setMake(data.make);
      } else setMake({ uid: "", name: "Not Selected" });
      if (data.hasAcceptedTnc) setTermsOfUse(true);
      if (data.hasAcceptedPrivacyPolicy) setPrivacyPolicy(true);
    } else setMake({ uid: "", name: "Not Selected" });
    //
    clearInterval(intervlToRefreshToken);
    let expires_in_count = 0,
      dtSeconds = 0;
    if (!oAuthData.refresh_token && TokenStorage.getRefreshToken()) {
      let curDate = new Date();
      let expiryDate = new Date(TokenStorage.getTokenExpirationTime());
      dtSeconds = Math.ceil((expiryDate.getTime() - curDate.getTime()) / 1000);
    } else {
      //TokenStorage.storeToken(oAuthData.access_token);
      TokenStorage.storeRefreshToken(oAuthData.refresh_token);
      let dt = new Date();
      //oAuthData.expires_in = 240;
      dt.setSeconds(dt.getSeconds() + oAuthData.expires_in);
      TokenStorage.setTokenExpirationTime(dt);
    }
    intervlToRefreshToken = setInterval(() => {
      if (
        expires_in_count <
        (oAuthData.expires_in ? oAuthData.expires_in : dtSeconds)
      ) {
        expires_in_count++;
      } else {
        clearInterval(intervlToRefreshToken);
        expires_in_count = 0;
        dispatch(
          OAuthActions.refreshOAuthToken(TokenStorage.getRefreshToken())
        );
      }
    }, 1000);
    return () => {
      clearInterval(intervlToRefreshToken);
    }; //component will unmount
  }, [oAuthData]);

  const toggleSidebarHandler = (event, newValue) => {
    event.preventDefault();
    setOpen(newValue);
  };
  const onChange = (event) => {
    switch (event.target.id) {
      case "termsOfUse":
        setTermsOfUse(event.target.checked);
        break;
      case "privacyPolicy":
        setPrivacyPolicy(event.target.checked);
        break;
      default:
        break;
    }
  };

  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "logout-event") {
        console.log("Received logout event! Insert logout script here.");
        // window.location = "logout.php";
      }
    },
    false
  );

  return (
    <Grid container spacing={0}>
      <Context.Provider value={[make, setMake]}>
        <Grid item xs={12}>
          <Topbar
            isOpen={open}
            onToggle={toggleSidebarHandler}
            history={children.props.history}
            // isDashboardView={true}
          />
        </Grid>

        <Grid
          container
          spacing={0}
          style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
        >
          {data.hasAcceptedPrivacyPolicy && data.hasAcceptedTnc ? (
            <Grid
              className={open ? "" : classes.menuClosed}
              item
              xs={open ? 2 : 1}
              style={{ marginTop: "1.5vh" }}
            >
              <div className={classes.headerHeight} />
              <Sidebar open={open} history={children.props.history} />
            </Grid>
          ) : null}

          {data.hasAcceptedPrivacyPolicy && data.hasAcceptedTnc ? (
            <Grid
              className={
                open ? classes.dashboardWidthOpen : classes.dashboardWidth
              }
              item
              id="drawercontainer"
              xs={open ? 10 : 11}
            >
              <main>
                <div elevation={3} className={classes.content}>
                  <div className={classes.headerHeight} />
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Grid item xs>
                      <Breadcrumbs />
                    </Grid>
                    {data.role.name == "Utility Admin" ? (
                      <></>
                    ) : (
                      <Grid item xs="auto">
                        <div className={classes.makeWrap}>
                          <div className={classes.module}>
                            <strong>
                              OEM Details:
                              {make.name != "" ? make.name : "Not Selected"}
                            </strong>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <Paper elevation={3} style={{ overflow: "hidden" }}>
                    <ErrorBoundary>
                      {children.type !== "p" ? children : ""}
                    </ErrorBoundary>
                    <ScrollToTop />
                  </Paper>
                </div>
              </main>
            </Grid>
          ) : null}
          {!data.hasAcceptedPrivacyPolicy || !data.hasAcceptedTnc ? (
            <Grid item xs={12}>
              <Dialog
                fullWidth={true}
                open={OpenDialogue}
                aria-labelledby="max-width-dialog-title"
                className={classes.dialogStyle}
              >
                <DialogTitle id="max-width-dialog-title">
                  <Typography variant="subtitle1">
                    Confirmation of new Privacy Policy and Terms And Conditions:
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  {!data.hasAcceptedTnc ? (
                    <Grid item style={{ padding: "0.5rem" }}>
                      <Checkbox
                        id="termsOfUse"
                        checked={termsOfUse}
                        onChange={(event) => onChange(event)}
                        color="primary"
                      />
                      <Typography variant="caption">
                        I accept the{" "}
                        <em
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleClickOpen}
                        >
                          terms and conditions
                        </em>
                      </Typography>
                      <FooterTabs
                        clickValue={3}
                        open={openTerms}
                        onClose={handleTermsClose}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {!data.hasAcceptedPrivacyPolicy ? (
                    <Grid item style={{ padding: "0.5rem" }}>
                      <Checkbox
                        id="privacyPolicy"
                        checked={privacyPolicy}
                        onChange={(event) => onChange(event)}
                        color="primary"
                      />
                      <Typography variant="caption">
                        I acknowledge the{" "}
                        <em
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={handleClickPrivacyOpen}
                        >
                          privacy policy
                        </em>
                      </Typography>
                      <FooterTabs
                        clickValue={1}
                        open={openPrivacy}
                        onClose={handlePrivacyClose}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handlePrivacyUpdate}
                    color="primary"
                    variant="contained"
                    disabled={!termsOfUse || !privacyPolicy}
                  >
                    Update
                  </Button>
                  <Button
                    onClick={logout}
                    color="primary"
                    variant="contained"
                    to="/login"
                  >
                    Logout
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          ) : null}
        </Grid>
        {data.hasAcceptedPrivacyPolicy ? <UserProfile /> : null}
      </Context.Provider>
    </Grid>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

function compare(prevProps, nextProps) {
  console.log("prevProps, nextProps", prevProps, nextProps);
  return prevProps.location.pathname == nextProps.location.pathname;
}

export default withRouter(React.memo(Main, compare));
