import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { background } from "../../assets/images";
import { PasskeySet, Header, Footer } from "../../components";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  background_img: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    [`${theme.breakpoints.down("md")} and (orientation:landscape )`]: {
      minHeight: "80vw",
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape )`]: {
      minHeight: "80vw",
    },
    [`${theme.breakpoints.down("sm")} and (orientation:portrait)`]: {
      height: "110vh",
    },
    [`${theme.breakpoints.down("xs")} and (orientation:landscape )`]: {
      height: "90vw",
    },
    [`${theme.breakpoints.down("xs")} and (orientation:portrait)`]: {
      height: "110vh",
    },
  },
}));

const Passkey = (props) => {
  const [registrationId, setRegistrationId] = useState("");
  console.log("props", props);
  useEffect(() => {
    let data = props.location.state;
    // debugger;
    if (data?.registrationId !== undefined) {
      setRegistrationId(props.location.state.registrationId);
      console.log("props registrationId", props.location.state);
    } else {
      setRegistrationId("");
      console.log("props registrationId no!", props.location.state);
    }
  }, []);

  const classes = useStyles();

  let url = window.location.href;
  let last_section = url.replace(/\/+$/, "").split("/").pop();

  console.log("last_section", last_section);

  return (
    <>
      <Header />
      <div className={classes.background_img}>
        <PasskeySet registrationId={registrationId} />
      </div>
      <Footer />
    </>
  );
};

export default Passkey;
