import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { contentUploadIcon, contentUpload } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useDispatch } from "react-redux";
import { OAuth as OAuthActions } from "../../js/actions";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import { useHistory } from "react-router-dom";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
}));
const ContentUpload = (props) => {
  // let makeID;
  const [vehicleModelUid, setVehicleModelUid] = useState("");
  const dispatch = useDispatch();
  const [model, setModel] = useState("");
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [variant, setVariant] = useState("");
  const [market, setMarket] = useState("");
  const [year, setYear] = useState("");
  const [language, setLanguage] = useState("");
  const [modelSelected, setModelSelected] = useState(false);
  const [variantSelected, setVariantSelected] = useState(false);
  const [marketSelected, setMarketSelected] = useState(false);
  // const [yearSelected, setYearSelected] = useState(false);
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [file, setFile] = useState(null);
  const [localPath, setLocalPath] = useState("");
  const classes = useStyles();
  const history = useHistory();

  const bindScreen = (e) => {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  useEffect(async () => {
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //   }
    // });
    await dispatch(OAuthActions.getModelVariant(makeSelected.uid)).then(
      function (mdlData) {
        if (mdlData) {
          setModelData(mdlData.vehicleModelVariants);
        }
      }
    );
    await dispatch(OAuthActions.getLanguage()).then(function (language) {
      if (language) {
        setLanguageData(language);
      }
    });
  }, [makeSelected]);
  const handleModelChange = (event) => {
    setModel(event.target.value);
    if (event.target.value) {
      setModelSelected(true);
      const varDt = modelData.filter((m) => m.model === event.target.value);
      setVariantData(varDt[0].variants);
      if (event.target.value !== model) {
        setVariantSelected(false);
        setMarketSelected(false);
        // setYearSelected(false);
        setVariant("");
        setMarket("");
        setYear("");
        setLanguage("");
      }
    } else {
      setModelSelected(false);
      setVariantSelected(false);
      setMarketSelected(false);
      // setYearSelected(false);
      setVariant("");
      setMarket("");
      setYear("");
      setLanguage("");
    }
  };
  const handleVariantChange = (event) => {
    setVariant(event.target.value);
    if (event.target.value) {
      setVariantSelected(true);
      let mrktUrl = variantData.filter((m) => m.name === event.target.value);
      mrktUrl = mrktUrl[0].links[0].href;
      dispatch(OAuthActions.getData(mrktUrl)).then(function (mrktData) {
        if (mrktData) {
          setMarketData(mrktData);
        }
      });
      if (event.target.value !== variant) {
        setMarketSelected(false);
        // setYearSelected(false);
        setMarket("");
        setYear("");
        setLanguage("");
      }
    } else {
      setVariantSelected(false);
      setMarketSelected(false);
      // setYearSelected(false);
      setMarket("");
      setYear("");
      setLanguage("");
    }
  };
  const handleMarketChange = (event) => {
    setMarket(event.target.value);
    if (event.target.value) {
      setMarketSelected(true);
      let yrUrl = marketData.filter((m) => m.uid === event.target.value);
      yrUrl = yrUrl[0].links[0].href;
      dispatch(OAuthActions.getData(yrUrl)).then(function (yrData) {
        if (yrData) {
          setYearData(yrData);
        }
      });
      if (event.target.value !== market) {
        // setYearSelected(false);
        setYear("");
        setLanguage("");
      }
    } else {
      setMarketSelected(false);
      // setYearSelected(false);
      setYear("");
      setLanguage("");
    }
  };
  const handleYearChange = (event) => {
    console.log("event", event.target);
    setYear(event.target.value);
    if (event.target.value) {
      // setYearSelected(true);
      let vmUID = yearData.filter((m) => m.uid === event.target.value);
      vmUID = vmUID[0].vehicleModelDetailsUid;
      setVehicleModelUid(vmUID);
      // dispatch(OAuthActions.getManualLanguage(vmUID)).then(function (lngData) {
      //   if (lngData) {
      //     setLanguageData(lngData);
      //   }
      // });
      // if (event.target.value !== year) {
      //   setLanguage("");
      // }
    } else {
      // setYearSelected(false);
      setLanguage("");
    }
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      setFile(file);
      setLocalPath(
        file.name + "(" + (file.size / (1024 * 1024)).toFixed(2) + " MB )"
      );
      console.log(file);
    }
  };

  const submit = () => {
    let formData = new FormData();
    formData.append("file", file);
    window.addEventListener("beforeunload", bindScreen, true);
    dispatch(OAuthActions.uploadDoc(language, vehicleModelUid, formData)).then(
      function (success) {
        window.removeEventListener("beforeunload", bindScreen, true);
        if (success["error"] === undefined) {
          dispatch(setSnackbar(true, "success", "Upload document Successful"));
          history.push("/dashboard");
        } else {
          dispatch(
            setAlertDialog(
              true,
              null,
              "error",
              "Document Upload:",
              "Document upload was unsuccessful. Please check if the provided zip file only contains PDF and try again.\nDetails Of Failure: " +
                success.message,
              "Ok",
              ""
            )
          );
        }
      }
    );
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={contentUploadIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Content Upload:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img
              src={contentUpload}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Model"
                  id="model-select"
                  value={model}
                  onChange={handleModelChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {modelData.length &&
                    modelData.map((data) => (
                      <MenuItem key={data.model} value={data.model}>
                        {data.model}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Variant"
                  id="variant-select"
                  value={variant}
                  onChange={handleVariantChange}
                  disabled={!modelSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {variantData.length &&
                    variantData.map((data) => (
                      <MenuItem key={data.name} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Market"
                  id="market-select"
                  value={market}
                  onChange={handleMarketChange}
                  disabled={!variantSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {marketData.length &&
                    marketData.map((data) => {
                      console.log("market", data);
                      return (
                        <MenuItem key={data.uid} value={data.uid}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Year of manufacturing"
                  id="year-select"
                  value={year}
                  onChange={handleYearChange}
                  disabled={!marketSelected}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {yearData.length &&
                    yearData.map((data) => {
                      return (
                        <MenuItem
                          key={data.vehicleModelDetailsUid}
                          value={data.uid}
                        >
                          {data.yearOfManufacturing}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Language"
                  id="language-select"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {languageData.length &&
                    languageData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.language}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={9} sm={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <input
                      accept=".zip,.rar,.7zip"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      onChange={onFileChange}
                    />
                    <TextField
                      fullWidth
                      required
                      id="filled-required"
                      label="Local Path"
                      value={localPath}
                      InputProps={{
                        endAdornment: (
                          <>
                            <label htmlFor="contained-button-file">
                              <Button
                                size="large"
                                variant="contained"
                                component="span"
                                startIcon={<AttachmentIcon />}
                              >
                                Browse
                              </Button>
                            </label>
                          </>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        const data = {
                          model,
                          variant,
                          market,
                          year,
                          language,
                          localPath,
                        };
                        console.log(data);
                        submit();
                      }}
                      disabled={
                        !model ||
                        !variant ||
                        !market ||
                        !year ||
                        !language ||
                        !localPath
                      }
                    >
                      Upload Content
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ContentUpload;
