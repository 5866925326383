import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

///Unused
const CustomBreadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
      {pathnames.length > 0 ? (
        <Link style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
          Home
        </Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>{name.replace("-", " ")}</Typography>
        ) : (
          <Link
            key={name}
            style={{ cursor: "pointer" }}
            onClick={() => history.push(routeTo)}
          >
            {name.replace("-", " ")}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default withRouter(CustomBreadcrumbs);
