import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { userIcon, addUser } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import { OAuth as OAuthActions } from "../../js/actions";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  dropWidth: {
    width: "75%",
  },
}));
const AddUser = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstNameHelper, setFirstNameHelper] = useState("");
  const [lastNameHelper, setLastNameHelper] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [role, setRole] = useState("");
  const [RoleData, setRoleData] = useState([]);
  const [makeSelected, setMakeSelected] = useContext(Context);

  const userRole = useSelector((state) => {
    console.log("state?.OAuth?.authData?.role", state?.OAuth?.authData?.role);
    return state?.OAuth?.authData?.role;
  });
  React.useEffect(async () => {
    if (userRole !== undefined) {
      await dispatch(OAuthActions.getRole()).then(function (roleData) {
        if (roleData) {
          console.log("userRole", userRole);

          if (userRole == "SUPER_ADMIN")
            setRoleData(
              roleData.filter(
                (roleDetails) =>
                  roleDetails.name != "Super Admin" &&
                  roleDetails.name != "Utility Admin"
              )
            );
          else if (userRole == "ADMIN")
            setRoleData(
              roleData.filter(
                (roleDetails) => roleDetails.name.indexOf("Admin") == -1
              )
            );
        }
      });
      // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
      //   if (makeId) {
      //     setMake(makeId.uid);
      //   }
      // });
    }
  }, [userRole, makeSelected]);

  const classes = useStyles();
  const handleRole = (event) => {};

  const submit = () => {
    console.log(firstName, lastName, email, role);
    dispatch(
      OAuthActions.createUser(
        firstName,
        lastName,
        email,
        role,
        makeSelected.uid
      )
    ).then(function (success) {
      if (success["error"] === undefined) {
        dispatch(setSnackbar(true, "success", "Add User Successful"));
        history.goBack();
      } else dispatch(setAlertDialog(true, null, "error", "Add User:", "Add user unsuccessful.\nDetails Of Failure: " + success.message, "Ok", ""));
    });
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={userIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Add User:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img src={addUser} style={{ height: "14vh", margin: "1em" }} />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={firstNameHelper.length !== 0}
                  helperText={firstNameHelper}
                  label="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    const valid = /^[a-zA-Z\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setFirstNameHelper("Name contains only Alphabets");
                    } else {
                      setFirstNameHelper("");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={lastNameHelper.length !== 0}
                  helperText={lastNameHelper}
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    const valid = /^[a-zA-Z\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setLastNameHelper("Name contains only Alphabets");
                    } else {
                      setLastNameHelper("");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <Autocomplete
                  options={RoleData}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // className={classes.dropWidth}
                      label="Role"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue == null) setRole("");
                    else setRole(newValue.uid);
                  }}
                />
              </Grid>

              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  id="filled-required"
                  label="Email ID "
                  value={email}
                  error={emailHelper.length !== 0}
                  helperText={emailHelper}
                  onChange={(event) => {
                    let valid =
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                        event.target.value
                      );
                    if (!valid && event.target.value.length !== 0) {
                      setEmailHelper("Invalid email address");
                    } else {
                      setEmailHelper("");
                    }
                    setEmail(event.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={
                        firstName == "" ||
                        lastName == "" ||
                        email == "" ||
                        role == "" ||
                        emailHelper.length !== 0 ||
                        firstNameHelper.length !== 0 ||
                        lastNameHelper.length !== 0
                      }
                      onClick={submit}
                    >
                      Register
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AddUser;
