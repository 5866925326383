/* global _appConstants*/
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { inspectionJobReportIcon } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import {
  OAuth as OAuthActions,
  UserProfile as UserProfileActions,
} from "../../js/actions";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useDispatch } from "react-redux";
import MaterialTable, { MTableEditRow } from "material-table";
import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { CustomTable } from "../../components";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import Chip from "@material-ui/core/Chip";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    // border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  IconDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "transparent",
    cursor: "default",
    pointerEvents: "none",
  },
  avatar: {
    backgroundColor: "#01579B",
    width: "25px",
    height: "25px",
  },
  editRow: {
    backgroundColor: "#01579B",
    color: "white !important",
  },
  new: {
    backgroundColor: "#16a085",
    color: "#fff",
  },
  inprogress: {
    backgroundColor: "#2980b9",
    color: "#fff",
  },
  completed: {
    backgroundColor: "#2c3e50",
    color: "#fff",
  },
  cancelled: {
    backgroundColor: "#c0392b",
    color: "#fff",
  },
  MTablePaginationInner: {
    MuiButtonBase: {
      backgroundColor: "red",
    },
  },
}));
const InspectionJobReport = (props) => {
  // let makeID;
  let roleID;
  const [makeSelected, setMakeSelected] = useContext(Context);
  const dispatch = useDispatch();
  const [model, setModel] = useState("");
  const [modelData, setModelData] = useState([]);
  const [market, setMarket] = useState("");
  const [marketData, setMarketData] = useState([]);
  const [year, setYear] = useState("");
  const [yearData, setYearData] = useState([]);
  const [plant, setPlant] = useState("");
  const [plantData, setPlantData] = useState([]);
  const [statusArray, setStatusArray] = useState([]);
  const [technician, setTechnician] = useState("");
  const [technicianData, setTechnicianData] = useState([]);
  const [jobId, setJobId] = useState("");
  const classes = useStyles();

  const tableIcons = {
    file: React.forwardRef((props, ref) => (
      <DescriptionOutlinedIcon {...props} ref={ref} />
    )),
  };
  const [state, setState] = useState({
    dataSet: [],
  });

  const columns = [
    // {
    //   title: "Job ID",
    //   field: "jobId",
    //   editable: "never",
    //   cellStyle: {
    //     backgroundColor: "#F5F5F5",
    //   },
    // },
    {
      title: "Model",
      field: "modelName",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
        width: "120px",
      },
    },
    {
      title: "Market",
      field: "market",
      editable: "never",
    },
    {
      title: "Year",
      field: "year",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
    },
    {
      title: "Inspection Date",
      field: "inspectionDate",
      editable: "never",
      cellStyle: {
        width: "120px",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={moment(rowData.rowData.inspectionDate).format(
            "DD/MM/YYYY"
          )}
          size="small"
        />
      ),
    },
    {
      title: "Technician",
      field: "techniciannames",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      render: (rowData) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Avatar className={classes.avatar}></Avatar>
          </Grid>
          {rowData.technicians.length && (
            <Grid item>
              {rowData.technicians.map((value, i) =>
                rowData.technicians.length !== i + 1 ? (
                  <Typography>{`${value},`}</Typography>
                ) : (
                  <Typography>{`${value}`}</Typography>
                )
              )}
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      title: "Plant Details",
      field: "plantDetails",
      editable: "never",
    },
    {
      title: "Status",
      field: "status",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      render: (rowData) => {
        switch (rowData.status) {
          case "COMPLETED":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.completed}
              >
                <Grid item>
                  <DoneAllOutlinedIcon />
                </Grid>
                <Grid item>Completed</Grid>
              </Grid>
            );
            break;
          case "NEW":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.new}
              >
                <Grid item>
                  <DescriptionOutlinedIcon />
                </Grid>
                <Grid item>New</Grid>
              </Grid>
            );
            break;

          case "IN_PROGRESS":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.inprogress}
              >
                <Grid item>
                  <HourglassEmptyOutlinedIcon />
                </Grid>
                <Grid item>In Progress</Grid>
              </Grid>
            );
            break;
          case "CANCELLED":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.cancelled}
              >
                <Grid item>
                  <HighlightOffOutlinedIcon />
                </Grid>
                <Grid item>Cancelled</Grid>
              </Grid>
            );
            break;
          default:
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                {" "}
                <Grid item>N/A</Grid>
              </Grid>
            );
            break;
        }
      },
    },
  ];

  const actions = [
    {
      icon: tableIcons.file,
      tooltip: "Reference",
      // style: {
      //   backgroundColor: "#F5F5F5",
      // },
      onClick: (event, rowData) => {
        props.history.push({
          pathname: _appConstants.useCommonDefaults.navRef.jobDetails,
          rowdata: rowData,
        });
      },
    },
  ];

  useEffect(async () => {
    let Make = "";
    await dispatch(OAuthActions.getRole()).then(function (roles) {
      if (roles) {
        roleID = roles.find((c) => c.name === "Technician").uid;
      }
    });
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     Make = makeId.uid;
    //     setMakeId(makeId.uid);
    //   }
    // });
    await dispatch(OAuthActions.getReportsData(makeSelected.uid)).then(
      function (data) {
        if (data) {
          const totalData =
            data?._embedded?.jobBasicDetailsDtoes === undefined
              ? []
              : data?._embedded?.jobBasicDetailsDtoes;
          totalData.find((d) => {
            d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
            d.makeId = makeSelected.uid;
          });
          let newtotalData = totalData.map((val) => {
            return Object.assign({}, val, {
              techniciannames:
                val?.technicians?.length > 0 ? val.technicians.join() : "",
            });
          });
          setState((prevState) => {
            return { ...prevState, dataSet: newtotalData };
          });
        }
      }
    );
    await dispatch(OAuthActions.getAllModel(makeSelected.uid)).then(function (
      data
    ) {
      if (data) {
        setModelData(data);
      }
    });
    await dispatch(
      OAuthActions.getData(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/market/all`
      )
    ).then(function (data) {
      if (data) {
        setMarketData(data);
      }
    });
    await dispatch(
      OAuthActions.getData(
        `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/year/all`
      )
    ).then(function (data) {
      if (data) {
        setYearData(data);
      }
    });
    await dispatch(OAuthActions.getPlants(makeSelected.uid)).then(function (
      pltData
    ) {
      if (pltData) {
        setPlantData(
          pltData?._embedded?.plantLists === undefined
            ? []
            : pltData?._embedded?.plantLists
        );
      }
    });
    await dispatch(OAuthActions.getUsers(makeSelected.uid, roleID)).then(
      function (tchnData) {
        if (tchnData) {
          setTechnicianData(
            tchnData?._embedded?.userLists === undefined
              ? []
              : tchnData?._embedded?.userLists
          );
        }
      }
    );
  }, [makeSelected]);

  // let unique = state.dataSet.filter((item, i, ar) => ar.indexOf(item) === i);

  // console.log([...new Set(state.dataSet.map(({ year }) => year))]);
  // console.log([...new Set(state.dataSet.map(({ modelName }) => modelName))]);
  // console.log([...new Set(state.dataSet.map(({ market }) => market))]);

  // console.log([
  //   ...new Set(state.dataSet.map(({ plantDetails }) => plantDetails)),
  // ]);
  // console.log([...new Set(state.dataSet.map(({ status }) => status))]);
  // console.log([...new Set(state.dataSet.map(({ techName }) => techName))]);
  // console.log([...new Set(state.dataSet.map(({ jobId }) => jobId))]);
  const filterCall = async () => {
    if (!jobId) {
      console.log("statusArray", statusArray);
      let status = statusArray
        .map((status) => {
          return status.id;
        })
        .join();
      console.log("statusArray child list", status);
      const filters = {
        technicianId: technician ? technician.uid : "",
        plantId: plant ? plant.uid : "",
        model_Name: model ? model.modelName : "",
        marketId: market ? market.uid : "",
        jobStatus: status ? status : "", //to-do: should check  and chnge to statusArray
        yearId: year ? year.uid : "",
      };
      await dispatch(
        OAuthActions.getReportsData(makeSelected.uid, filters)
      ).then(function (data) {
        if (data) {
          let totalData = [];
          if (data?._embedded && data?._embedded?.jobBasicDetailsDtoes) {
            totalData = data._embedded.jobBasicDetailsDtoes;
            totalData.find((d) => {
              d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
              d.makeId = makeSelected.uid;
            });
          }
          let newtotalData = totalData.map((val) => {
            return Object.assign({}, val, {
              techniciannames:
                val?.technicians?.length > 0 ? val.technicians.join() : "",
            });
          });
          setState((prevState) => {
            return { ...prevState, dataSet: newtotalData };
          });
        }
      });
    } else {
      setModel("");
      setMarket("");
      setYear("");
      setPlant("");
      setStatusArray([]);
      setTechnician("");
      await dispatch(OAuthActions.getBasicDetailsbyJobid(jobId)).then(function (
        data
      ) {
        let tData = [];
        if (data && data["error"] === undefined) {
          let totalData = [];
          totalData = [data];
          totalData.find((d) => {
            d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
            d.makeId = makeSelected.uid;
          });
          let newtotalData = totalData.map((val) => {
            return Object.assign({}, val, {
              techniciannames:
                val?.technicians?.length > 0 ? val.technicians.join() : "",
            });
          });
          tData = newtotalData;
          // setState((prevState) => {
          //   return { ...prevState, dataSet: newtotalData };
          // });
        }
        setState((prevState) => {
          return { ...prevState, dataSet: tData };
        });
        if (data["error"] !== undefined) {
          dispatch(
            setAlertDialog(
              true,
              null,
              "error",
              "Job Id Search:",
              "Job Id search unsuccessful.\nDetails Of Failure: " +
                data.message,
              "Ok",
              ""
            )
          );
        }
      });
    }
  };
  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: state.dataSet,
    columns: columns,
    actions: actions,
    exportOption: true,
    exportOptionPdf: true,
    searchState: true,
    exportFileName: "Insepction Job Report",
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={inspectionJobReportIcon}
                style={{ height: "2em", margin: "0 1em" }}
              />
              <Typography variant="h3">
                <strong>Inspection Job Report:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={1}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <Grid item xs={12} sm={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              justify="space-around"
              spacing={1}
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={model}
                  options={modelData}
                  getOptionLabel={(option) => option.modelName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Model"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setModel(newValue);
                    } else {
                      setModel("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={market}
                  options={marketData}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Market"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setMarket(newValue);
                    } else {
                      setMarket("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={year}
                  options={yearData}
                  getOptionLabel={(option) => option.year}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Year"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setYear(newValue);
                    } else {
                      setYear("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={plant}
                  options={plantData}
                  getOptionLabel={(option) => option.plantName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Plant"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPlant(newValue);
                    } else {
                      setPlant("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  multiple
                  value={statusArray}
                  options={_appConstants.statusDataInspection}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    let newValueSet = newValue.reduce(
                      (accumulator, current) => {
                        if (
                          !accumulator.some((x) => x.label === current.label)
                        ) {
                          accumulator.push(current);
                        }
                        return accumulator;
                      },
                      []
                    );

                    setStatusArray([...new Set([...newValueSet])]);
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={`${option.label}`}
                        {...getTagProps({ index })}
                        // disabled={fixedTechnicians.indexOf(option) !== -1}
                      />
                    ))
                  }
                  // style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Status"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />

                {/* <Autocomplete
                  value={status}
                  options={_appConstants.statusDataInspection}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setStatus(newValue);
                    } else {
                      setStatus("");
                    }
                  }}
                ></Autocomplete> */}
                {/* // ---------------------------------------- */}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  value={technician}
                  options={technicianData}
                  getOptionLabel={
                    (option) =>
                      option && option.firstName
                        ? `${option.firstName} ${option.lastName}`
                        : ""
                    // `${option.firstName} ${option.lastName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Technician"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setTechnician(newValue);
                    } else {
                      setTechnician("");
                    }
                  }}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Job ID"
                  onChange={(event) => {
                    setJobId(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} style={{ bottom: "0" }}>
                <IconButton variant="contained" onClick={filterCall}>
                  <PageviewIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomTable {...customTableData} />
      </div>
    </>
  );
};
export default InspectionJobReport;
