import { makeStyles } from "@material-ui/core/styles";

import { background } from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    // height: "36.5em",
    // width: "80em"
  },
  link: {
    color: "#005691",
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
  gridItem: {
    margin: "3.5em",
    padding: 0,
  },
  mainContainer: {
    bottom: 0,
  },
  belowContainer: {
    backgroundColor: "#005691",
    fontWeight: "bold",
  },
  footer: {
    backGroundColor: theme.palette.common.white,
    width: "100%",
    zIndex: 9999,
    position: "relative",
  },
  om_logo: {
    height: "2.5rem",
    [theme.breakpoints.down("md")]: {
      height: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "0.5rem",
    },
  },
  appbar: {
    marginTop: "1rem",
    height: "6rem",
    zIndex: theme.zIndex.modal + 1,
  },
  cardHeader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: "1rem",
  },
  omLogoCard: {
    // height: "20vh",
    width: "20vw",
    [`${theme.breakpoints.up("sm")} and (orientation:portrait )`]: {
      width: "30vw",
    },
    [`${theme.breakpoints.down("xs")}`]: {
      width: "45vw",
    },
  },
  loginCard: {
    minWidth: "320px",
    // maxWidth: "30vw",
    height: "fit-content",
    backgroundColor: "#fff",
    padding: "0 1rem 1rem 1rem",
    margin: "2rem",
    [`${theme.breakpoints.down("lg")}  and (orientation: portrait)`]: {
      width: "35vw",
    },
    [`${theme.breakpoints.up("lg")}`]: {
      width: "25vw",
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      marginTop: "15%",
    },
    [`${theme.breakpoints.down("xs")} and (orientation: landscape)`]: {
      marginTop: "25%",
    },
    [`${theme.breakpoints.down("xs")} and (orientation: portrait)`]: {
      minWidth: "300px",
    },

    boxShadow: theme.shadows[10],
  },
  registerCard: {
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      marginTop: "30%",
    },
    [`${theme.breakpoints.down("xs")} and (orientation: landscape)`]: {
      marginTop: "40%",
    },
  },
  parentDiv: {
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  testLabel: {
    color: "black",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "0.5em",
  },
  loginLogo: {
    position: "absolute",
    width: "4.5rem",
    fontWeight: 900,
    height: "auto",
    marginLeft: "0.5rem",
    marginTop: "-4.23rem",
  },
  passwordButton: {
    fontWeight: 600,
    textDecoration: "none",
    borderRadius: 0,
    borderWidth: 1.5,
    width: "12.5em",
    height: "3em",
  },
  listGroup: {
    paddingTop: "0",
  },
  textfieldHeader: {
    "&::after": {
      content: "*",
      color: "#EA0016",
    },
  },
  pointerLink: {
    cursor: "pointer",
    color: "#005691",
    fontSize: "0.75em",
  },
  Manage_users_icon: {
    "&::before": {
      content: "e901",
    },
  },
}));

export default useStyles;
