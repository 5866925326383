/*global _appConstants */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Button,
  // Typography,
  Grid,
  CssBaseline,
  Hidden,
} from "@material-ui/core";
import { omLogo, boschLogo, boschBanner } from "../../../../assets/images";
import axios from "axios";
import TokenStorage from "../../../../js/utils/tokenStorage";
import { useDispatch } from "react-redux";
import { OAuth as OAuthActions } from "../../../../js/actions";
import jwt from "jwt-decode";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    boxShadow: "initial",
  },
  appBar: {
    background: "transparent",
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    display: "inline-block",
    height: "2rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  omLogo: {
    display: "inline-block",
    height: "4rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  menuButton: {
    border: "none",
    cursor: "pointer",
    // display: "flex",
    padding: theme.spacing(2),
    "&:hover": {},
  },
  menuButtonOpen: {
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    // boxShadow: "6px 6px 6px #0000000F",
    // backgroundColor: theme.palette.drawerColor,
  },
  menuButtonClose: {
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(10) + 1,
    // },
    // boxShadow: "6px 6px 6px #0000000F",
  },
  line: {
    fill: "none",
    stroke: "#293036",
    "stroke-width": "6",
    transition:
      "stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  line1: {
    "stroke-dasharray": "60 207",
    "stroke-width": "6",
  },
  line2: {
    "stroke-dasharray": "60 60",
    "stroke-width": "6",
  },
  line3: {
    "stroke-dasharray": "60 207",
    "stroke-width": "6",
  },
  crossline1: {
    "stroke-dasharray": "90 207",
    "stroke-dashoffset": "-134",
    "stroke-width": "6",
  },
  crossline2: {
    "stroke-dasharray": "1 60",
    "stroke-dashoffset": "-30",
    "stroke-width": "6",
  },
  crossline3: {
    "stroke-dasharray": "90 207",
    "stroke-dashoffset": "-134",
    "stroke-width": "6",
  },
  hamburgerSize: {
    width: theme.spacing(6),
    height: "3em",
  },
  banner: {
    height: "1.5vh",
  },
}));

const Topbar = (props) => {
  const {
    // className,
    onToggle,
    isOpen,
    history,
    ...rest
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [checked, setChecked] = useState([]);
  useEffect(async () => {
    const token = TokenStorage.getToken();
    const cookieData = TokenStorage.getMultiTabData();
    if (token) {
      axios.defaults.headers.common["Authorization"] =
        cookieData.token_type + " " + cookieData.access_token;
      await dispatch(OAuthActions.multiTabCookieHandler(cookieData));
      await dispatch(
        OAuthActions.getLoginUser(cookieData.user_uid)
      ).then(function (flag) {});
    }
    window.addEventListener("storage", (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem("token_name");
        if (token == undefined) {
          // Perform logout
          //Navigate to login/home
          history.push(_appConstants.useCommonDefaults.navRef.login);
        }
      }
    });
  }, []);

  // const handleToggle = (id) => () => {
  //   const currentIndex = checked.indexOf(id);
  //   const newChecked = [...checked];
  //   if (checked.includes(id)) {
  //     newChecked.splice(currentIndex, 1);
  //   } else {
  //     newChecked.push(id);
  //   }
  //   setChecked(newChecked);
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar {...rest} position="fixed" style={{ zIndex: 999 }}>
        <img
          className={classes.banner}
          alt="banner"
          src={boschBanner}
          height="1vh"
        />
        <Grid container alignItems="center" container direction="row">
          <Grid
            item
            // xs={isOpen ? 2 : 1}
          >
            <Hidden xsDown>
              <Button
                aria-label="open drawer"
                className={clsx(classes.menuButton, {
                  [classes.opened]: isOpen,
                  [classes.menuButtonOpen]: isOpen,
                  [classes.menuButtonClose]: isOpen,
                })}
                color="inherit"
                edge="start"
                onClick={(e) => {
                  onToggle(e, !isOpen);
                  // this.classList.toggle("opened");
                  // this.setAttribute(
                  //   "aria-expanded",
                  //   this.classList.contains("opened")
                  // );
                }}
              >
                <svg className={classes.hamburgerSize} viewBox="0 0 100 100">
                  <path
                    className={clsx(classes.line, {
                      [classes.line1]: !isOpen,
                      [classes.crossline1]: isOpen,
                    })}
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path
                    className={clsx(classes.line, {
                      [classes.line3]: !isOpen,
                      [classes.crossline3]: isOpen,
                    })}
                    d="M 20,50 H 80"
                  />
                  <path
                    className={clsx(classes.line, {
                      [classes.line3]: !isOpen,
                      [classes.crossline3]: isOpen,
                    })}
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </Button>
            </Hidden>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={11}
            lg={11}
            // xs={isOpen ? 10 : 11}
          >
            <Toolbar>
              <Grid alignItems="center" container direction="row">
                <Grid item sm={8} xs={6}>
                  <img className={classes.logo} alt="OMimg" src={boschLogo} />
                </Grid>
                <Grid align="right" item xs={6} sm={4}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        className={classes.omLogo}
                        alt="OMimg"
                        src={omLogo}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default Topbar;
