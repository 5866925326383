import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";

// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import { OAuth as OAuthActions } from "../../js/actions";
import { inspectionJobReportIcon } from "../../assets/images";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  inProgress: {
    backgroundColor: "#fbdaa7",
    padding: "0.75em",
    color: "#F39C12",
  },
  completed: {
    backgroundColor: "#bff6eb",
    padding: "0.75em",
    color: "#16A085",
  },
  notCompleted: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
  new: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
}));

const tableIcons = {
  file: React.forwardRef((props, ref) => (
    <DescriptionOutlinedIcon {...props} ref={ref} />
  )),
  Receipt: React.forwardRef((props, ref) => (
    <AssignmentTurnedInIcon {...props} ref={ref} />
  )),
};

const ManageInvoiceData = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [dataSet, setDataSet] = React.useState([]);
  const [shipmentNumber, setShipmentNumber] = React.useState([]);
  const [partNumber, setPartNumber] = React.useState([]);
  const [dateHelper, setDateHelper] = React.useState("");
  const [endDateHelper, setEndDateHelper] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [minEndDate, setMinEndDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [dateSelectedFlag, setDateSelectedFlag] = React.useState(false);
  const [invoiceStatusArray, setInvoiceStatusArray] = React.useState([]);
  const [filterFlag, setFilterFlag] = React.useState(false);
  const [invoiceFlag, setInvoiceFlag] = React.useState(false);
  let today = new Date();
  today = moment(today).format("YYYY-MM-DD");

  const handleStartDate = (event) => {
    if (event.target.value) {
      setStartDate(event.target.value);
      const tempStartDate = new Date(event.target.value).getTime();
      const tempTodayDate = new Date(today).getTime();
      // if (tempStartDate >= tempTodayDate)
      {
        setDateHelper("");
        setDateSelectedFlag(true);
        setEndDate("");
        let date = new Date(event.target.value);
        date.setDate(date.getDate() + 1);
        date = moment(date).format("YYYY-MM-DD");
        setMinEndDate(date);
      }
      // else {
      //   // setStartDate('');
      //   setDateHelper("Start date can't be previous date");
      //   setEndDate("");
      //   setDateSelectedFlag(false);
      // }
    } else {
      setDateSelectedFlag(false);
    }
  };
  const handleEndDate = (event) => {
    setEndDate(event.target.value);
    const endDaate = new Date(event.target.value).getTime();
    const stDaate = new Date(startDate).getTime();
    if (endDaate <= stDaate) {
      setEndDateHelper("End Date can't be less than start date");
    } else {
      setEndDateHelper("");
    }
  };

  const RefreshDataSet = async () => {
    dispatch(
      OAuthActions.getTweddleInvoiceData(
        "",
        shipmentNumber,
        "",
        encodeURIComponent(partNumber),
        invoiceStatusArray
          .map((status) => {
            return status.id;
          })
          .toString(),
        "",
        startDate,
        endDate
      )
    ).then(function (tweddleData) {
      let newDataSet = [];
      console.log(
        "tweddleData",
        tweddleData?._embedded?.tweddleShipmentDetailsDtoes
      );
      console.log("RefreshDataSet");
      for (
        let index = 0;
        index < tweddleData?._embedded?.tweddleShipmentDetailsDtoes.length;
        index++
      ) {
        const element = {
          amountPreviouslyPaid:
            "$ " +
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .amountPreviouslyPaid,
          amountReceived:
            "$ " +
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .amountReceived,
          balance:
            "$ " +
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index].balance,
          bankClearanceDate:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .bankClearanceDate,
          boschInvoiceNumber:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .boschInvoiceNumber,
          currentBoschUnitPrice:
            "$ " +
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .currentBoschUnitPrice,
          description:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .description,
          dueDate:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .dueDate != null
              ? new Date(
                  tweddleData?._embedded?.tweddleShipmentDetailsDtoes[
                    index
                  ].dueDate
                ).toLocaleDateString("en-US")
              : "--",
          fordPaymentNumber:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .fordPaymentNumber,
          fordRemittanceTransNumber:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .fordRemittanceTransNumber,
          partNumber:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .partNumber,
          perviousBoschUnitPrice:
            "$ " +
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .perviousBoschUnitPrice,
          quantityPaidByFord:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .quantityPaidByFord,
          shipmentNumber:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .shipmentNumber,
          shippedDate: new Date(
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[
              index
            ].shippedDate
          ).toLocaleDateString("en-US"),
          shippedQuantity:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .shippedQuantity,
          status:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index].status,
          tweddleShipmentDetailsUid:
            tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index]
              .tweddleShipmentDetailsUid,
          uid: tweddleData?._embedded?.tweddleShipmentDetailsDtoes[index].uid,
        };
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  };
  React.useEffect(() => {
    RefreshDataSet();
  }, [filterFlag]);

  const columns = [
    {
      title: "Shipped Date",
      field: "shippedDate",
      editable: "never",
    },
    {
      title: "Shipment Number",
      field: "shipmentNumber",
      editable: "never",
    },
    {
      title: "Shipment Quantity",
      field: "shippedQuantity",
      editable: "never",
    },
    {
      title: "Part Number",
      field: "partNumber",
      editable: "never",
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
    },
    {
      title: "Due Date",
      field: "dueDate",
      editable: "never",
    },
    // {
    //   title: "status",
    //   field: "status",
    //   editable: "never",
    // },
    {
      title: "Invoice Status",
      field: "status",

      render: (rowData) => {
        // let val1 = rowData.shipmentNumber % 2 == 0;
        // const date1 = new Date(rowData.shippedDate);
        // const date2 = new Date("02/9/2021");
        // const diffTime = Math.abs(date2 - date1);
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // console.log(diffTime + " milliseconds");
        // console.log(diffDays + " days");
        // let val2 = diffDays > 239;
        // let val3 = rowData.shippedQuantity % 2 == 0;
        // let fval = 0;
        // if (val1 == true && val3 == false) fval = 1;
        // else if (val1 == false && val2 == true) fval = 2;
        // else fval = 3;
        switch (rowData.status) {
          case "PAID": //Yes
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.completed}
              >
                <Grid item>
                  <CheckIcon />
                </Grid>
                <Grid item>Paid</Grid>
              </Grid>
            );
            break;
          case "SENT": //Yes
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.inProgress}
              >
                <Grid item>
                  <CheckIcon />
                </Grid>
                <Grid item>sent</Grid>
              </Grid>
            );
            break;
          case "OVERDUE":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.notCompleted}
              >
                <Grid item>
                  <CloseIcon />
                </Grid>
                <Grid item>Overdue</Grid>
              </Grid>
            );
            break;
          case "NEW":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.new}
              >
                <Grid item>
                  <FiberNewIcon />
                </Grid>
                <Grid item>New</Grid>
              </Grid>
            );
            break;
          default:
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                {" "}
                <Grid item>N/A</Grid>
              </Grid>
            );
            break;
        }
      },
    },
  ];

  const updateData = (newData, oldData) => {
    console.log("updateData", newData, oldData);
    return true;
  };
  const onRowClick = (event, rowData) => {
    console.log("onRowClick", rowData);
    return true;
  };

  const filterCall = () => {
    RefreshDataSet();
    setFilterFlag(true);
    console.log(
      "filterCall",
      shipmentNumber,
      partNumber,
      startDate,
      endDate,
      invoiceStatusArray
        .map((status) => {
          return status.id;
        })
        .toString()
    );
  };

  const selectionPropsDisable = (rowData) => {
    rowData.tableData.disabled = rowData.status !== "OVERDUE";
    return {
      disabled: rowData.status !== "OVERDUE",
    };
  };

  const clearFlterCall = () => {
    setShipmentNumber("");
    setPartNumber("");
    setStartDate("");
    setEndDate("");
    setInvoiceStatusArray([]);
    setFilterFlag(false);
  };

  const exportToCSV = (details) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    debugger;
    const ws = XLSX.utils.json_to_sheet(details);
    const wb = {
      Sheets: { "Shipping Payment Report": ws },
      SheetNames: ["Shipping Payment Report"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      "Ford Bosch Shipping Payment Report" + fileExtension
    );
  };

  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: updateData,
    onRowClick: onRowClick,
    searchState: invoiceFlag,
    selection: invoiceFlag,
    selectionProps: selectionPropsDisable,
    tableLayout: "auto",
    actions: [
      {
        icon: tableIcons.file,
        tooltip: "More Details",
        // style: {
        //   backgroundColor: "#F5F5F5",
        // },
        position: "row",
        onClick: (event, rowData) => {
          props.history.push({
            pathname: _appConstants.useCommonDefaults.navRef.viewInvoiceDetails,
            rowdata: rowData,
          });
        },
      },
      {
        tooltip: "Update All Selected Invoice And Export",
        icon: tableIcons.Receipt,
        position: "toolbarOnSelect",
        onClick: (evt, data) => {
          console.log("DATA", data);
          const selectedInvoice = data.map((invoice) => {
            let invoiceDetails = {
              tweddleShipmentDetailsUid: invoice.tweddleShipmentDetailsUid,
              partNumber: invoice?.partNumber,
            };
            return invoiceDetails;
          });

          const selectedInvoiceExcel = data.map((invoiceExcel) => {
            let invoiceExcelDetails = {
              "Shipped Date": moment(invoiceExcel.shippedDate).format(
                "MM/DD/YYYY"
              ),
              "Shipment Number": invoiceExcel.shipmentNumber,
              "Shipped Quantity": invoiceExcel.shippedQuantity,
              "Part Number": invoiceExcel.partNumber,
              " Description": invoiceExcel.description,
              "Ford Remittance Trans Number":
                invoiceExcel.fordRemittanceTransNumber,
              "Ford Payment Number": invoiceExcel.fordPaymentNumber,
              "Bank Clearance Date": invoiceExcel.bankClearanceDate,
              "Previous Bosch Unit Price": invoiceExcel.perviousBoschUnitPrice,
              "Current Bosch Unit Price": invoiceExcel.currentBoschUnitPrice,
              "Quantity Paid By Ford": invoiceExcel.quantityPaidByFord,
              "Amount Received": invoiceExcel.amountReceived,
              "Amount Previously Paid": invoiceExcel.amountPreviouslyPaid,
              " Balance": invoiceExcel.balance,
              "Bosch Invoice Number": invoiceExcel.boschInvoiceNumber,
              "Due Date":
                invoiceExcel.dueDate != "--"
                  ? moment(invoiceExcel.dueDate).format("MM/DD/YYYY")
                  : "",
            };
            return invoiceExcelDetails;
          });
          // exportToCSV(selectedInvoiceExcel);
          console.log("selectedInvoice DATA", selectedInvoice, data);

          dispatch(OAuthActions.postSentInvoiceData(selectedInvoice)).then(
            function (success) {
              if (success["error"] === undefined) {
                dispatch(
                  setSnackbar(true, "success", "Update status Successful")
                );

                RefreshDataSet();
                console.log(
                  "exportToCSV selectedInvoice DATA",
                  selectedInvoiceExcel
                );
                exportToCSV(selectedInvoiceExcel);
                // history.goBack();
              } else
                dispatch(
                  setAlertDialog(
                    true,
                    null,
                    "error",
                    "Update status:",
                    "Update status unsuccessful.\nDetails Of Failure: " +
                      success.message,
                    "Ok",
                    ""
                  )
                );
            }
          );
        },
      },
    ],
    // fixedColumns: {
    //   left: 1,
    //   right: 0,
    // },
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item p={0} m={0}>
                <img
                  src={inspectionJobReportIcon}
                  style={{ height: "2em", margin: "0 1em" }}
                />
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2">
                    <strong>Tweddle ERP Invoice Data:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                {/* <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddRole}
                  startIcon={<AddIcon />}
                >
                  Add Role
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <Grid
              container
              style={{
                padding: "1em 0",
                backgroundColor: "whitesmoke",
              }}
              justify="space-around"
              justify="flex-start"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  padding: "0 1em",
                }}
              >
                <TextField
                  fullWidth
                  label="Shipment Number"
                  value={shipmentNumber}
                  onChange={(event) => {
                    setShipmentNumber(event.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  padding: "0 1em",
                }}
              >
                <TextField
                  fullWidth
                  label="Part Number"
                  value={partNumber}
                  onChange={(event) => {
                    setPartNumber(event.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  padding: "0 1em",
                }}
              >
                <TextField
                  fullWidth
                  error={dateHelper.length !== 0}
                  helperText={dateHelper}
                  label="Start Shipped Date"
                  type="date"
                  value={startDate}
                  inputProps={
                    {
                      // min: today,
                    }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleStartDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  padding: "0 1em",
                }}
              >
                <TextField
                  fullWidth
                  error={endDateHelper.length !== 0}
                  helperText={endDateHelper}
                  label="End Shipped Date"
                  type="date"
                  value={endDate}
                  inputProps={{
                    min: minEndDate,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEndDate}
                  disabled={!dateSelectedFlag}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{ bottom: "0", padding: "0 1em" }}
              >
                <Autocomplete
                  multiple
                  value={invoiceStatusArray}
                  options={_appConstants.invoiceStatusArray}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    let newValueSet = newValue.reduce(
                      (accumulator, current) => {
                        if (
                          !accumulator.some((x) => x.label === current.label)
                        ) {
                          accumulator.push(current);
                        }
                        return accumulator;
                      },
                      []
                    );

                    setInvoiceStatusArray([...new Set([...newValueSet])]);
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={`${option.label}`}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Status"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{ bottom: "0", padding: "0 1em" }}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                {/* <IconButton variant="contained" onClick={filterCall}>
                  <PageviewIcon fontSize="large" />
                </IconButton> */}
                <Grid item>
                  <Button
                    size="medium"
                    fullwidth
                    variant="contained"
                    onClick={filterCall}
                    startIcon={<SearchOutlinedIcon />}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="medium"
                    fullwidth
                    variant="outlined"
                    onClick={clearFlterCall}
                    startIcon={<ClearIcon />}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ bottom: "0", padding: "0 1em" }}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                size="medium"
                fullwidth
                variant="contained"
                onClick={() => setInvoiceFlag(!invoiceFlag)}
                // startIcon={<FileDownloadIcon />}
              >
                Toggle Invoice Generation:
                {invoiceFlag ? " Hide Option" : " Show Option"}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <CustomTable {...customTableData} style="overflow: show" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageInvoiceData;
