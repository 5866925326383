import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { InputAdornment } from "@material-ui/core";
// import Button from "@material-ui/core/Button";

// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import { OAuth as OAuthActions } from "../../js/actions";
import { inspectionJobReportIcon } from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  avatar: {
    backgroundColor: "#01579B",
  },
  present: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
  absent: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
}));

const ManageBoschMasterData = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [dataSet, setDataSet] = React.useState([]);

  const RefreshDataSet = async () => {
    dispatch(OAuthActions.getBoshKitData()).then(function (boshKitData) {
      let newDataSet = [];
      console.log("boshKitData", boshKitData?._embedded?.boschKitDataDtoes);
      console.log("RefreshDataSet");
      for (
        let index = 0;
        index < boshKitData?._embedded?.boschKitDataDtoes.length;
        index++
      ) {
        const element = {
          entryDate: boshKitData?._embedded?.boschKitDataDtoes[index].entryDate,
          kit: boshKitData?._embedded?.boschKitDataDtoes[index].kit,
          kitPrice: boshKitData?._embedded?.boschKitDataDtoes[index].kitPrice,
          modelYear: boshKitData?._embedded?.boschKitDataDtoes[index].modelYear,
          monthlyEstimatedVolumes:
            boshKitData?._embedded?.boschKitDataDtoes[index]
              .monthlyEstimatedVolumes,
          partNumber:
            boshKitData?._embedded?.boschKitDataDtoes[index].partNumber,
          program: boshKitData?._embedded?.boschKitDataDtoes[index].program,
          region:
            boshKitData?._embedded?.boschKitDataDtoes[index].region == ""
              ? "--"
              : boshKitData?._embedded?.boschKitDataDtoes[index].region,
          totalVolume:
            boshKitData?._embedded?.boschKitDataDtoes[index].totalVolume,
          tweddleDescription:
            boshKitData?._embedded?.boschKitDataDtoes[index].tweddleDescription,
          uid: boshKitData?._embedded?.boschKitDataDtoes[index].uid,
        };
        console.log("boshKitData element index", index, element);
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  };
  React.useEffect(() => {
    RefreshDataSet();
  }, []);

  const columns = [
    {
      title: "Part Number",
      field: "partNumber",
      editable: "never",
    },
    {
      title: "Tweddle Description",
      field: "tweddleDescription",
      editable: "never",
    },
    {
      title: "Model Year",
      field: "modelYear",
      editable: "never",
    },
    {
      title: "Program",
      field: "program",
      editable: "never",
    },
    {
      title: "Region",
      field: "region",
      editable: "never",
    },
    {
      title: "Kit",
      field: "kit",
      editable: "never",
    },
    {
      title: "Total Volume",
      field: "totalVolume",
      type: "numeric",
    },
    {
      title: "Monthly Extimated Volume",
      field: "monthlyEstimatedVolumes",
      type: "numeric",
    },
    {
      title: "Kit Price",
      field: "kitPrice",
      type: "currency",
      // editComponent: (rowData) => (
      //   <TextField
      //     variant="outlined"
      //     defaultValue={rowData.rowData.kitPrice}
      //     onChange={(e) => {
      //       // setLastName(e.target.value);
      //       rowData.onChange(e.target.value);
      //     }}
      //     size="small"
      //     autoFocus
      //   />
      // ),
    },
    {
      title: "Entry Date",
      field: "entryDate",
      type: "date",
      dateSetting: { locale: "en-US" },

      // editComponent: (rowData) => (
      //   <TextField
      //     variant="outlined"
      //     defaultValue={rowData.rowData.entryDate}
      //     onChange={(e) => {
      //       // setLastName(e.target.value);
      //       rowData.onChange(e.target.value);
      //     }}
      //     size="small"
      //     autoFocus
      //   />
      // ),
    },
  ];

  // const handleAddRole = (event) => {
  //   props.history.push(_appConstants.useCommonDefaults.navRef.role.addRole);
  // };

  const updateData = (newData, oldData) => {
    console.log("updateData newData:", newData);
    console.log("updateData oldData:", oldData);
    console.log(
      "newData.totalVolume === oldData.totalVolume",
      newData.totalVolume,
      oldData.totalVolume
    );
    debugger;
    console.log("dataSet.entryDate.toISOString ", dataSet.entryDate);

    if (newData.totalVolume < 0) {
      dispatch(
        setSnackbar(true, "info", "Total Volume cannot be a negative value.")
      );
    } else if (newData.monthlyEstimatedVolumes < 0) {
      dispatch(
        setSnackbar(
          true,
          "info",
          "Monthly Estimated Volume cannot be a negative value"
        )
      );
    } else if (newData.kitPrice < 0) {
      dispatch(
        setSnackbar(true, "info", "Kit Price cannot be a negative value")
      );
    } else if (
      newData.totalVolume == oldData.totalVolume &&
      newData.monthlyEstimatedVolumes == oldData.monthlyEstimatedVolumes &&
      newData.kitPrice == oldData.kitPrice &&
      newData.entryDate == oldData.entryDate
    ) {
      dispatch(
        setSnackbar(
          true,
          "info",
          "Updated record saved is same as previous record. Kindly check if you have changed the details."
        )
      );
      console.log("oldData.entryDate  Failed: same data", newData.entryDate);
    } else {
      console.log("oldData.entryDate Success", newData.entryDate);
      updateDataSet(newData);
      return true;
    }
  };
  const onRowClick = (event, rowData) => {
    console.log("onRowClick", rowData);
    return true;
  };

  const updateDataSet = async (dataSet) => {
    await dispatch(
      OAuthActions.updateBoshKitData(
        dataSet.uid,
        dataSet.totalVolume,
        dataSet.monthlyEstimatedVolumes,
        dataSet.kitPrice,
        dataSet.entryDate
      )
    ).then(function (userDetails) {
      if (userDetails["error"] === undefined) {
        RefreshDataSet();
        dispatch(setSnackbar(true, "success", "Update Successful"));
      } else dispatch(setAlertDialog(true, null, "error", "Update Kit Details:", "Update unsuccessful.\nDetails Of Failure: " + userDetails.message, "Ok", ""));
    });
  };

  const customTableData = {
    isReadOnly: false,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: updateData,
    onRowClick: onRowClick,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item p={0} m={0}>
                <img
                  src={inspectionJobReportIcon}
                  style={{ height: "2em", margin: "0 1em" }}
                />
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2">
                    <strong>Bosch Master Data For Vehicle Kit:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                {/* <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddRole}
                  startIcon={<AddIcon />}
                >
                  Add Role
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageBoschMasterData;
