import React, { useState, useReducer, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { roleIcon, manageVehicleIcon } from "../../assets/images";
import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import { OAuth as OAuthActions } from "../../js/actions";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  present: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
  absent: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
  avatar: {
    backgroundColor: "#01579B",
  },
}));

const ManageVehicle = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [dataSet, setDataSet] = React.useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleClick = () => {
    forceUpdate();
  };

  const RefreshDataSet = async () => {
    dispatch(OAuthActions.getVehicleDetails(makeSelected.uid)).then(function (
      vehicleDetails
    ) {
      let newDataSet = [];
      console.log("RefreshDataSet vehicleDetails", vehicleDetails);
      for (
        let index = 0;
        index < vehicleDetails?._embedded?.vehicleModelDtoes.length;
        index++
      ) {
        const element = {
          uid: vehicleDetails?._embedded?.vehicleModelDtoes[index].uid,
          modelName:
            vehicleDetails?._embedded?.vehicleModelDtoes[index].modelName,
          modelCode:
            vehicleDetails?._embedded?.vehicleModelDtoes[index].modelCode,
          variant: vehicleDetails?._embedded?.vehicleModelDtoes[index].variant,
          yearOfManufacturing:
            vehicleDetails?._embedded?.vehicleModelDtoes[index]
              .yearOfManufacturing,
          market: vehicleDetails?._embedded?.vehicleModelDtoes[index].market,
        };
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  };
  React.useEffect(() => {
    RefreshDataSet();
  }, [makeSelected]);

  const columns = [
    {
      title: "Model Name",
      field: "modelName",
      editable: "never",
    },
    {
      title: "Model Code",
      field: "modelCode",
      editable: "never",
    },

    {
      title: "Year of Manufacturing",
      field: "yearOfManufacturing",
      editable: "never",
    },
    {
      title: "Variant",
      field: "variant",
      editable: "never",
    },
    {
      title: "Market",
      field: "market",
      editable: "never",
    },
  ];

  const handleAddVehicle = (event) => {
    props.history.push(
      _appConstants.useCommonDefaults.navRef.vehicle.addVehicle
    );
  };

  const handlestatus = (event) => {
    //
  };

  const handlegrade = (event) => {};
  const handlecourseName = (event) => {};

  const handleComments = (event) => {};

  const canceledClicked = (event) => {};

  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: null,
    onRowClick: null,
    searchState: false,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <img
                    src={manageVehicleIcon}
                    style={{ height: "2em", margin: "0 1em" }}
                  />
                  <Typography variant="h2">
                    <strong>Manage Vehicle:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddVehicle}
                  startIcon={<AddIcon />}
                >
                  Add Vehicle
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageVehicle;
