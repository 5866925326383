import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { inspectionDashboard } from "../../assets/images";
import { CustomTable, CustomProgressBar } from "../../components";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Avatar from "@material-ui/core/Avatar";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import PieGraph from "../Pie/index";
// import { refreshOAuthToken } from "../../js/actions/OAuth";
import { useDispatch } from "react-redux";
import { OAuth as OAuthActions } from "../../js/actions";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as _ from "lodash";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    // border: "2px solid whitesmoke",
  },
  // document: {
  //   color: "#01579B",
  // },
  selected: {
    backgroundColor: "#01579B",
    color: "#ffff",
  },
  new: {
    backgroundColor: "#16a085",
    color: "#fff",
  },
  inprogress: {
    backgroundColor: "#2980b9",
    color: "#fff",
  },
  completed: {
    backgroundColor: "#2c3e50",
    color: "#fff",
  },
  cancelled: {
    backgroundColor: "#c0392b",
    color: "#fff",
  },
  avatar: {
    backgroundColor: "#01579B",
    width: "25px",
    height: "25px",
  },
}));
const InspectionDashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statusData, setStatusData] = useState({});
  const childRef = useRef();
  // const [makId, setMakId] = useState("");
  const [totalDataSet, setTotalDataSet] = useState([]);
  const [dataSett, setDataSett] = useState([]);
  const [makeSelected, setMakeSelected] = useContext(Context);

  const columns = [
    {
      title: "Model",
      field: "modelName",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
        width: "120px",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.modelName}
          size="small"
        />
      ),
    },
    {
      title: "Market",
      field: "market",
      editable: "never",
      editComponent: (rowData) => (
        <TextField
          id="GRD_edit-country"
          variant="outlined"
          defaultValue={rowData.rowData.market}
          size="small"
        />
      ),
    },
    {
      title: "Year",
      field: "year",
      editable: "never",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.year}
          size="small"
        />
      ),
    },
    {
      title: "Inspection Date",
      field: "inspectionDate",
      editable: "never",
      cellStyle: {
        width: "120px",
      },
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={moment(rowData.rowData.inspectionDate).format(
            "DD/MM/YYYY"
          )}
          size="small"
        />
      ),
    },
    {
      title: "Technician",
      field: "technician",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      render: (rowData) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Avatar className={classes.avatar}></Avatar>
          </Grid>
          {rowData.technicians.length && (
            <Grid item xs>
              {rowData.technicians.map((value) => (
                <Typography>{`${value} `}</Typography>
              ))}
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      title: "Plant Details",
      field: "plantDetails",
      editable: "never",
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.plantDetails}
          size="small"
        />
      ),
    },
    {
      title: "Status",
      field: "status",
      cellStyle: {
        backgroundColor: "#F5F5F5",
      },
      lookup: {
        COMPLETED: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.completed}
          >
            <Grid item>
              <DoneAllOutlinedIcon />
            </Grid>
            <Grid item>Completed</Grid>
          </Grid>
        ),
        NEW: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.new}
          >
            <Grid item>
              <DescriptionOutlinedIcon />
            </Grid>
            <Grid item>New</Grid>
          </Grid>
        ),
        IN_PROGRESS: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.inprogress}
          >
            <Grid item>
              <HourglassEmptyOutlinedIcon />
            </Grid>
            <Grid item>In Progress</Grid>
          </Grid>
        ),
        CANCELLED: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.cancelled}
          >
            <Grid item>
              <HighlightOffOutlinedIcon />
            </Grid>
            <Grid item>Cancelled</Grid>
          </Grid>
        ),
      },

      editComponent: (rowData) => (
        <Select
          defaultValue={rowData.rowData.status}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={1}>Present</MenuItem>
          <MenuItem value={0}>Absent</MenuItem>
        </Select>
      ),
    },
    {
      title: "Inspection  Status",
      field: "inspectionProgress",
      editable: "never",
      cellStyle: {
        width: "120px",
      },
      render: (rowData) => (
        <CustomProgressBar
          progress={rowData.inspectionProgress}
          status={rowData.status}
        />
      ),
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);
  const customTableData = {
    isReadOnly: true,
    dataSet: dataSett,
    columns: columns,
    actions: [],
  };
  const changeStatuss = async (status) => {
    if (status.clicked === true) {
      const index =
        status.name === "New"
          ? 0
          : status.name === "Inprogress"
          ? 1
          : status.name === "Completed"
          ? 2
          : 3;
      let processedData = [];
      let filterData = _.cloneDeep(totalDataSet);
      const name =
        status.name === "Inprogress"
          ? "IN_PROGRESS"
          : status.name.toUpperCase();
      filterData = filterData.filter((m) => m.status === name);
      if (filterData.length !== 0) {
        processedData = filterData;
      }
      await setDataSett(processedData);
      setActiveIndex(index);
    } else {
      const processedData = _.cloneDeep(totalDataSet);
      await setDataSett(processedData);
      setActiveIndex(null);
    }
  };
  useEffect(async () => {
    let totalData = [];
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     // setMakId(makeId.uid);
    //   }
    // });
    const filters = {
      technicianId: "",
      plantId: "",
      model_Name: "",
      marketId: "",
      jobStatus: "",
      yearId: "",
    };
    await dispatch(OAuthActions.getReportsData(makeSelected.uid, filters)).then(
      function (data) {
        if (data) {
          // let totalData = [];
          if (data._embedded && data._embedded.jobBasicDetailsDtoes) {
            totalData = data._embedded.jobBasicDetailsDtoes;
            totalData.find((d) => {
              d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
              d.makeId = makeSelected.uid;
            });
          }
          setTotalDataSet(totalData);
          setDataSett(totalData);
        }
      }
    );
    const NEW = totalData.filter((m) => m.status === "NEW").length;
    const INPROGRESS = totalData.filter(
      (m) => m.status === "IN_PROGRESS"
    ).length;
    const COMPLETED = totalData.filter((m) => m.status === "COMPLETED").length;
    const CANCELLED = totalData.filter((m) => m.status === "CANCELLED").length;
    const a = {
      New: NEW,
      Inprogress: INPROGRESS,
      Completed: COMPLETED,
      Cancelled: CANCELLED,
    };
    setStatusData(a);
  }, [makeSelected]);
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" ustify="center" alignItems="center">
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <img
                    src={inspectionDashboard}
                    style={{ height: "2em", margin: "0 1em" }}
                  />
                  <Typography variant="h2">
                    <strong>Inspection Dashboard:</strong>
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={6}>
                <PieGraph
                  pieDta={{
                    deta: statusData,
                    changeStatus: changeStatuss.bind(this),
                  }}
                  ref={childRef}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  ustify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <Paper
                      onClick={() => {
                        if (activeIndex !== 0) {
                          setActiveIndex(0);
                          let processedData = [];
                          let filterData = _.cloneDeep(totalDataSet);
                          filterData = filterData.filter(
                            (m) => m.status === "NEW"
                          );
                          if (filterData.length !== 0) {
                            processedData = filterData;
                          }
                          setDataSett(processedData);
                          childRef.current.showAlert("0", true);
                        } else {
                          setActiveIndex(null);
                          const processedData = _.cloneDeep(totalDataSet);
                          setDataSett(processedData);
                          childRef.current.showAlert("0", false);
                        }
                      }}
                      className={activeIndex === 0 ? classes.selected : null}
                    >
                      <Box display="flex" alignItems="center" p={1}>
                        <Box
                          flexDirection="column"
                          display="flex"
                          p={1}
                          width="100%"
                        >
                          <AddIcon
                            style={{ color: "#16a085", margin: "0.5em 0" }}
                          />
                          New
                        </Box>
                        <Box p={1} flexShrink={0}>
                          {statusData.New}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      onClick={() => {
                        if (activeIndex !== 1) {
                          setActiveIndex(1);
                          let processedData = [];
                          let filterData = _.cloneDeep(totalDataSet);
                          filterData = filterData.filter(
                            (m) => m.status === "IN_PROGRESS"
                          );
                          if (filterData.length !== 0) {
                            processedData = filterData;
                          }
                          setDataSett(processedData);
                          childRef.current.showAlert("1", true);
                        } else {
                          setActiveIndex(null);
                          const processedData = _.cloneDeep(totalDataSet);
                          setDataSett(processedData);
                          childRef.current.showAlert("1", false);
                        }
                      }}
                      className={activeIndex === 1 ? classes.selected : null}
                    >
                      <Box display="flex" alignItems="center" p={1}>
                        <Box
                          flexDirection="column"
                          display="flex"
                          p={1}
                          spacing={3}
                          width="100%"
                        >
                          <HourglassEmptyIcon
                            style={{ color: "#2980b9", margin: "0.5em 0" }}
                          />
                          In-Progress
                        </Box>
                        <Box p={1} flexShrink={0}>
                          {statusData.Inprogress}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      onClick={() => {
                        if (activeIndex !== 2) {
                          setActiveIndex(2);
                          let processedData = [];
                          let filterData = _.cloneDeep(totalDataSet);
                          filterData = filterData.filter(
                            (m) => m.status === "COMPLETED"
                          );
                          if (filterData.length !== 0) {
                            processedData = filterData;
                          }
                          setDataSett(processedData);
                          childRef.current.showAlert("2", true);
                        } else {
                          setActiveIndex(null);
                          const processedData = _.cloneDeep(totalDataSet);
                          setDataSett(processedData);
                          childRef.current.showAlert("2", false);
                        }
                      }}
                      className={activeIndex === 2 ? classes.selected : null}
                    >
                      <Box display="flex" alignItems="center" p={1}>
                        <Box
                          flexDirection="column"
                          display="flex"
                          p={1}
                          spacing={3}
                          width="100%"
                        >
                          <CheckIcon
                            style={{ color: "#2c3e50", margin: "0.5em 0" }}
                          />
                          Completed
                        </Box>
                        <Box p={1} flexShrink={0}>
                          {statusData.Completed}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      onClick={() => {
                        if (activeIndex !== 3) {
                          setActiveIndex(3);
                          let processedData = [];
                          let filterData = _.cloneDeep(totalDataSet);
                          filterData = filterData.filter(
                            (m) => m.status === "CANCELLED"
                          );
                          if (filterData.length !== 0) {
                            processedData = filterData;
                          }
                          setDataSett(processedData);
                          childRef.current.showAlert("3", true);
                        } else {
                          setActiveIndex(null);
                          const processedData = _.cloneDeep(totalDataSet);
                          setDataSett(processedData);
                          childRef.current.showAlert("3", false);
                        }
                      }}
                      className={activeIndex === 3 ? classes.selected : null}
                    >
                      <Box display="flex" alignItems="center" p={1}>
                        <Box
                          flexDirection="column"
                          display="flex"
                          p={1}
                          spacing={3}
                          width="100%"
                        >
                          <CloseIcon
                            style={{ color: "#c0392b", margin: "0.5em 0" }}
                          />
                          Cancelled
                        </Box>
                        <Box p={1} flexShrink={0}>
                          {statusData.Cancelled}
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <br />
            <Divider />

            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default InspectionDashboard;
