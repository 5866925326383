import React, { useState } from "react";
import useStyles from "../../assets/styles/styles";
import { omLogo } from "../../assets/images";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { OAuth as OAuthActions } from "../../js/actions";

import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FooterTabs } from "../index";

const NewUserCard = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [newPasswordHelper, setNewPasswordHelper] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const history = useHistory();

  const onChange = (event) => {
    let valid;
    switch (event.target.id) {
      case "password":
        setPassword(event.target.value);
        valid = /(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          event.target.value
        );
        if (!valid && event.target.value.length !== 0) {
          setPasswordHelper(
            "The password must contain at least 1 number,1 special character(!,@,#,$,%,^,&,*) and must be at least 8 characters in length"
          );
        } else {
          setPasswordHelper("");
        }
        break;
      case "newPassword":
        setNewPassword(event.target.value);
        valid = /(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          event.target.value
        );
        if (!valid && event.target.value.length !== 0) {
          setNewPasswordHelper(
            "The password must contain at least 1 number,1 special character(!,@,#,$,%,^,&,*) and must be at least 8 characters in length"
          );
        } else if (password !== event.target.value) {
          console.log(
            "The password",
            password,
            "newPassword",
            event.target.value
          );
          setNewPasswordHelper(
            "The above password and the confirmation password must be same."
          );
        } else {
          //
          setNewPasswordHelper("");
        }
        break;
      case "termsOfUse":
        setTermsOfUse(event.target.checked);
        break;
      case "privacyPolicy":
        setPrivacyPolicy(event.target.checked);
        break;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickPrivacyOpen = () => {
    setOpenPrivacy(true);
  };

  const handleClose = () => {
    console.log("handleClose Parent");
    setOpen(false);
  };
  const handlePrivacyClose = () => {
    setOpenPrivacy(false);
  };

  const submit = () => {
    dispatch(OAuthActions.register(id, password)).then(function (success) {
      if (success["error"] === undefined) {
        //
        dispatch(
          setAlertDialog(
            true,
            null,
            "success",
            "New Password:",
            "New Password set successfully. Note: Please contact the administrator to activate your account, if you are a new user.",
            "Ok",
            ""
          )
        );
        history.push("/login");
      } else dispatch(setAlertDialog(true, null, "error", "New Password:", "New Password set is unsuccessful.\nDetails Of Failure: " + success.message, "Ok", ""));
    });
  };

  return (
    <div className={classes.parentDiv}>
      <div className={`${classes.loginCard} ${classes.registerCard}`}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className={classes.cardHeader}>
              <img
                src={omLogo}
                alt="login_logo"
                className={classes.omLogoCard}
              />
            </div>
          </Grid>
          <Grid item>
            <Typography
              style={{ color: "#005691" }}
              variant="h3"
              align="center"
            >
              New Password
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            <br />
            Please enter your new password. <br />
            This will henceforth be used as the password to your account.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            required
            error={passwordHelper.length !== 0}
            helperText={passwordHelper}
            id="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter your Password"
            value={password}
            onChange={(event) => onChange(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            required
            error={newPasswordHelper.length !== 0}
            helperText={newPasswordHelper}
            id="newPassword"
            label="Confirm Password"
            type={showNewPassword ? "text" : "password"}
            placeholder="Re-enter your Password"
            value={newPassword}
            onChange={(event) => onChange(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ padding: "0.5rem" }}>
          <Checkbox
            id="termsOfUse"
            checked={termsOfUse}
            onChange={(event) => onChange(event)}
            color="primary"
          />
          <Typography variant="caption">
            I accept the{" "}
            <em
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleClickOpen}
            >
              terms and conditions
            </em>
          </Typography>
          <FooterTabs clickValue={3} open={open} onClose={handleClose} />
        </Grid>
        <Grid item style={{ padding: "0.5rem" }}>
          <Checkbox
            id="privacyPolicy"
            checked={privacyPolicy}
            onChange={(event) => onChange(event)}
            color="primary"
          />
          <Typography variant="caption">
            I acknowledge the{" "}
            <em
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleClickPrivacyOpen}
            >
              privacy policy
            </em>
          </Typography>
          <FooterTabs
            clickValue={1}
            open={openPrivacy}
            onClose={handlePrivacyClose}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                onClick={() => {
                  setNewPassword("");
                  setPassword("");
                  history.push("/login");
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={submit}
                disabled={
                  password.length === 0 ||
                  passwordHelper.length !== 0 ||
                  newPassword.length === 0 ||
                  newPasswordHelper.length !== 0 ||
                  !termsOfUse ||
                  !privacyPolicy
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(NewUserCard);
