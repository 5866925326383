import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { contentUploadIcon } from "../../assets/images";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import CancelIcon from "@material-ui/icons/Cancel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  // parentDiv: {
  //   width: "40vw",
  //   [theme.breakpoints.down("md")]: {
  //     width: "80vw",
  //   },
  // },
  border: {
    margin: "1vh 0",
  },
}));
const Instructions = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // const [yearSelected, setYearSelected] = useState(false);
  const [file, setFile] = useState(null);
  const [localPath, setLocalPath] = useState("");
  const classes = useStyles();
  const history = useHistory();

  const bindScreen = (e) => {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  useEffect(async () => {}, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12} style={{ minWidth: "100%" }}>
            <div style={{ display: "flex" }}>
              {/* <img
                src={contentUploadIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              /> */}
              <InfoSharpIcon
                color="primary"
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Instructions:</strong>
              </Typography>
              <Grid item xs>
                <CancelIcon
                  color="primary"
                  style={{ float: "right" }}
                  onClick={props.handleClose}
                />
              </Grid>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Bosch Master Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <List>
                <ListItem disablePadding>
                  <Link
                    style={{ padding: 5, color: "blue" }}
                    onClick={props.downloadMaster}
                  >
                    Note: Download Bosch Master Data template by clicking{" "}
                    <b>
                      <u>here</u>
                    </b>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  1) Download Bosch Master Data template from above and open.
                </ListItem>
                <ListItem disablePadding>
                  2) Open original bosch sheet recieved.
                </ListItem>
                <ListItem disablePadding>
                  3) Copy all the rows data to a "Notepad ++" without column
                  names.
                </ListItem>
                <ListItem disablePadding>
                  4) Remove all the "$" and "," symbols from all the rows and
                  columns by following "Find and Replace" method.
                </ListItem>
                <ListItem disablePadding>
                  5) Add a new "ID" column at the first by using the below
                  formula
                  =CONCATENATE(DEC2HEX(RANDBETWEEN(0,65535),4),"-",DEC2HEX(RANDBETWEEN(16384,20479),4),"-",DEC2HEX(RANDBETWEEN(32768,49151),4),"-",DEC2HEX(RANDBETWEEN(0,65535),4))
                </ListItem>
                <ListItem disablePadding>
                  6) Once an "ID" is generate, drag that id down for how many
                  rows we need.
                </ListItem>

                <ListItem disablePadding>
                  7) Copy and paste all the values from Notepad ++ to the
                  template.
                </ListItem>
                <ListItem disablePadding>
                  8) Check whether all the values pasted in the right columns
                  (Example: Shipped quantity value to be pasted in Shipped
                  quantity column).
                </ListItem>
                <ListItem disablePadding>
                  9) Save the modified document.
                </ListItem>
                <ListItem disablePadding>
                  10) Upload that modified document by using OM Utility portal.
                </ListItem>
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Tweddle Sheet Rules
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <List>
                <ListItem disablePadding>
                  <Link
                    style={{ padding: 5, color: "blue" }}
                    onClick={props.downloadTweddle}
                  >
                    Note: Download Tweddle template by clicking it{" "}
                    <b>
                      <u>here</u>
                    </b>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  1) Download Tweddle ERP Data template from above and open.
                </ListItem>
                <ListItem disablePadding>
                  2) Open the original Tweddle sheet recieved.
                </ListItem>
                <ListItem disablePadding>
                  3) Remove 1st row from the original excel sheet with the text
                  called (Example: Run Date and Time: 1/31/2022 8:56:10 AM).
                </ListItem>
                <ListItem disablePadding>
                  4) Delete all the unused columns from original tweddle sheet
                  if present. Columns count must be equal to 16 compared to
                  template downloaded.
                </ListItem>
                <ListItem disablePadding>
                  5) Delete last rows from the original tweddle sheet. (Example:
                  Lines and Total Count).
                </ListItem>
                <ListItem disablePadding>
                  6) Copy all the rows data to a "Notepad ++" without column
                  names.
                </ListItem>
                <ListItem disablePadding>
                  7) Remove all the "$" and "," symbols from all the rows and
                  columns by following "Find and Replace" method.
                </ListItem>
                <ListItem disablePadding>
                  8) Copy and paste all the values from Notepad ++ to the
                  template.
                </ListItem>
                <ListItem disablePadding>
                  9) Check whether all the values pasted in the right columns
                  (Example: Shipped quantity value to be pasted in Shipped
                  quantity column).
                </ListItem>
                <ListItem disablePadding>
                  10) Save the modified document.
                </ListItem>
                <ListItem disablePadding>
                  11) Upload that modified document by using OM Utility portal.
                </ListItem>
                <ListItem disablePadding>
                  Note: No "ID" column will be there for Tweddle ERP sheet.
                </ListItem>

                {/* <ListItem disablePadding>
                  7) Copy & Paste all the value from Original Tweddle sheet to
                  generated Tweddle template
                  <br />
                  =CONCATENATE(DEC2HEX(RANDBETWEEN(0,65535),4),"-",DEC2HEX(RANDBETWEEN(16384,20479),4),"-",DEC2HEX(RANDBETWEEN(32768,49151),4),"-",DEC2HEX(RANDBETWEEN(0,65535),4))
                </ListItem> */}
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default Instructions;
