import React, { useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { manageOemIcon } from "../../assets/images";
import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";

import { OAuth as OAuthActions } from "../../js/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
}));

const ManageOem = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataSet, setDataSet] = React.useState([]);

  React.useEffect(async () => {
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     setMakeId(makeId.uid);
    //     console.log("make", makeID);
    //   }
    // });
    await dispatch(OAuthActions.getAllOems()).then(function (oemData) {
      console.log("getAllOems", oemData);
      let newDataSet = [];
      for (let index = 0; index < oemData.length; index++) {
        let date = new Date(oemData[index].addedOn);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        const element = {
          oemName: oemData[index].oemName,
          parentOemName:
            oemData[index].parentOemName == null
              ? "--"
              : oemData[index].parentOemName,
          addedOn: month + "/" + day + "/" + year,
        };
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  }, []);
  const columns = [
    {
      title: "OEM Name",
      field: "oemName",
      editable: "never",
    },

    {
      title: "Parent OEM Name",
      field: "parentOemName",
      editable: "never",
    },
    {
      title: "Added On (mm/dd/yyyy)",
      field: "addedOn",
      editable: "never",
    },
  ];

  const handleAddOem = (event) => {
    props.history.push(_appConstants.useCommonDefaults.navRef.oem.addOem);
  };
  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: null,
    onRowClick: null,
    searchState: false,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <img
                    src={manageOemIcon}
                    style={{ height: "2em", margin: "0 1em" }}
                  />
                  <Typography variant="h2">
                    <strong>Manage OEM:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddOem}
                  startIcon={<AddIcon />}
                >
                  Add OEM
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageOem;
