import React from "react";
// import { navProfile } from "../../assets/images/";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { Typography, Modal, Avatar, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import jwt from "jwt-decode";
import { useDispatch } from "react-redux";
import { setUserProfileDialog } from "../../js/reducers/userProfileDialog";
import { UserProfile as UserProfileActions } from "../../js/actions";
import { OAuth as OAuthActions } from "../../js/actions";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { setSnackbar } from "../../js/reducers/snackbar";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TokenStorage from "../../js/utils/tokenStorage";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    position: "relative",
    height: "6rem",
  },
  cardHeaderIcon: {
    width: "5rem",
    height: "5rem",
    background: "rgb(26,26,177)",
    background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",

    position: "absolute",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  card: {
    width: "50rem",
    backgroundColor: "#fff",
    padding: "0 1rem 1rem 1rem",
    margin: "1rem 4rem",
    boxShadow: theme.shadows[10],
  },
  parentDiv: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: "999",
  },
  buttonFooterContainer: {
    position: "relative",
    height: "3em",
  },
  buttonFooter: {
    borderRadius: 100,
    height: "5em",
    padding: "1em 4em",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%,50%)",
    boxShadow: theme.shadows[10],
    "&:hover": {
      color: "#0A65A8",
      backgroundColor: "#fff",
    },
  },
  avatarIcon: {
    width: "100%",
    height: "100%",
    padding: "1em",
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: 30,
    textTransform: "none",
  },
}));

const UserProfile = (props) => {
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openIdleDialog, setopenIdleDialog] = React.useState(false);
  const message =
    "Are you sure you want to revoke the privacy policy & terms and condition?You will be logged out of the application, and will not be able to use the application until they are accepted.";
  const userProfileDialogOpen = useSelector((state) => {
    if (open == false)
      if (state?.userProfileDialog?.userProfileOpen) {
        console.log(state?.userProfileDialog?.userProfileOpen);
        setOpen(state?.userProfileDialog?.userProfileOpen);
      }
    return state?.userProfileDialog?.userProfileOpen;
  });
  const uid = useSelector((state) => state.OAuth.loginUserData.uid || {});
  const token = useSelector((state) => {
    let user = "";
    if (state?.OAuth?.authData?.access_token !== undefined)
      user = jwt(state?.OAuth?.authData?.access_token) || "";
    return user;
  });

  // const loginUserDetails = useSelector((state) => {
  //   let authData = state?.OAuth?.authData?.user_name || "";
  //   if (authData === undefined || authData === "")
  //     return (
  //       state?.OAuth?.loginUserData?.firstName +
  //         " " +
  //         state?.OAuth?.loginUserData?.lastName || ""
  //     );
  //   else return authData;
  // });
  const loginUserDetails = useSelector((state) => {
    let authData = state?.OAuth?.authData?.user_name || "";
    if (
      state?.OAuth?.loginUserData?.firstName !== undefined ||
      state?.OAuth?.loginUserData?.firstName !== ""
    )
      return (
        state?.OAuth?.loginUserData?.firstName +
          " " +
          state?.OAuth?.loginUserData?.lastName || ""
      );
    else return authData;
  });

  const handleClose = () => {
    setOpen(false);
    console.log("setOpen");
    dispatch(setUserProfileDialog(false));
  };

  const logout = () => {
    handleClose();
    localStorage.removeItem("token_name");
    dispatch(UserProfileActions.clearLoginUserData()).then(function () {
      TokenStorage.clearAllCookies();
      history.push(_appConstants.useCommonDefaults.navRef.login);
    });
  };

  return (
    <Modal
      component="dialog"
      className={classes.parentDiv}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <Avatar className={classes.cardHeaderIcon}>
            <SupervisorAccountIcon className={classes.avatarIcon} />
          </Avatar>
        </div>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h3" align="center">
              <strong>User Name:</strong>
              {loginUserDetails.toUpperCase()}
            </Typography>
            <Typography variant="h5" align="center">
              <strong>Role:</strong>
              {token?.role?.replace("_", " ")}
            </Typography>
          </Grid>
          <hr />
          <Grid item>
            <Typography variant="h5">
              <strong>Authorized Modules:</strong>
              <br />
              {token?.authorities?.toString().replace(/ROLE|_/g, " ")}
            </Typography>
          </Grid>
          <Grid item>
            <hr />
          </Grid>
          <Grid item>
            <Typography variant="h5">
              <strong>Revoke Terms Of Use And Privacy Policy:</strong>
              <Button
                style={{ float: "right" }}
                variant="contained"
                size="small"
                onClick={() => {
                  setopenIdleDialog(true);
                }}
              >
                Revoke
              </Button>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">
              By revoking the terms of use and privacy policy you will no longer
              be able to explore and use the application.
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.buttonFooterContainer}>
          <Button
            variant="contained"
            className={classes.buttonFooter}
            alt="login_logo"
            component={Link}
            to="/login"
            onClick={logout}
            endIcon={<ExitToAppIcon />}
          >
            LOGOUT
          </Button>
        </div>
        <Dialog open={openIdleDialog} keepMounted>
          <DialogTitle>
            <Typography variant="subtitle1">
              Confirm Revoke Privileges:
            </Typography>
          </DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => {
                setopenIdleDialog(false);
              }}
              variant="contained"
            >
              No
            </Button>
            <Button
              className={classes.button}
              component={Link}
              to="/login"
              onClick={async () => {
                //
                handleClose();
                localStorage.removeItem("token_name");
                //
                setopenIdleDialog(false);
                await dispatch(
                  OAuthActions.updateUsersPolicy(uid, {
                    hasAcceptedTnc: false,
                    hasAcceptedPrivacyPolicy: false,
                  })
                ).then(function (userDetails) {
                  if (userDetails["error"] === undefined) {
                    dispatch(setSnackbar(true, "success", "Update Successful"));
                    handleClose();
                    localStorage.removeItem("token_name");
                    dispatch(UserProfileActions.clearLoginUserData()).then(
                      function () {
                        history.push(
                          _appConstants.useCommonDefaults.navRef.login
                        );
                      }
                    );
                  } else dispatch(setAlertDialog(true, null, "error", "Update User:", "Update unsuccessful.\nDetails Of Failure: " + userDetails.message, "Ok", ""));
                });
              }}
              variant="contained"
            >
              yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Modal>
  );
};

export default React.memo(UserProfile);
