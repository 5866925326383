import React from "react";
import { Typography, makeStyles, Grid, Hidden } from "@material-ui/core";

import { boschBanner } from "../../assets/images";

import { FooterTabs } from "../index";
const useStyles = makeStyles((theme) => ({
  // This group of buttons will be aligned to the right
  footer: {
    zIndex: "1",
    margin: "0",
    padding: "1vh",
    backgroundColor: "white",
    position: "fixed",
    bottom: "1.5vh",
    left: 0,
    width: "100%",
    alignItems: "center",
  },
  banner: {
    height: "1.5vh",
    bottom: 0,
    padding: 0,
    margin: 0,
    position: "fixed",
    left: 0,
    width: "100%",
  },
  robertbosch: {
    display: "flex",
    justifyContent: "flex-start",
  },
  rightlinks: {
    paddingRight: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  leftlinks: {
    cursor: "pointer",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    [`${theme.breakpoints.down("md")}`]: {
      paddingLeft: "0.2em",
    },
    [`${theme.breakpoints.down("xs")}`]: {
      paddingRight: "0.2em",
    },
  },
  removeSpacing: {
    padding: "0",
    margin: "0",
  },
}));

function Footer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  // const [year, setYear] = React.useState("2021");

  React.useEffect(() => {
    var d = new Date();
    // let tempYear = d.getFullYear();
    // setYear(tempYear.toString());
    return () => {};
  }, []);

  const handleClickOpen = (docType) => () => {
    console.log("docType", docType);
    const clickData = docType;
    setValue(clickData);
    setOpen(true);
  };

  const handleClose = () => {
    console.log("handleClose Parent");
    setOpen(false);
  };

  return (
    <>
      {/* <Hidden smDown={true}> */}
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-end"
        className={classes.footer}
      >
        <Grid item xs={12} md={10} lg={10} className={classes.robertbosch}>
          <Typography variant="h6" style={{ fontWeight: "900" }}>
            © {new Date().getFullYear()} Robert Bosch.IO GmbH
          </Typography>

          <Typography
            variant="caption"
            className={classes.leftlinks}
            onClick={handleClickOpen(0)}
          >
            Corporate Information
          </Typography>
          <Typography
            variant="caption"
            className={classes.leftlinks}
            onClick={handleClickOpen(1)}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="caption"
            className={classes.leftlinks}
            onClick={handleClickOpen(2)}
          >
            Legal Policy
          </Typography>

          <Typography
            variant="caption"
            className={classes.leftlinks}
            onClick={handleClickOpen(3)}
          >
            Terms and Conditions
          </Typography>

          <Typography
            variant="caption"
            className={classes.leftlinks}
            onClick={handleClickOpen(4)}
          >
            Copyright
          </Typography>
          <a data-show-privacy-settings href="">
            <Typography variant="caption" className={classes.leftlinks}>
              Privacy Settings
            </Typography>
          </a>
        </Grid>
        {/* <Hidden mdDown> */}
        <Grid item xs={12} md={2} lg={2}>
          <Typography
            color="secondary"
            variant="h3"
            className={classes.rightlinks}
          >
            Administrator
          </Typography>
        </Grid>
        {/* </Hidden> */}
        <img className={classes.banner} alt="banner" src={boschBanner} />
      </Grid>

      <FooterTabs clickValue={value} open={open} onClose={handleClose} />
      {/* </Hidden> */}
    </>
  );
}

export default Footer;
