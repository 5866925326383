import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { contentUploadIcon, contentUpload } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { setSnackbar } from "../../js/reducers/snackbar";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Context } from "../../js/utils/Context";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Receipt from "@material-ui/icons/Receipt";

import DoneAllSharpIcon from "@material-ui/icons/DoneAllSharp";
import FiberNewIcon from "@material-ui/icons/FiberNew";

import { CustomTable } from "../../components";

import * as _ from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  newTable: {
    borderBottom: "none",
    padding: "2px",
  },
  spanColor: {
    marginLeft: "10px",
    color: "gray",
  },
}));
const ViewInvoiceDetails = (props) => {
  // let makeID;
  let roleID;
  const history = useHistory();
  const dispatch = useDispatch();
  const [technicians, setTechnicians] = useState([]);
  const [technicianArray, setTechnicianArray] = useState([]);

  const [dataSet, setDataSet] = React.useState([]);
  const classes = useStyles();

  // const [details, setDetails] = useState({
  //   description: "",
  //   dueDate: "",
  //   partNumber: "NA1J 19G219 AA",
  //   shipmentNumber: "1413775",
  //   shippedDate: "10/6/2021",
  //   shippedQuantity: 1,
  //   status: "PAID",
  //   tweddleShipmentDetailsUid: "2c9085847f269e91017f26f623000001",
  // });

  const columns = [
    {
      title: "Previous Unit Price",
      field: "perviousBoschUnitPrice",
      editable: "never",
    },
    {
      title: "Current Unit Price",
      field: "currentBoschUnitPrice",
      editable: "never",
    },
    {
      title: "Quantity Paid By Ford",
      field: "quantityPaidByFord",
      editable: "never",
    },
    {
      title: "Amount Recieved",
      field: "amountReceived",
      editable: "never",
    },
    {
      title: "Amount Previusly Paid",
      field: "amountPreviouslyPaid",
      editable: "never",
    },
    {
      title: "Ford Remittance Trans Number",
      field: "fordRemittanceTransNumber",
      editable: "never",
    },
    {
      title: "Bank Clearance Date",
      field: "bankClearanceDate",
      editable: "never",
    },
    {
      title: "Balance",
      field: "balance",
      editable: "never",
    },
  ];
  console.log("props", props.location.rowdata);
  if (props && props.location && props.location.rowdata === undefined) {
    history.replace("/manage-invoice-data");
  }

  useEffect(async () => {
    if (props && props.location && props.location.rowdata !== undefined) {
      // setDetails(
      //   description:props.location.rowdata.description,
      //   dueDate:props.location.rowdata.dueDate,
      //   partNumber:props.location.rowdata.partNumber,
      //   shipmentNumber:props.location.rowdata.shipmentNumber,
      //   shippedDate:props.location.rowdata.shippedDate,
      //   shippedQuantity:props.location.rowdata.shippedQuantity,
      //   status:props.location.rowdata.status,
      //   tweddleShipmentDetailsUid:props.location.rowdata.tweddleShipmentDetailsUid
      // );
      await dispatch(
        OAuthActions.getInvoiceDetails(
          props.location.rowdata.tweddleShipmentDetailsUid,
          props.location.rowdata.partNumber
        )
      ).then(function (tweddleData) {
        let newDataSet = [];
        console.log(
          "tweddleData",
          tweddleData?._embedded?.tweddleTransectionDetailsDtoes
        );
        console.log("RefreshDataSet");
        for (
          let index = 0;
          index < tweddleData?._embedded?.tweddleTransectionDetailsDtoes.length;
          index++
        ) {
          const element = {
            amountPreviouslyPaid:
              "$ " +
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .amountPreviouslyPaid, //
            amountReceived:
              "$ " +
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .amountReceived, //
            balance:
              "$ " +
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .balance, //
            bankClearanceDate:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .bankClearanceDate == ""
                ? "--"
                : new Date(
                    tweddleData?._embedded?.tweddleTransectionDetailsDtoes[
                      index
                    ].bankClearanceDate
                  ).toLocaleDateString("en-US"), //
            currentBoschUnitPrice:
              "$ " +
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .currentBoschUnitPrice, //
            description:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .description, //
            fordRemittanceTransNumber:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .fordRemittanceTransNumber == ""
                ? "--"
                : tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                    .fordRemittanceTransNumber, //
            partNumber:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .partNumber, //
            perviousBoschUnitPrice:
              "$ " +
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .perviousBoschUnitPrice, //
            quantityPaidByFord:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .quantityPaidByFord,
            shipmentNumber:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .shipmentNumber, //
            shippedDate: new Date(
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[
                index
              ].shippedDate
            ).toLocaleDateString("en-US"), //
            shippedQuantity:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .shippedQuantity, //
            uid: tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
              .uid,
            boschInvoiceNumber:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .boschInvoiceNumber,
            fordPaymentNumber:
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[index]
                .fordPaymentNumber,
            dueDate: new Date(
              tweddleData?._embedded?.tweddleTransectionDetailsDtoes[
                index
              ].dueDate
            ).toLocaleDateString("en-US"),
          };
          console.log("tweddleData element index", index, element);
          newDataSet.push(element);
        }
        setDataSet(newDataSet);
      });
    }
  }, []);

  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    tableLayout: "auto",
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <div style={{ display: "flex" }}>
                  <img
                    src={contentUploadIcon}
                    style={{ height: "2em", margin: "0 1em" }}
                  />
                  <Typography variant="h2">
                    <strong>Invoice Details:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item spacing={2}>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<ArrowLeft />}
                      onClick={history.goBack}
                    >
                      Back
                    </Button>
                  </Grid>
                  {/* <Grid item>
                    <Button variant="contained" startIcon={<CheckIcon />}>
                      Invoice Sent
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<Receipt />}
                      onClick={history.goBack}
                    >
                      Generate invoice
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <hr className={classes.border} color="whitesmoke" />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={2}>
            <img
              src={contentUpload}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <h1 style={{ color: "#005691", fontSize: "15px" }}>
              Shipment Details
            </h1>
            <br />
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Shipment Number
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {props.location.rowdata.shipmentNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Total Quantity
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {props.location.rowdata.shippedQuantity}
                    </TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Shipped Date
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {props.location.rowdata.shippedDate
                        ? moment(props.location.rowdata.shippedDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow key="4">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Description
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {props.location.rowdata.description}
                    </TableCell>
                  </TableRow>
                  <TableRow key="4">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Part Number
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {props.location.rowdata.partNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={2} slyle={{ display: "flex", float: "right" }}>
            <Grid item gutterBottom>
              <h1 style={{ color: "#005691", fontSize: "15px" }}>
                Invoice Status
              </h1>
            </Grid>
            {props.location.rowdata.status === "OVERDUE" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  borderRadius: "50%",
                  width: "8em",
                  height: "8em",
                  backgroundColor: "#ffcdd2",
                  padding: "1em",
                  color: "#c62828",
                }}
              >
                {/* <div style={{ position: "absolute", top: "3em", left: "25%" }}> */}
                <Grid item>
                  <CloseIcon fontSize="large" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="justify">
                    <b>Overdue</b>
                  </Typography>
                </Grid>
                {/* </div> */}
              </Grid>
            )}
            {props.location.rowdata.status === "PAID" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  borderRadius: "50%",
                  width: "8em",
                  height: "8em",
                  backgroundColor: "#bff6eb",
                  padding: "1em",
                  color: "#16A085",
                }}
              >
                {/* <div style={{ position: "absolute", top: "3em", left: "25%" }}> */}
                <Grid item>
                  <CheckIcon fontSize="large" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="justify">
                    <b>Paid</b>
                  </Typography>
                </Grid>
                {/* </div> */}
              </Grid>
            )}
            {props.location.rowdata.status === "SENT" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  borderRadius: "50%",
                  width: "8em",
                  height: "8em",
                  backgroundColor: "#fbdaa7",
                  padding: "1em",
                  color: "#F39C12",
                }}
              >
                {/* <div style={{ position: "absolute", top: "3em", left: "25%" }}> */}
                <Grid item>
                  <CheckIcon fontSize="large" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="justify">
                    <b>Sent</b>
                  </Typography>
                </Grid>
                {/* </div> */}
              </Grid>
            )}
            {props.location.rowdata.status === "NEW" && (
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  borderRadius: "50%",
                  width: "8em",
                  height: "8em",
                  backgroundColor: "#B3E5FC",
                  padding: "1em",
                  color: "#01579B",
                }}
              >
                {/* <div style={{ position: "absolute", top: "3em", left: "25%" }}> */}
                <Grid item>
                  <FiberNewIcon fontSize="large" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="justify">
                    <b>New</b>
                  </Typography>
                </Grid>
                {/* </div> */}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          direction="row"
          container
          style={{ backgroundColor: "whitesmoke", marginTop: "4px" }}
        >
          <h4 style={{ color: "#005691" }}>Shipment Details</h4>
        </Grid>

        <Grid container>
          <Grid item sm={12}>
            <CustomTable {...customTableData} style={{ height: "40vh" }} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: "12px" }}></Grid>
      </div>
    </>
  );
};
export default ViewInvoiceDetails;
