import { makeStyles } from "@material-ui/core/styles";
const ListStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
  item: {
    fontWeight: "normal",
    borderBottom: "1px solid #102E3533",
    color: "white",
    padding: "1vh 1vw",
    "&:hover": {
      borderLeft: "0.5em solid #005691",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    minWidth: "fit-content",
    margin: "1vh 1vw",
  },
  profileListItem: {
    display: "inline-block",
  },
  itemTextWrap: {
    // fontSize: "0.5em !important",
    fontSize: "2vh !important",
    whiteSpace: "initial",
    lineHeight: "1rem",
  },
  iconImage:{
    height: "2vh",
    width: "2vw"
  },
  itemToggleIcon: {
    borderWidth: "0 2px 2px 0",
    padding: "1.5vh 1vw",
    position: "absolute",
    right: "20px",
    border: "solid" + theme.palette.menuColor,
    minWidth: "fit-content !important",
    transform: "rotate(-45deg)",
    "-webkitTransform": "rotate(-45deg)",
    "&.expand": {
      borderColor: theme.palette.primary,
      transform: "rotate(45deg)",
      "-webkitTransform": "rotate(45deg)",
      top: "25px",
    },
    "&.visibleHidden": {
      visibility: "hidden",
    },
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: "#005691",
  },
  ProfileSelected: {
    fontWeight: "bold",
    width: "5em",
    backgroundColor: "#005691",
  },

  profileStyle: {
    position: "fixed",
    bottom: 0,
    width: "auto",
  },
}));

export default ListStyles;
