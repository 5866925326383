import React, { Component } from "react";
import { pdfjs, Document, Page } from "react-pdf";
const pdfjsVersion = "2.0.305";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import ReactGA from "react-ga";

ReactGA.initialize("GTM-5RK9R65");
//implementation to show the pdf file
export default class FileViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  componentDidMount() {
    console.log("this.props.docType", this.props.docType);
    if (this.props.docType === "policyData") {
      ReactGA.event({
        category: "Privacy Policy",
        action: "Viewing Privacy Policy",
      });
    } else {
      ReactGA.event({
        category: "Terms and conditions",
        action: "Viewing Terms and conditions",
      });
    }
  }
  render() {
    return (
      <div>
        <Document
          file={this.props.pdfFile}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          {Array.apply(null, Array(this.state.numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} size="A4" key={page} />
            ))}
        </Document>
      </div>
    );
  }
}
