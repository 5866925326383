import React, { useState, useEffect } from "react";
import { omLogo } from "../../assets/images/";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/styles/styles";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { OAuth as OAuthActions } from "../../js/actions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TokenStorage from "../../js/utils/tokenStorage";
// var qs = require("qs");

const PasskeySet = ({ registrationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [secretAnswer, setSecretAnswer] = useState("");
  const [secretAnswerHelper, setSecretAnswerHelper] = useState("");
  const [questions, setQuestions] = useState([]);
  const [secretQuestion, setSecretQuestion] = useState("");
  const [secretQuestionHelper, setSecretQuestionHelper] = useState("");

  useEffect(() => {
    // if (registrationId == null)
    if (registrationId === "") {
      // history.push("/login");
      console.log("PasskeySet registrationId null", registrationId);
    } else {
      console.log("PasskeySet registrationId", registrationId);
    }
  });

  useEffect(async () => {
    await dispatch(OAuthActions.getAllSecretQuestions()).then(function (
      response
    ) {
      console.log("response", response);
      setQuestions(response.secretQuestions);
      setSecretQuestionHelper("Please select your secret question");
    });

    return () => {
      console.log("I AM HERE!!!");
    };
  }, []);

  const handleChange = (event, type) => {
    if (event.target.id === "secret-answer") {
      setSecretAnswer(event.target.value);

      console.log("event", event.target.id, event.target.value, type);
    } else {
      setSecretQuestionHelper("");
      setSecretQuestion(event.target.value);
      console.log("event value", event.target.value);
      console.log("event", event.target.id, event.target.value, type);
    }
  };

  const submit = () => {
    console.log("Hello");
    // if (secretAnswer.length && secretAnswerHelper.length === 0) {
    console.log("HWEW", registrationId, secretQuestion, secretAnswer);

    dispatch(
      OAuthActions.patchSecretanswer(
        registrationId,
        secretQuestion,
        secretAnswer
      )
    ).then(function (success) {
      if (success["error"] === undefined) {
        history.push("/register/" + registrationId);
        dispatch(
          setSnackbar(
            true,
            "success",
            "Step 1: Successful. kindly continue with creating/resetting your new password"
          )
        );
        // dispatch(
        //   setAlertDialog(
        //     true,
        //     null,
        //     "success",
        //     "New Password:",
        //     "New Password set successfully. Please request the administrator to activate your account. ",
        //     "Ok",
        //     ""
        //   )
        // );
      } else {
        if (success.error == "E0033") {
          history.goBack();
        }
        dispatch(
          setAlertDialog(
            true,
            null,
            "error",
            "Passkey Settings:",
            "New passkey set is unsuccessful.\nDetails Of Failure: " +
              success.message,
            "Ok",
            ""
          )
        );
      }
    });
    // dispatch(OAuthActions.patchSecretanswer(registrationId, question, answer)).then(function (
    //   loginSuccess
    // ) {
    //   if (loginSuccess["error"] === undefined) {
    //     history.push(_appConstants.useCommonDefaults.navRef.dashboard);
    //     //
    //     dispatch(setSnackbar(true, "success", "Login Successful"));
    //   } else {
    //     dispatch(
    //       setAlertDialog(
    //         true,
    //         null,
    //         "error",
    //         "Login:",
    //         "Login unsuccessful. Please check your username and password. If account locked, please click on the forgot password link to reset your password.\nDetails Of Failure: " +
    //           loginSuccess.error_description,
    //         "Ok",
    //         ""
    //       )
    //     );
    //   }
    // });
    // } else {
    //   if (secretAnswer.length === 0) {
    //     setSecretAnswerHelper("Please enter an answer for the set question");
    //   }
    // }
  };

  return (
    <div className={classes.parentDiv}>
      <div className={classes.loginCard}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className={classes.cardHeader}>
              <img
                src={omLogo}
                alt="login_logo"
                className={classes.omLogoCard}
              />
            </div>
          </Grid>
          <Grid item>
            <Typography
              style={{ color: "#005691" }}
              variant="h3"
              align="center"
            >
              Forgot Password
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              Please select a secret question from the dropdown and please give
              the appropriate answer .
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              select
              required
              id="secret-question"
              label="Secret Question:"
              value={secretQuestion}
              onChange={(event) => handleChange(event, "question")}
              helperText={secretQuestionHelper}
            >
              {questions.map((option) => (
                <option
                  style={{ cursor: "context-menu" }}
                  key={option.uid}
                  value={option.uid}
                >
                  {option.question}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              className={classes.textfieldHeader}
              error={secretAnswerHelper.length !== 0}
              helperText={secretAnswerHelper}
              onChange={(event) => handleChange(event, "answer")}
              id="secret-answer"
              label="Answer:"
              placeholder="Enter your secret answer"
              value={secretAnswer}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3}>
              <Grid item xs>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  fullWidth
                  size="large"
                  id="loginClick"
                  variant="contained"
                  onClick={submit}
                  disabled={
                    secretAnswer.length === 0 ||
                    secretAnswerHelper.length !== 0 ||
                    secretQuestionHelper.length !== 0
                  }
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(PasskeySet);
