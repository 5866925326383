export const SET_ALERT_DIALOG = "SET_ALERT_DIALOG";

const initialState = {
  alertDialogOpen: false,
  alertDialogClickStatus: null,
  alertDialogType: "success",
  alertDialogTitle: "Header",
  alertDialogMessage: "",
  alertDialogOkText: "",
  alertDialogCancelText: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_DIALOG:
      const {
        alertDialogOpen,
        alertDialogClickStatus,
        alertDialogType,
        alertDialogTitle,
        alertDialogMessage,
        alertDialogOkText,
        alertDialogCancelText,
      } = action;
      return {
        ...state,
        alertDialogOpen,
        alertDialogClickStatus,
        alertDialogType,
        alertDialogTitle,
        alertDialogMessage,
        alertDialogOkText,
        alertDialogCancelText,
      };
    default:
      return state;
  }
};

export const setAlertDialog = (
  alertDialogOpen = false,
  alertDialogClickStatus = null,
  alertDialogType = "",
  alertDialogTitle = "",
  alertDialogMessage = "",
  alertDialogOkText = "",
  alertDialogCancelText = ""
) => ({
  type: SET_ALERT_DIALOG,
  alertDialogOpen,
  alertDialogClickStatus,
  alertDialogType,
  alertDialogTitle,
  alertDialogMessage,
  alertDialogOkText,
  alertDialogCancelText,
});
