import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Slide,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import { setAlertDialog } from "../../js/reducers/alertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "1vh",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  warning: {
    color: "#f1c40f",
    marginRight: "0.5em",
  },
  success: { color: "#1abc9c", marginRight: "0.5em" },
  error: { color: "#e74c3c", marginRight: "0.5em" },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  useSelector((state) => {
    if (open == false)
      if (state?.alertDialog?.alertDialogOpen) {
        console.log(state?.alertDialog?.alertDialogOpen);
        setOpen(state?.alertDialog?.alertDialogOpen);
      }
  });
  const dialogType = useSelector(
    (state) => state?.alertDialog?.alertDialogType
  );
  const dialogTitle = useSelector(
    (state) => state?.alertDialog?.alertDialogTitle
  );
  const dialogMessage = useSelector(
    (state) => state?.alertDialog?.alertDialogMessage
  );
  const dialogOkText = useSelector(
    (state) => state?.alertDialog?.alertDialogOkText
  );
  const dialogCancelText = useSelector(
    (state) => state?.alertDialog?.alertDialogCancelText
  );

  // const dialogMessage = useSelector(
  //   (state) => state?.alertDialog?.alertDialogMessage
  // );
  const handleCancelClose = (event, reason) => {
    setOpen(false);
    dispatch(setAlertDialog(false, false));
  };
  const handleOkClose = (event, reason) => {
    setOpen(false);
    dispatch(setAlertDialog(false, true));
  };
  const iconToBeUsed = () => {
    if (dialogType === "success") {
      return <CheckCircleIcon fontSize="large" className={classes.success} />;
    } else if (dialogType === "error") {
      return <ErrorIcon fontSize="large" className={classes.error} />;
    } else if (dialogType === "warning")
      return <WarningIcon fontSize="large" className={classes.warning} />;
    else return <></>;
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullWidth={false}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {iconToBeUsed()}
            <Typography variant="h3">
              <strong>{dialogTitle}</strong>
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage.split("\n").map((item, key) => {
              if (key === 0)
                return <React.Fragment key={key}>{item}</React.Fragment>;
              else
                return (
                  <React.Fragment key={key}>
                    <br />
                    <br />
                    {item}
                  </React.Fragment>
                );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogCancelText.length > 0 ? (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCancelClose}
              color="primary"
            >
              {dialogCancelText}
            </Button>
          ) : (
            <></>
          )}
          {dialogOkText.length > 0 ? (
            <Button
              fullWidth
              variant="contained"
              onClick={handleOkClose}
              color="primary"
              autoFocus
            >
              {dialogOkText}
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
