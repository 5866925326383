import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { roleIcon, manageOemIcon, addOemIcon } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import AttachmentIcon from "@material-ui/icons/Attachment";

import { OAuth as OAuthActions } from "../../js/actions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  dropWidth: {
    width: "75%",
  },
}));
const AddOem = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bindScreen = (e) => {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };
  const [oemHelper, setOemHelper] = useState("");
  const [oemName, setOemName] = useState("");
  const [parentOemName, setParentOemName] = useState("");
  const [parentOems, setParentOems] = useState([]);
  const [file, setFile] = useState(null);
  const [localPath, setLocalPath] = useState("");

  const classes = useStyles();
  React.useEffect(async () => {
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     setMakeId(makeId.uid);
    //     console.log("make", makeID);
    //   }
    // });
    await dispatch(OAuthActions.getParentOems()).then(function (data) {
      console.log(data);
      if (data) {
        setParentOems(data);
      }
    });
  }, []);
  const handleParentOem = async (event) => {
    console.log("handleParentOeem event", event);

    // const ParentOemData = parentOems.filter(function (data) {
    //   console.log("ParentOemData", data, event.target.value);
    //   return data.name == event.target.value;
    // });
    // console.log("parentOems", parentOems);
    // console.log("selection:", event, event.target.value);
    // console.log("Filter", ParentOemData[0]);
    if (event.uid == null) setParentOemName("");
    else setParentOemName(event.uid);

    // await setParentOemName(event.target.value);
    // if (event.target.value == null) setParentOemName("");
    // else setParentOemName(event.target.value);
  };
  const onFileChange = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      var pattern = /image\/png/;

      if (!file.type.match(pattern)) {
        dispatch(
          setSnackbar(
            true,
            "error",
            "Please provide the company image, which should be in an image file format - png."
          )
        );
        return;
      }
      setFile(file);
      setLocalPath(
        file.name + "(" + (file.size / (1024 * 1024)).toFixed(2) + " MB )"
      );
      console.log(file);
    }
    const file = event.target.files[0];
    if (file !== undefined) {
    }
  };
  const oemNameHandler = (e) => {
    setOemName(e.target.value);
    const valid = /^[a-zA-Z0-9_\s]+$/.test(e.target.value);
    if (!valid && e.target.value.length !== 0) {
      setOemHelper("Name contains only Alphabets");
    } else {
      setOemHelper("");
    }
  };
  const submit = () => {
    console.log("oemName, parentOemName", oemName, parentOemName);
    let formData = new FormData();
    formData.append("file", file);
    formData.append(
      "makeDto",
      JSON.stringify({ uid: parentOemName, name: oemName })
    );
    window.addEventListener("beforeunload", bindScreen, true);
    dispatch(OAuthActions.uploadOemDetails(formData)).then(function (success) {
      debugger;
      window.removeEventListener("beforeunload", bindScreen, true);
      if (success["error"] === undefined) {
        dispatch(setSnackbar(true, "success", "OEM added successfully"));
        history.push("/dashboard");
      } else {
        dispatch(
          setAlertDialog(
            true,
            null,
            "error",
            "Document Upload:",
            "Document upload was unsuccessful. Please check your network connectivity, and try again.\nDetails Of Failure: " +
              success.message,
            "Ok",
            ""
          )
        );
      }
    });
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={manageOemIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Add OEM:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img src={addOemIcon} style={{ height: "14vh", margin: "1em" }} />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={oemHelper.length !== 0}
                  helperText={oemHelper}
                  label="OEM Name"
                  value={oemName}
                  onChange={oemNameHandler}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <Autocomplete
                  options={parentOems}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // className={classes.dropWidth}
                      label="Parent OEM Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, value) => handleParentOem(value)}
                />
              </Grid>
              <Grid item xs={9} sm={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <input
                      accept="image/png"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      type="file"
                      onChange={onFileChange}
                    />
                    <TextField
                      fullWidth
                      required
                      id="filled-required"
                      label="OEM Logo"
                      value={localPath}
                      InputProps={{
                        endAdornment: (
                          <>
                            <label htmlFor="contained-button-file">
                              <Button
                                size="large"
                                variant="contained"
                                component="span"
                                startIcon={<AttachmentIcon />}
                              >
                                Browse
                              </Button>
                            </label>
                          </>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={oemName == ""}
                      onClick={submit}
                    >
                      Create
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AddOem;
