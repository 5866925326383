import { makeStyles } from "@material-ui/core/styles";

const PagesNavigationStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    padding: theme.spacing(3),
    //margin: '-'+theme.spacing(3)+'px '
    marginTop: "-" + theme.spacing(3) + "px",
    marginRight: "-" + theme.spacing(3) + "px",
    marginBottom: theme.spacing(3) + "px",
    marginLeft: "-" + theme.spacing(3) + "px",
  },
  backspaceIcon: {
    verticalAlign: "bottom",
    marginRight: theme.spacing(2),
    cursor: "pointer",
    color: theme.palette.menuColor,
  },
  backTitle: {
    color: theme.palette.menuColor,
    fontSize: "0.5rem !important",
    fontWeight: 400,
    lineHeight: 1,
    "&.primary": {
      color: theme.palette.primaryColor,
    },
    "&.subHeader": {
      fontWeight: 600,
    },
  },
  bCrumbsRight: {
    float: "right",
  },
}));

export default PagesNavigationStyles;
