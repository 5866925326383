/* global _appConstants */
import React, { useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import jwt from "jwt-decode";
import { ListMenuNav } from "./components";
import { OAuth as OAuthActions } from "../../../../js/actions";
import TokenStorage from "../../../../js/utils/tokenStorage";
import { useHistory } from "react-router-dom";

const drawerWidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  menuButton: {
    marginRight: theme.spacing(3) + 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    background: "#293036",
    width: drawerWidth,
    whiteSpace: "nowrap",
    height: "100%",
    position: "relative",
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "6px 6px 6px #0000000F",
    backgroundColor: theme.palette.drawerColor,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
    boxShadow: "6px 6px 6px #0000000F",
  },
}));

const prepareFeatureBasedMenu = (userRole, menuList) => {
  const token = TokenStorage.getToken();
  const tokenDetails = jwt(token);
  const history = useHistory();
  let sidebarOptions = [];

  var a = _appConstants.menuOptions.forEach((element, index) => {
    if (index == 0) {
      sidebarOptions.push(element);
    } else if (tokenDetails.authorities.includes(element.role)) {
      sidebarOptions.push(element);
    }
  });

  return sidebarOptions;
};
// eslint-disable-next-line react/no-multi-comp
const Sidebar = (props) => {
  const { open, ...rest } = props;
  const classes = useStyles();
  const sidebarRef = createRef(); //useRef('sidebar_drawer');
  const userRole = useSelector(
    (state) => state ?.OAuth ?.authData ?.role || "N/A"
  );
  const dispatch = useDispatch();
  // const userFeatures = useSelector(
  //   (state) => state?.UserProfile?.features || {}
  // );
  useEffect(async () => {
    let activeIndex = _appConstants.menuOptions.findIndex(
      (item) => window.location.href.indexOf(item.href) > 0
    );
    const token = TokenStorage.getToken();
    const tokenDetails = jwt(token);
    let sidebarOptions = [];
    _appConstants.menuOptions.forEach((element, index) => {
      if (tokenDetails.authorities.includes(element.role)) {
        sidebarOptions.push(element);
      }
    });
    if (sidebarOptions.length === 2) {
      activeIndex = activeIndex === 7 ? 1 : (activeIndex === 8 ? 2 : activeIndex);
    }
    if (activeIndex > -1) dispatch(OAuthActions.ClickHandler(activeIndex));
  }, []);

  return (
    <>
      <Drawer
        ref={sidebarRef}
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        open
        variant="persistent"
      >
        <ListMenuNav
          {...rest}
          isOpen={open}
          className={clsx(classes.drawer)}
          pages={prepareFeatureBasedMenu(userRole, _appConstants.menuOptions)}
        />
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

export default React.memo(Sidebar);
