import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { inspectionJobReportIcon } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import * as _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import PageviewIcon from "@material-ui/icons/Pageview";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import { CustomTable } from "../../components";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "none",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  IconDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "transparent",
    cursor: "default",
    pointerEvents: "none",
  },
  editRow: {
    backgroundColor: "#01579B",
    color: "white !important",
  },
  new: {
    backgroundColor: "#2ecc71",
    padding: "0.75em",
    color: "#16a085",
  },
  inprogress: {
    backgroundColor: "#249BBE",
    padding: "0.75em",
    color: "#fff",
  },
  completed: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#fff",
  },
  cancelled: {
    backgroundColor: "#000000",
    padding: "0.75em",
    color: "#fff",
  },
  MTablePaginationInner: {
    MuiButtonBase: {
      backgroundColor: "red",
    },
  },
  downloadDropdown: {
    position: "absolute",
    marginRight: "80px",
    marginTop: "64px",
    background: "whitesmoke",
  },
  dowloadOption: {
    display: "block",
    background: "whitesmoke",
    width: "150px",
    border: "none",
    textAlign: "left",
    marginLeft: "18px",
    padding: "2px",
  },
}));
const InspectionReport = (props) => {
  // let makeID;
  // const [makId, setMakId] = useState("");
  const [makeSelected, setMakeSelected] = useContext(Context);
  const dispatch = useDispatch();
  const [model, setModel] = useState("");
  const [market, setMarket] = useState("");
  const [year, setYear] = useState("");
  const [plant, setPlant] = useState("");
  const [showExport, setShowExport] = useState(false);
  const classes = useStyles();
  const [state, setState] = useState({
    dataSet: [],
    showLoading: false,
    columns: [
      // {
      //     title: "Job ID",
      //     field: "jobId",
      //     editable: "never",
      //     editComponent: (rowData) => (
      //         <TextField
      //             variant="outlined"
      //             defaultValue={rowData.rowData.jobId}
      //             size="small"
      //         />
      //     ),
      // },
      {
        title: "Technician",
        field: "technician",
        render: (rowData) => (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Avatar maxinitials={1} size={40}></Avatar>
            </Grid>
            <Grid item>{rowData.techName}</Grid>
          </Grid>
        ),
        editComponent: (rowData) => (
          <TextField
            variant="outlined"
            defaultValue={rowData.rowData.techName}
            onChange={(e) => {
              rowData.onChange(e.target.value);
            }}
            size="small"
            autoFocus
          />
        ),
      },

      {
        title: "Inspection Date",
        field: "inspectionDate",
        editable: "never",
        editComponent: (rowData) => (
          <TextField
            variant="outlined"
            defaultValue={moment(rowData.rowData.inspectionDate).format(
              "DD/MM/YYYY"
            )}
            size="small"
          />
        ),
      },
      {
        title: "Year",
        field: "year",
        editable: "never",
        editComponent: (rowData) => (
          <TextField
            variant="outlined"
            defaultValue={rowData.rowData.year}
            size="small"
          />
        ),
      },
      {
        title: "Model",
        field: "modelName",
        editable: "never",
        editComponent: (rowData) => (
          <TextField
            variant="outlined"
            defaultValue={rowData.rowData.modelName}
            size="small"
          />
        ),
      },
      {
        title: "Market",
        field: "market",
        editable: "never",
        editComponent: (rowData) => (
          <TextField
            id="GRD_edit-country"
            variant="outlined"
            defaultValue={rowData.rowData.market}
            size="small"
          />
        ),
      },
      {
        title: "Plant Details",
        field: "plantDetails",
        editable: "never",
        editComponent: (rowData) => (
          <TextField
            variant="outlined"
            defaultValue={rowData.rowData.plantDetails}
            size="small"
          />
        ),
      },
      {
        title: "Status",
        field: "status",
        lookup: {
          Completed: (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.completed}
            >
              <Grid item>
                <DoneAllOutlinedIcon />
              </Grid>
              <Grid item>Completed</Grid>
            </Grid>
          ),
          New: (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.new}
            >
              <Grid item>
                <DescriptionOutlinedIcon />
              </Grid>
              <Grid item>New</Grid>
            </Grid>
          ),
          Inprogress: (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.inprogress}
            >
              <Grid item>
                <HourglassEmptyOutlinedIcon />
              </Grid>
              <Grid item>Inprogress</Grid>
            </Grid>
          ),
          Cancelled: (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.cancelled}
            >
              <Grid item>
                <HighlightOffOutlinedIcon />
              </Grid>
              <Grid item>Cancelled</Grid>
            </Grid>
          ),
        },

        editComponent: (rowData) => (
          <Select
            defaultValue={rowData.rowData.status}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={1}>Present</MenuItem>
            <MenuItem value={0}>Absent</MenuItem>
          </Select>
        ),
      },
    ],
    tableIcons: {},
    action: [
      {
        icon: () => <DescriptionOutlinedIcon style={{ color: "#005691" }} />,
        disable: false,
        onClick: async (event, rowData) => {
          console.log(rowData);
          // props.history.push({
          //     pathname: _appConstants.useCommonDefaults.navRef.inspection,
          //     rowdata: rowData,
          // });
        },
      },
    ],
  });
  useEffect(async () => {
    let makeID = "";
    // await dispatch(OAuthActions.getMakeId()).then(function (makeSelected.uid) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     // setMakId(makeId.uid);
    //   }
    // });
    await dispatch(OAuthActions.getReportsData(makeSelected.uid)).then(
      function (data) {
        if (data) {
          const totalData = data._embedded.jobBasicDetailsDtoes;
          totalData.find((d) => {
            let name = "";
            const length = d.technicianList.length;
            d.technicianList.map((data, i) => {
              if (length !== i + 1) {
                name = `${name} ${data.firstName} ${data.lastName}, `;
              } else {
                name = `${name} ${data.firstName} ${data.lastName}`;
              }
            });
            d.techName = name;
            d.inspectionDate = moment(d.inspectionDate).format("DD/MM/YYYY");
            d.makeId = makeSelected.uid;
          });
          setState((prevState) => {
            return { ...prevState, dataSet: totalData };
          });
        }
      }
    );
    setModel("");
    setYear("");
    setMarket("");
    setPlant("");
  }, [makeSelected]);
  const convertTOCSV = (data, fileName) => {
    const items = data;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    const csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    const rowData = csv.join("\r\n");
    downloadFile(rowData, fileName);
  };
  const downloadFile = (data, fileName) => {
    const parsedResponse = data;
    const blob = new Blob(["\ufeff", parsedResponse], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, `${new Date().getTime()}.xls`);
    } else {
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xls`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const customTableData = {
    isReadOnly: true,
    isOnclick: false,
    dataSet: state.dataSet,
    columns: state.columns,
    exportOption: false,
    actions: state.action,
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={inspectionJobReportIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Inspection Job Report</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={1}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <Grid item xs={12} sm={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              justify="space-around"
              spacing={1}
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label="Model"
                  id="model-select"
                  value={model}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label="Market"
                  id="market-select"
                  value={market}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label="Year of manufacturing"
                  id="year-select"
                  value={year}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label="Plant"
                  id="plant-select"
                  value={plant}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField fullWidth select label="Status" id="status-select">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  select
                  label="Technician"
                  id="technician-select"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField type="number" fullWidth id="jobid" label="Job ID" />
              </Grid>
              <Grid item xs={12} sm={3} style={{ bottom: "0" }}>
                <IconButton
                  variant="contained"
                  // onClick={() => {
                  // alert("Filter Search");
                  // const data = _.cloneDeep(state.dataSet);
                  // if (data && data.length) {
                  //     let Filename = 'Inspection_Reports';
                  //     Filename = `${Filename}_${moment().format('Do_MMMM_YYYY_h:mm:ss_a')}`;
                  //     let downloadData = [];
                  //     downloadData = data.map(key => ({
                  //         JOB_ID: key.jobId,
                  //         TECHNICIAN: key.techName,
                  //         INSPECTIon_DATE: moment(key.inspectionDate).format('DD/MM/YYYY'),
                  //         YEAR: key.year,
                  //         MODEL: key.modelName,
                  //         MARKET: key.market,
                  //         PLANT_DETAILS: key.plantDetails,
                  //         STATUS: key.status
                  //     }));
                  //     convertTOCSV(downloadData, Filename);
                  // }
                  // }}
                >
                  <PageviewIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid direction="row" container style={{ marginTop: "12px" }}>
          <Grid item xs={12} sm={11}></Grid>
          <Grid item xs={12} sm={1}>
            <Button
              // size="large"
              variant="outlined"
              style={{ color: "gray", borderColor: "gray", width: "90px" }}
              onClick={() => {
                setShowExport(!showExport);
              }}
              // disabled={!technicianArray.length}
            >
              <CloudDownloadOutlinedIcon style={{ color: "gray" }} />
              <span
                style={{ color: "gray", marginLeft: "5px", fontWeight: "100" }}
              >
                Export
              </span>
              {showExport ? (
                <div className={classes.downloadDropdown}>
                  <button
                    className={classes.dowloadOption}
                    onClick={() => {
                      const data = _.cloneDeep(state.dataSet);
                      if (data && data.length) {
                        let Filename = "Inspection_Reports";
                        Filename = `${Filename}_${moment().format(
                          "Do_MMMM_YYYY_h:mm:ss_a"
                        )}`;
                        let downloadData = [];
                        downloadData = data.map((key) => ({
                          JOB_ID: key.jobId,
                          TECHNICIAN: key.techName,
                          INSPECTIon_DATE: moment(key.inspectionDate).format(
                            "DD/MM/YYYY"
                          ),
                          YEAR: key.year,
                          MODEL: key.modelName,
                          MARKET: key.market,
                          PLANT_DETAILS: key.plantDetails,
                          STATUS: key.status,
                        }));
                        convertTOCSV(downloadData, Filename);
                      }
                    }}
                  >
                    Download as Excel
                  </button>
                  <button
                    className={classes.dowloadOption}
                    // onClick={() => {
                    //   alert("Dowload PDF");
                    // }}
                  >
                    Download as Pdf
                  </button>
                </div>
              ) : null}
            </Button>
            {/* {
                            showExport ? <div className={classes.downloadDropdown}>
                                <button className={classes.dowloadOption}
                                    onClick={alert('Hi')}
                                >Download as Excel</button>
                                <button className={classes.dowloadOption}>Download as Pdf</button>
                            </div> : null
                        } */}
          </Grid>
        </Grid>
        <CustomTable {...customTableData} />
      </div>
    </>
  );
};
export default InspectionReport;
