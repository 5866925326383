import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";

ReactGA.initialize("GTM-5RK9R65");

function CorporateInformation() {
  useEffect(() => {
    ReactGA.event({
      category: "Corporate Information/Imprint",
      action: "Viewing Corporate Information/Imprint",
    });
    return () => {};
  }, []);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        <strong>Corporate Information/Imprint:</strong>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <strong>Name and address</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Bosch Automotive Service Solutions Inc.
      </Typography>
      <Typography variant="body1" gutterBottom>
        28635 Mound Road
      </Typography>
      <Typography variant="body1" gutterBottom>
        Warren, MI 48092
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Authorized Representative:</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Director Engineering Diagnostic Service OEM
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Your contact at Bosch</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        <a href="https://www.bosch.us/contact/">Contact | Bosch in the USA</a>
      </Typography>
    </>
  );
}

export default CorporateInformation;
