import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";

// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
// import { setSnackbar } from "../../js/reducers/snackbar";
// import { setAlertDialog } from "../../js/reducers/alertDialog";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import { OAuth as OAuthActions } from "../../js/actions";
import { inspectionJobReportIcon } from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  avatar: {
    backgroundColor: "#01579B",
  },
  present: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
  absent: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
}));

const ManageTweddleData = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [dataSet, setDataSet] = React.useState([]);
  const RefreshDataSet = async () => {
    dispatch(OAuthActions.getTweddleData()).then(function (tweddleData) {
      let newDataSet = [];
      console.log("tweddleData", tweddleData?._embedded?.tweddleErpDataDtoes);
      console.log("RefreshDataSet");
      for (
        let index = 0;
        index < tweddleData?._embedded?.tweddleErpDataDtoes.length;
        index++
      ) {
        const element = {
          amountPreviouslyPaid:
            "$ " +
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .amountPreviouslyPaid, //
          amountReceived:
            "$ " +
            tweddleData?._embedded?.tweddleErpDataDtoes[index].amountReceived, //
          balance:
            "$ " + tweddleData?._embedded?.tweddleErpDataDtoes[index].balance, //
          bankClearanceDate:
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .bankClearanceDate == ""
              ? "--"
              : new Date(
                  tweddleData?._embedded?.tweddleErpDataDtoes[
                    index
                  ].bankClearanceDate
                ).toLocaleDateString("en-US"), //
          currentBoschUnitPrice:
            "$ " +
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .currentBoschUnitPrice, //
          description:
            tweddleData?._embedded?.tweddleErpDataDtoes[index].description, //
          fordRemittanceTransNumber:
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .fordRemittanceTransNumber == ""
              ? "--"
              : tweddleData?._embedded?.tweddleErpDataDtoes[index]
                  .fordRemittanceTransNumber, //
          partNumber:
            tweddleData?._embedded?.tweddleErpDataDtoes[index].partNumber, //
          perviousBoschUnitPrice:
            "$ " +
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .perviousBoschUnitPrice, //
          quantityPaidByFord:
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .quantityPaidByFord,
          shipmentNumber:
            tweddleData?._embedded?.tweddleErpDataDtoes[index].shipmentNumber, //
          shippedDate: new Date(
            tweddleData?._embedded?.tweddleErpDataDtoes[index].shippedDate
          ).toLocaleDateString("en-US"), //
          shippedQuantity:
            tweddleData?._embedded?.tweddleErpDataDtoes[index].shippedQuantity, //
          uid: tweddleData?._embedded?.tweddleErpDataDtoes[index].uid,
          boschInvoiceNumber:
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .boschInvoiceNumber,
          fordPaymentNumber:
            tweddleData?._embedded?.tweddleErpDataDtoes[index]
              .fordPaymentNumber,
          dueDate:
            tweddleData?._embedded?.tweddleErpDataDtoes[index].dueDate != null
              ? new Date(
                  tweddleData?._embedded?.tweddleErpDataDtoes[index].dueDate
                ).toLocaleDateString("en-US")
              : "--",
        };
        console.log("tweddleData element index", index, element);
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  };
  React.useEffect(() => {
    RefreshDataSet();
  }, []);

  const columns = [
    {
      title: "Shipped Date",
      field: "shippedDate",
      editable: "never",
    },
    {
      title: "Shipment Number",
      field: "shipmentNumber",
      editable: "never",
    },
    {
      title: "Shipment Quantity",
      field: "shippedQuantity",
      editable: "never",
    },
    {
      title: "Part Number",
      field: "partNumber",
      editable: "never",
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
    },
    {
      title: "Ford Remittance Trans Number",
      field: "fordRemittanceTransNumber",
      editable: "never",
    },
    {
      title: "Bank Clearance Date",
      field: "bankClearanceDate",
      editable: "never",
    },
    {
      title: "Previous Unit Price",
      field: "perviousBoschUnitPrice",
      editable: "never",
    },
    {
      title: "Current Unit Price",
      field: "currentBoschUnitPrice",
      editable: "never",
    },
    {
      title: "Quantity Paid By Ford",
      field: "quantityPaidByFord",
      editable: "never",
    },
    {
      title: "Amount Recieved",
      field: "amountReceived",
      editable: "never",
    },
    {
      title: "Amount Previusly Paid",
      field: "amountPreviouslyPaid",
      editable: "never",
    },
    {
      title: "Balance",
      field: "balance",
      editable: "never",
    },
    {
      title: "Bosch Invoice Number",
      field: "boschInvoiceNumber",
      editable: "never",
    },
    {
      title: "Ford Payment Number",
      field: "fordPaymentNumber",
      editable: "never",
    },
    {
      title: "Due Date",
      field: "dueDate",
      editable: "never",
    },
    // {
    //   title: "Estimated Monthly Production",
    //   field: "description",
    //   editable: "never",
    // },
  ];

  const updateData = (newData, oldData) => {
    console.log("updateData", newData, oldData);
    return true;
  };
  const onRowClick = (event, rowData) => {
    console.log("onRowClick", rowData);
    return true;
  };

  const customTableData = {
    isReadOnly: true,
    isOnclick: true,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: updateData,
    onRowClick: onRowClick,
    tableLayout: "fixed",
    // fixedColumns: {
    //   left: 1,
    //   right: 0,
    // },
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item p={0} m={0}>
                <img
                  src={inspectionJobReportIcon}
                  style={{ height: "2em", margin: "0 1em" }}
                />
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2">
                    <strong>Tweddle ERP Payment Data:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                {/* <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddRole}
                  startIcon={<AddIcon />}
                >
                  Add Role
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} style="overflow: show" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageTweddleData;
