import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  roleIcon,
  manageVehicleIcon,
  addVehicleIcon,
  addUser,
} from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { Context } from "../../js/utils/Context";

import { OAuth as OAuthActions } from "../../js/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  dropWidth: {
    width: "75%",
  },
}));
const AddVehicle = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [modelNameHelper, setModelNameHelper] = useState("");
  const [modelCodeHelper, setModelCodeHelper] = useState("");
  const [variantHelper, setVariantHelper] = useState("");
  const [yearOfManufacturingHelper, setYearOfManufacturingHelper] =
    useState("");
  const [marketHelper, setMarketHelper] = useState("");

  const [modelName, setModelName] = useState("");
  const [modelCode, setModelCode] = useState("");
  const [variant, setVariant] = useState("");
  const [yearOfManufacturing, setYearOfManufacturing] = useState("");
  const [market, setMarket] = useState("");
  React.useEffect(async () => {}, []);

  const classes = useStyles();
  const handleRole = (event) => {};

  const submit = () => {
    console.log(
      "modelName, modelCode, variant, yearOfManufacturing, market",
      modelName,
      modelCode,
      variant,
      yearOfManufacturing,
      market
    );
    const details = {
      modelName: modelName,
      modelCode: modelCode,
      variant: variant,
      yearOfManufacturing: yearOfManufacturing,
      market: market,
    };
    dispatch(OAuthActions.addVehicle(details, makeSelected.uid)).then(function (
      success
    ) {
      if (success["error"] === undefined) {
        dispatch(setSnackbar(true, "success", "Add Vehicle Successful"));
        history.goBack();
      } else
        dispatch(
          setAlertDialog(
            true,
            null,
            "error",
            "Add User:",
            "Add vehicle unsuccessful.\nDetails Of Failure: " + success.message,
            "Ok",
            ""
          )
        );
    });
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={manageVehicleIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Add Vehicle:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img
              src={addVehicleIcon}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              // justify="space-around"
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={modelNameHelper.length !== 0}
                  helperText={modelNameHelper}
                  label="Model Name"
                  value={modelName}
                  onChange={(e) => {
                    setModelName(e.target.value);
                    const valid = /^[\w\-\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setModelNameHelper(
                        "Model Name must contain only Alphabets,digits and underscore"
                      );
                    } else {
                      setModelNameHelper("");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={modelCodeHelper.length !== 0}
                  helperText={modelCodeHelper}
                  label="Model Code"
                  value={modelCode}
                  onChange={(e) => {
                    setModelCode(e.target.value);
                    const valid = /^[\w\-\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setModelCodeHelper(
                        "Model Code must contain only Alphabets,digits and underscore"
                      );
                    } else {
                      setModelCodeHelper("");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={variantHelper.length !== 0}
                  helperText={variantHelper}
                  label="Variant"
                  value={variant}
                  onChange={(e) => {
                    setVariant(e.target.value);
                    const valid = /^[\w\-\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setVariantHelper(
                        "Variant must contain only Alphabets,digits and underscore"
                      );
                    } else {
                      setVariantHelper("");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={yearOfManufacturingHelper.length !== 0}
                  helperText={yearOfManufacturingHelper}
                  label="Year Of Manufacturing"
                  value={yearOfManufacturing}
                  onChange={(e) => {
                    setYearOfManufacturing(e.target.value);
                    const valid = /^\d{4}$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setYearOfManufacturingHelper(
                        "Year of manufacturing must contain only digits and lenght must be 4"
                      );
                    } else {
                      setYearOfManufacturingHelper("");
                    }
                  }}
                />
                {/* <Autocomplete
                  options={yearOfManuData}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // className={classes.dropWidth}
                      label="Year Of Manufacturing"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue == null) setRole("");
                    else setRole(newValue.uid);
                  }}
                /> */}
              </Grid>

              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // className={classes.dropWidth}
                  required
                  error={marketHelper.length !== 0}
                  helperText={marketHelper}
                  label="Market"
                  value={market}
                  onChange={(e) => {
                    setMarket(e.target.value);
                    const valid = /^[a-zA-Z\s]+$/.test(e.target.value);
                    if (!valid && e.target.value.length !== 0) {
                      setMarketHelper(
                        "Market must contain only Alphabets,digits and underscore"
                      );
                    } else {
                      setMarketHelper("");
                    }
                  }}
                />
                {/* <Autocomplete
                  options={marketData}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // className={classes.dropWidth}
                      label="Market"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue == null) setRole("");
                    else setRole(newValue.uid);
                  }}
                /> */}
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={
                        modelName == "" ||
                        modelCode == "" ||
                        variant == "" ||
                        yearOfManufacturing == "" ||
                        market == "" ||
                        modelNameHelper.length !== 0 ||
                        modelCodeHelper.length !== 0 ||
                        variantHelper.length !== 0 ||
                        yearOfManufacturingHelper.length !== 0 ||
                        marketHelper.length !== 0
                      }
                      onClick={submit}
                    >
                      Create
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AddVehicle;
