import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core";
// import {
//   userIcon,
//   roleIcon,
//   onboardNewPlantIcon,
//   createInspectionIcon,
//   contentUploadIcon,
//   assignTransferJobIcon,
//   inspectionJobReportIcon,
//   forwardArrow,
//   navProfile,
// } from "../../../../../../assets/images";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./Sidebar.scss";

// import dashboard from '../../pages/Dashboard/Background'
// import Dashboard from '../../pages/Dashboard/Dashboard';

export default function SidebarMenuItem(props) {
  // const { name, link, items, IconA = [], depth } = props;
  const { name, link, items, depth } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);
  // const [selectedEle, setSelectedEle] = React.useState(false);

  function handleClick(evt, depth, items) {
    //let ele = evt.currentTarget;
    // if (open) {
    //   ele.classList.remove("active");
    //   setSelectedEle(false);
    // } else {
    //   ele.classList.add("active");
    //   setSelectedEle(true);
    // }
    if (items) {
      if (document.getElementById(depth).classList.contains("expanded")) {
        // document
        //   .getElementById(depth)
        //   .classList.remove('expanded', 'active');
        // setSelectedEle(false);
      } else {
        // document
        //   .getElementById(depth)
        //   .classList.add('expanded', 'active');
        // setSelectedEle(true);
      }
    } else {
      let eleList = document.getElementsByClassName("left-panel");
      for (let i = 0; i < eleList.length; i++) {
        eleList[i].classList.remove("active");
      }
      let newArray = depth.split("");
      let str = "";
      for (let i = 0; i < newArray.length; i++) {
        str = str + newArray[i];
        document.getElementById(str).classList.add("active");
      }
    }
    setOpen(!open);
  }
  const MenuItemRoot = (
    <ListItem
      className={
        link === window.location.pathname
          ? "active left-panel " + classes.listItem
          : classes.listItem + " left-panel"
      }
      divider={true}
      button
      id={depth}
      onClick={(evt) => handleClick(evt, depth, items)}
    >
      <Link
        className="nav-link"
        className={!props.close ? "nav-link fill" : "nav-link stroke"}
        to={link}
        style={{ textDecoration: "none", color: "black" }}
      >
        {console.log("props.close", props.close)}
        {props.close && (
          <ListItemText className={classes.textSize} primary={name} />
        )}
      </Link>

      {/* Display the expand menu if the item has children */}
      {props.close && isExpandable && !open && <AddIcon fontSize={"small"} />}
      {props.close && isExpandable && open && <RemoveIcon fontSize={"small"} />}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open && props.close} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <SidebarMenuItem {...item} key={index} close={props.close} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: "17px",
    paddingTop: "17px",
  },
  textSize: {
    fontSize: "15px",
    whiteSpace: "normal",
  },
}));
