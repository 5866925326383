/*global _appConstants */
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import * as axios from "axios";
import TokenStorage from "../../js/utils/tokenStorage";
import { useDispatch } from "react-redux";
import { UserProfile as UserProfileActions } from "../../js/actions";
import { NoInternet } from "../../views";
import jwt from "jwt-decode";

const RouteWithLayout = (props) => {
  const dispatch = useDispatch();
  const { layout: Layout, history, component: Component, ...rest } = props;
  let sidebarOptions = [];
  const [connection, setConnection] = React.useState(true);

  window.addEventListener("offline", function (e) {
    console.log("offline");
    setConnection(false);
  });

  window.addEventListener("online", function (e) {
    console.log("online");
    setConnection(true);
  });

  const loginUser = useSelector((state) => {
    return state?.UserProfile?.loginUser || {};
  });
  useEffect(() => {
    if (TokenStorage.getToken() && !loginUser) {
      //initAllDevicesMapping(selectedCardIndex) //component did mount
      axios.defaults.headers.common["Authorization"] =
        "bearer " + TokenStorage.getToken();

      dispatch(UserProfileActions.getLoginUser()).then((k) => {
        if (!k) {
          dispatch(UserProfileActions.clearLoginUserData()).then(function () {
            history.push(_appConstants.useCommonDefaults.navRef.login);
          });
        }
      });
    }
    if (TokenStorage.getToken()) {
      const token = TokenStorage.getToken();
      const tokenDetails = jwt(token);

      var a = _appConstants.menuOptions.forEach((element) => {
        if (tokenDetails.authorities.includes(element.role)) {
          sidebarOptions.push(element);
        }
      });
    }
  }, [loginUser]);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        connection ? (
          TokenStorage.getToken() ? (
            loginUser ? (
              <Layout>
                <Component {...matchProps} />
              </Layout>
            ) : null
          ) : (
            <Redirect
              to={{
                pathname: localStorage.getItem("auth")
                  ? window.location.pathname
                  : "/login",
                state: { from: matchProps.location },
              }}
            />
          )
        ) : (
          <NoInternet />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
