//import { createStore } from "redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import middleware from "../middleware";
import * as reducers from "../reducers";

function loadFromSessionStorage() {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch {
    return undefined;
  }
}

function saveToSessionStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
  : compose;

const persistedStorage = loadFromSessionStorage();

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  persistedStorage,
  composeEnhancers(applyMiddleware(middleware, thunk))
);

store.subscribe(() => saveToSessionStorage(store.getState()));

export default store;
