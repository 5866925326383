import React from "react";
// import { underConstruction } from "../../assets/images";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(4),
    height: "74vh",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  border: {
    borderBottom: "2px solid whitesmoke",
    marginTop: "10px",
  },
}));
const NoInternet = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <h1>
          Application cannot be loaded. Please check your internet connection
          settings
        </h1>
        <br />
      </div>
    </>
  );
};

export default NoInternet;
