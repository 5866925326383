import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { setSnackbar } from "../../js/reducers/snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      color: "#2ecc71",
    },
    snackBar: {
      color: "#2ecc71",
    },
  },
}));

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        className={classes.root}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          style={{ width: "80%" }}
          onClose={handleClose}
          severity={snackbarType}
        >
          <strong className={classes.snackBar}>{snackbarMessage}</strong>
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notification;
