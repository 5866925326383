import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { LinearProgress, Typography, Box } from "@material-ui/core";

const CustomProgressBar = ({ progress, status }) => {
  const colorValue = () => {
    switch (status) {
      case "NEW":
        return "#16a085";
        break;
      case "IN_PROGRESS":
        return "#2980b9";
        break;
      case "COMPLETED":
        return "#2c3e50";
        break;
      case "CANCELLED":
        return "#c0392b";
        break;

      default:
        break;
    }
  };
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: colorValue,
    },
  }))(LinearProgress);
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default CustomProgressBar;
