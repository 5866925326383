import { UserProfile as UserProfileActions } from "../actions";
import TokenStorage from "../utils/tokenStorage";

let initialState = {};

const loginUserViewData = (response) => {
  return {
    uid: response.uid,
    emailId: response.emailId,
    role: "SUPER_ADMIN",
    roleName: "SUPER_ADMIN", //response.role ? response.role.roleName : '',
    roleFeatures: response.role ? response.role.features : [],
    firstName: response.firstName,
    lastName: response.lastName,
    phoneNumber: response.phoneNumber,
    displayName:
      (response.firstName + " " + response.lastName).charAt(0).toUpperCase() +
      (response.firstName + " " + response.lastName).slice(
        1,
        (response.firstName + " " + response.lastName).length
      ),
    avatarDisplayName: (response.firstName + " " + response.lastName)
      .charAt(0)
      .toUpperCase(),
    roleDisplayName: "SUPER_ADMIN",
    company: "",
    country: response.country,
    profilePic: response.profilePic ? response.profilePic : "",
  };
};

const prepareSidebarMenu = (roleFeatures, role) => {
  let menuWithPermissions = { vehicleManagement: {}, tripManagement: {} };
  let attachments = roleFeatures.filter((role) => role.startsWith("ATT"));
  let allVehicles = roleFeatures.filter((role) => role.startsWith("VEH"));
  let groupVehicles = roleFeatures.filter((role) => role.startsWith("GRP"));
  let driverManagement = roleFeatures.filter((role) => role.startsWith("DRI"));
  let tripManagement = roleFeatures.filter((role) => role.startsWith("TRP"));
  let userManagement = roleFeatures.filter((role) => role.startsWith("USR"));

  if (attachments.length > 0) {
    menuWithPermissions.attachments = { show: true, permissions: attachments };
  }
  if (allVehicles.length > 0) {
    menuWithPermissions.vehicleManagement.all = { permissions: allVehicles };
  }
  if (groupVehicles.length > 0) {
    menuWithPermissions.vehicleManagement.group = {
      permissions: groupVehicles,
    };
  }
  if (driverManagement.length > 0) {
    menuWithPermissions.driverManagement = {
      show: driverManagement.length > 0,
      permissions: driverManagement,
    };
  }
  if (
    tripManagement.length > 0 &&
    role &&
    role.toUpperCase() !== "FLEET_OWNER"
  ) {
    menuWithPermissions.tripManagement = {
      show: tripManagement.length > 0,
      permissions: tripManagement,
    };
  }

  menuWithPermissions.vehicleManagement.show =
    allVehicles.length > 0 || groupVehicles.length > 0;
  if (role && role.toUpperCase() === "SUPER_ADMIN") {
    menuWithPermissions.vehicleDeviceMapping = {
      show: true,
      permissions: roleFeatures,
    };
    menuWithPermissions.userManagement = {
      show: true,
      permissions: userManagement,
    };
  }
  return menuWithPermissions;
};

const UserProfile = (state = initialState, action) => {
  switch (action.type) {
    case UserProfileActions.STORE_LOGIN_USER:
      var loginUserData = loginUserViewData(action.loginUserData);
      return Object.assign({}, state, {
        loginUser: loginUserData,
        features: prepareSidebarMenu(
          loginUserData.roleFeatures,
          loginUserData.roleName
        ),
      });

    case UserProfileActions.CLEAR_LOGIN_USER:
      // debugger;
      delete state.loginUser;
      delete state.features;
      delete state.loginUserData;
      delete state.Oauth;
      TokenStorage.clearAll();
      TokenStorage.clearAllCookies();
      sessionStorage.clear();

      return Object.assign({}, state);

    case UserProfileActions.UPDATE_LOGIN_USER:
      state.loginUser.profilePic = action.profilePic;
      return Object.assign({}, state);

    default:
      break;
  }

  return state;
};

export default UserProfile;
