import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";

ReactGA.initialize("GTM-5RK9R65");

function DataProtectionPolicy() {
  useEffect(() => {
    ReactGA.event({
      category: "Data Protection Policy",
      action: "Viewing Data Protection Policy",
    });
    return () => {};
  }, []);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        <strong>Data protection policy</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Robert Bosch GmbH (henceforth &ldquo;Bosch,&rdquo; &ldquo;we,&rdquo; or
        &ldquo;us&rdquo;) appreciates your visit to our websites and mobile
        applications (collectively also referred to as our &ldquo;online
        services&rdquo;), as well as your interest in our company and our
        products.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>1. Bosch respects your privacy</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        The protection of your privacy during the processing of personal data,
        as well as the security of all business data are very important to us.
        Our business processes take this into consideration. We process personal
        data that was gathered during your use of our online services
        confidentially and solely in accordance with the law.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Data privacy and information security are part of our corporate policy.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>2. Data controller and contact</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        The controller responsible for the processing of your data is:
      </Typography>

      <Typography variant="body1" gutterBottom>
        Robert Bosch GmbH
      </Typography>

      <Typography variant="body1" gutterBottom>
        Robert-Bosch-Platz 1 70839 Gerlingen-Schillerh&ouml;he, Germany
      </Typography>

      <Typography variant="body1" gutterBottom>
        E-mail: kontakt@bosch.de
      </Typography>

      <Typography variant="body1" gutterBottom>
        Phone: +49 711 400 40990
      </Typography>

      <Typography variant="body1" gutterBottom>
        To assert your rights, report data-privacy incidents, make suggestions
        and complaints regarding the processing of your personal data, and
        withdraw your consent, we recommend that you contact our chief data
        protection officer:
      </Typography>

      <Typography variant="body1" gutterBottom>
        Matthias Goebel Chief data protection officer Information security and
        privacy Bosch Group (C/ISP) Robert Bosch GmbH Kronenstra&szlig;e 22
        70173 Stuttgart, Germany
      </Typography>

      <Typography variant="body1" gutterBottom>
        Alternatively, use the following link:
      </Typography>

      <Typography variant="body1" gutterBottom>
        https://www.bkms-system.net/bosch-datenschutz
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>3. Processing of personal data</strong>
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.1 Categories of data processed
      </Typography>

      <Typography variant="body2" gutterBottom>
        The data processed are communication-related data such as name, phone
        number, e-mail address, street address, and IP address.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.2 Processing principles
      </Typography>

      <Typography variant="body2" gutterBottom>
        Personal data consists of all information related to an identified or
        identifiable natural person, e.g. names, addresses, phone numbers, and
        e-mail addresses which are an expression of a person&rsquo;s identity.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        We only process personal data when there is either a legal basis to do
        so or you have given your consent to the processing or use of personal
        data, e.g. by registering.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3 Purposes of processing, legal bases
      </Typography>

      <Typography variant="body2" gutterBottom>
        We and the service providers we contract process your personal data for
        the following purposes:
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3.1 Making this online service available
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: overriding, legitimate interest on our part in direct
        marketing, as long as this occurs in accordance with data protection and
        competition law
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3.2 Making this online service available with registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: fulfillment of a contract or by consent
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3.3 To investigate faults/errors and for security reasons
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal bases: fulfillment of our legal obligations with regard to data
        security and overriding, legitimate interest in resolving faults/errors
        and in the security of our services.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3.4 Safeguarding and defense of our rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: legitimate interest on our part in asserting and defending
        our rights.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.3.5 Application in the context of a success story
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: Fulfilment of our legal obligations in the field of data
        security and predominantly, justified interest in the rectification of
        malfunctions and the security of our offers.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.4 Registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you wish to use or get access to benefits requiring to enter into the
        fulfillment of a contract, we request your registration. With your
        registration we collect personal data necessary for entering into the
        fulfillment of the contract (e.g. first name, last name, date of birth,
        email address, if applicable, details on the preferred payment method or
        on the account holder) as well as further data on voluntary basis, if
        applicable. Mandatory information is marked with an *.
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.5 Log files
      </Typography>

      <Typography variant="body2" gutterBottom>
        Every time you use the internet, your browser transmits certain
        information, which we store in so-called log files.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We save log files for a short time solely for error tracking and for
        security reasons (e.g. to investigate hacking attempts) before deleting
        them. Log files which need to be kept for evidence purposes are not
        deleted until the relevant incident has been completely resolved and
        may, on a case-by-case basis, be passed on to investigating authorities.
      </Typography>

      <Typography variant="body2" gutterBottom>
        In log files, the following information in particular is saved:
      </Typography>

      <Typography variant="body2" gutterBottom>
        IP address (internet protocol address) of the terminal device which is
        being used to access the online services; internet address of the
        website from which the online services have been accessed (so-called URL
        of origin or referrer URL); name of the service provider which is used
        to access the online services; name of the files or information
        accessed; name of the files or information accessed; amount of data
        transferred; operating system and information on the internet browser
        used including add-ons installed (e.g., Flash Player); http status code
        (e.g., &ldquo;request successful&rdquo; or &ldquo;requested file not
        found&rdquo;). 3.6 Data transfer
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        3.6.1 Data transfer to other controllers
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a matter of principle, your personal data is forwarded to other
        controllers only when this is required for the fulfillment of a
        contract, in cases where we or the third party have an overriding,
        legitimate interest in the data transfer, or when your consent has been
        given. Information regarding the legal bases can be found in section 3.3
        &ldquo; Purposes of processing and legal bases. &rdquo; Third parties
        may also be other companies of the Bosch Group. The transfer of data to
        third parties on the basis of an overriding, legitimate interest is
        explained in this data protection policy.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Additionally, data may be transferred to other controllers when we are
        obliged to do so due to legal provisions or enforceable administrative
        or court orders.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>3.6.2 Data transfer to service providers</strong>
      </Typography>

      <Typography variant="body2" gutterBottom>
        We contract external service providers to perform tasks such as
        marketing services, programming, data hosting, and hotline services. We
        have chosen these service providers carefully and review them regularly,
        especially regarding their careful handling and protection of data they
        have stored. All service providers are obliged to maintain
        confidentiality and to abide by legal provisions. Service providers may
        also be other Bosch Group companies.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.3 Transfer to recipients outside the EEA
      </Typography>

      <Typography variant="body2" gutterBottom>
        We can also transfer personal data to recipients located outside the EEA
        in so-called third countries. In such cases, we ensure prior to the
        transfer either that the data recipient provides an appropriate level of
        data protection (e.g. via a decision of adequacy by the European
        Commission for the respective country or via an agreement based on
        standard contractual clauses between the EU and the recipient), or that
        you have consented to the transfer.
      </Typography>

      <Typography variant="body2" gutterBottom>
        You are entitled to receive an overview of third country recipients and
        a copy of the specific provisions securing an appropriate level of data
        protection to which you have agreed. To receive this overview, get in
        touch with us using the details provided in the &ldquo; Data controller
        and contact &rdquo; section (see section 2 above).
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.7 Duration of storage; retention periods
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a general rule, we store your data for as long as necessary for the
        performance of our online services and the services connected to them,
        or for as long as we have a legitimate interest in storing the data (for
        example, we might still have a legitimate interest in postal-mail
        marketing on fulfillment of a contract). In all other cases, we delete
        your personal data with the exception of data we must store to fulfill
        legal obligations (for example, where we are obliged by tax and
        commercial law to retain documents such as contracts and invoices for a
        certain period of time).
      </Typography>

      <Typography variant="body2" gutterBottom>
        4. Use of cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.1 General
      </Typography>

      <Typography variant="body2" gutterBottom>
        Cookies are small text files that are saved on your computer when you
        use one of our online services. Should you access this online service
        again, your browser sends the cookies&rsquo; content back to us, thus
        allowing us to re-identify your terminal device. Reading the cookies we
        have set allows us to optimize our online services for you and make it
        easier for you to use them.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.2 Disabling and deleting cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        You can however use your browser to delete all cookies at any time. Your
        browser&rsquo;s help function gives information on how to do this.
        However, this may mean that individual functions are no longer available
        to you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        On the following webpage, you can also manage and disable the use of
        cookies by third parties:
      </Typography>

      <Typography variant="body2" gutterBottom>
        http://www.youronlinechoices.com/de/praferenzmanagement
      </Typography>

      <Typography variant="body2" gutterBottom>
        As this website is not operated by us, we do not accept any
        responsibifor it and have no influence over content and availability.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3. List of the cookies we use
      </Typography>

      <Typography variant="body2" gutterBottom>
        The following section contains a list of the cookies we use.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3.1 Essential cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        Certain cookies are essential for our online services to work properly.
        They include:
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies used to identify and authenticate our users;
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that temporarily save certain user information (such as
        the content of a shopping cart or of an online form);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save certain user preferences (such as search and
        language settings);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save data in order to ensure uninterrupted
        listening to audio content and viewing of video content.
      </Typography>

      <Typography variant="body2" gutterBottom>
        5. External links
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services may contain links to third-party websites that are
        operated by providers not connected to us. When you click these links,
        we have no influence on the collection, processing, and use of the
        personal data possibly transmitted to the third party (such as the IP
        address or the URL of the site on which the link is located), as the
        actions of third parties are by nature beyond our control. We do not
        assume any responsibility for the processing of such personal data by
        third parties.
      </Typography>

      <Typography variant="body2" gutterBottom>
        6. Duty to disclose information
      </Typography>

      <Typography variant="body2" gutterBottom>
        In the course of our business relationship with you, we require such
        personally identifiable information as is necessary for commencing and
        carrying out the business relationship and fulfilling the contractual
        duties connected with it, or that we are legally obliged to collect.
        Without such information, we will generally be unable to conclude a
        contract with you or to carry it out.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our online service, it is above all necessary for you to
        provide your IP address. Without your IP address, we cannot address your
        terminal device.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our contact form and service chat, you have to make such
        personally identifiable information available as is we need to answer
        your request and carry out your order. Without such information, we will
        generally not be able to answer requests, carry out orders, and in some
        cases bring communications to a close.
      </Typography>

      <Typography variant="body2" gutterBottom>
        7. Security
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our associates and the companies providing services on our behalf are
        obliged to uphold confidentiality and to comply with all applicable data
        protection laws.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We take all necessary technical and organizational measures to ensure an
        appropriate level of security and to protect any data of yours that we
        process from the risk of unintentional or unlawful destruction,
        manipulation, loss, change, or unauthorized disclosure or access. Our
        security measures are constantly being improved in line with
        technological developments.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8. Your rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        To assert your rights, please follow the instructions provided in
        section 2 (&ldquo;Data controller and contact&rdquo;). In doing so,
        please provide us with information we can use to personally identify
        you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.1 Right to information and access:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to obtain information from us about whether or not
        your personal data is being processed, and, where this is the case, to
        access your personal data.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.2 Right to correction and deletion:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand that we correct inaccurate personal data
        relating to you and &ndash; provided that legal requirements have been
        met &ndash; amend or delete it.
      </Typography>

      <Typography variant="body2" gutterBottom>
        This does not apply to data required for billing or accounting purposes
        or which is subject to a legal retention period. Where access to such
        data is not required, however, its processing is restricted (see the
        following).
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.3 Restriction of processing:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand &ndash; provided that legal requirements
        have been met &ndash; that the processing of your data be restricted.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.4 Data portability:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You continue to have the right to receive the data you have provided us
        in a structured, commonly used, and machine-readable format and &ndash;
        wherever technically feasible &ndash; to demand that the data be
        transmitted to a third party.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5 Right to object
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.1 Right to object based on individual situation
      </Typography>

      <Typography variant="body2" gutterBottom>
        If we process data on the basis of an overriding justified interest of
        the kind described in this data protection notice, you have the right to
        object, on grounds relating to your particular situation, at any time to
        this processing. We will no longer process your personal data unless, in
        accordance with legal requirements, we can demonstrate compelling and
        legitimate grounds for their further processing which override your
        interests, rights, and freedoms, or if their further processing serves
        to establish, exercise, or defend legal rights.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.2 Objection to data processing for the purposes of direct marketing
      </Typography>

      <Typography variant="body2" gutterBottom>
        In addition, you can object at any time to the processing of your
        personal data for advertising purposes. Please note that, for
        organizational reasons, there may be an overlap between your objection
        and the use of your data in a campaign that is already running.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.6 Right to withdraw consent
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you have consented to the processing of your data, you can withdraw
        this consent with future effect at any time. This also applies to
        withdrawal of consent given to us prior to the enactment of the General
        Data Protection Regulation (May 25, 2018). This does not affect the
        lawfulness of the processing of your data prior to withdrawal.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.7 Right of complaint with supervisory authority
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to lodge a complaint with a data protection
        authority. You can either contact the data protection authority
        responsible for your place of residence or the authority responsible for
        us. Die f&uuml;r uns zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde
        ist der Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit
        Baden-W&uuml;rttemberg.
      </Typography>

      <Typography variant="body2" gutterBottom>
        9.Children
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services are not aimed at children under 16 years of age.
      </Typography>

      <Typography variant="body2" gutterBottom>
        10. Changes to the data protection notice
      </Typography>

      <Typography variant="body2" gutterBottom>
        We reserve the right to amend our security and data protection measures
        where required to take account of technological developments. In such
        cases, we will amend our data protection policy accordingly. Please
        always consult the current version of our data protection policy, as
        this is subject to change.
      </Typography>

      <Typography variant="body2" gutterBottom>
        September 16, 2019Robert Bosch GmbH (henceforth &ldquo;Bosch,&rdquo;
        &ldquo;we,&rdquo; or &ldquo;us&rdquo;) appreciates your visit to our
        websites and mobile applications (collectively also referred to as our
        &ldquo;online services&rdquo;), as well as your interest in our company
        and our products.
      </Typography>

      <Typography variant="body2" gutterBottom>
        1. Bosch respects your privacy
      </Typography>

      <Typography variant="body2" gutterBottom>
        The protection of your privacy during the processing of personal data,
        as well as the security of all business data are very important to us.
        Our business processes take this into consideration. We process personal
        data that was gathered during your use of our online services
        confidentially and solely in accordance with the law.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Data privacy and information security are part of our corporate policy.
      </Typography>

      <Typography variant="body2" gutterBottom>
        2. Data controller and contact
      </Typography>

      <Typography variant="body2" gutterBottom>
        The controller responsible for the processing of your data is:
      </Typography>

      <Typography variant="body2" gutterBottom>
        Robert Bosch GmbH
      </Typography>

      <Typography variant="body2" gutterBottom>
        Robert-Bosch-Platz 1 70839 Gerlingen-Schillerh&ouml;he, Germany
      </Typography>

      <Typography variant="body2" gutterBottom>
        E-mail: kontakt@bosch.de
      </Typography>

      <Typography variant="body2" gutterBottom>
        Phone: +49 711 400 40990
      </Typography>

      <Typography variant="body2" gutterBottom>
        To assert your rights, report data-privacy incidents, make suggestions
        and complaints regarding the processing of your personal data, and
        withdraw your consent, we recommend that you contact our chief data
        protection officer:
      </Typography>

      <Typography variant="body2" gutterBottom>
        Matthias Goebel Chief data protection officer Information security and
        privacy Bosch Group (C/ISP) Robert Bosch GmbH Kronenstra&szlig;e 22
        70173 Stuttgart, Germany
      </Typography>

      <Typography variant="body2" gutterBottom>
        Alternatively, use the following link:
      </Typography>

      <Typography variant="body2" gutterBottom>
        https://www.bkms-system.net/bosch-datenschutz
      </Typography>

      <Typography variant="body2" gutterBottom>
        3. Processing of personal data
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.1 Categories of data processed
      </Typography>

      <Typography variant="body2" gutterBottom>
        The data processed are communication-related data such as name, phone
        number, e-mail address, street address, and IP address.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.2 Processing principles
      </Typography>

      <Typography variant="body2" gutterBottom>
        Personal data consists of all information related to an identified or
        identifiable natural person, e.g. names, addresses, phone numbers, and
        e-mail addresses which are an expression of a person&rsquo;s identity.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We only process personal data when there is either a legal basis to do
        so or you have given your consent to the processing or use of personal
        data, e.g. by registering.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3 Purposes of processing, legal bases
      </Typography>

      <Typography variant="body2" gutterBottom>
        We and the service providers we contract process your personal data for
        the following purposes:
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.1 Making this online service available
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: overriding, legitimate interest on our part in direct
        marketing, as long as this occurs in accordance with data protection and
        competition law
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.2 Making this online service available with registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: fulfillment of a contract or by consent
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.3 To investigate faults/errors and for security reasons
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal bases: fulfillment of our legal obligations with regard to data
        security and overriding, legitimate interest in resolving faults/errors
        and in the security of our services.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.4 Safeguarding and defense of our rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: legitimate interest on our part in asserting and defending
        our rights.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.5 Application in the context of a success story
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: Fulfilment of our legal obligations in the field of data
        security and predominantly, justified interest in the rectification of
        malfunctions and the security of our offers.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.4 Registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you wish to use or get access to benefits requiring to enter into the
        fulfillment of a contract, we request your registration. With your
        registration we collect personal data necessary for entering into the
        fulfillment of the contract (e.g. first name, last name, date of birth,
        email address, if applicable, details on the preferred payment method or
        on the account holder) as well as further data on voluntary basis, if
        applicable. Mandatory information is marked with an *.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.5 Log files
      </Typography>

      <Typography variant="body2" gutterBottom>
        Every time you use the internet, your browser transmits certain
        information, which we store in so-called log files.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We save log files for a short time solely for error tracking and for
        security reasons (e.g. to investigate hacking attempts) before deleting
        them. Log files which need to be kept for evidence purposes are not
        deleted until the relevant incident has been completely resolved and
        may, on a case-by-case basis, be passed on to investigating authorities.
      </Typography>

      <Typography variant="body2" gutterBottom>
        In log files, the following information in particular is saved:
      </Typography>

      <Typography variant="body2" gutterBottom>
        IP address (internet protocol address) of the terminal device which is
        being used to access the online services; internet address of the
        website from which the online services have been accessed (so-called URL
        of origin or referrer URL); name of the service provider which is used
        to access the online services; name of the files or information
        accessed; name of the files or information accessed; amount of data
        transferred; operating system and information on the internet browser
        used including add-ons installed (e.g., Flash Player); http status code
        (e.g., &ldquo;request successful&rdquo; or &ldquo;requested file not
        found&rdquo;). 3.6 Data transfer
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.1 Data transfer to other controllers
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a matter of principle, your personal data is forwarded to other
        controllers only when this is required for the fulfillment of a
        contract, in cases where we or the third party have an overriding,
        legitimate interest in the data transfer, or when your consent has been
        given. Information regarding the legal bases can be found in section 3.3
        &ldquo; Purposes of processing and legal bases. &rdquo; Third parties
        may also be other companies of the Bosch Group. The transfer of data to
        third parties on the basis of an overriding, legitimate interest is
        explained in this data protection policy.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Additionally, data may be transferred to other controllers when we are
        obliged to do so due to legal provisions or enforceable administrative
        or court orders.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.2 Data transfer to service providers
      </Typography>

      <Typography variant="body2" gutterBottom>
        We contract external service providers to perform tasks such as
        marketing services, programming, data hosting, and hotline services. We
        have chosen these service providers carefully and review them regularly,
        especially regarding their careful handling and protection of data they
        have stored. All service providers are obliged to maintain
        confidentiality and to abide by legal provisions. Service providers may
        also be other Bosch Group companies.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.3 Transfer to recipients outside the EEA
      </Typography>

      <Typography variant="body2" gutterBottom>
        We can also transfer personal data to recipients located outside the EEA
        in so-called third countries. In such cases, we ensure prior to the
        transfer either that the data recipient provides an appropriate level of
        data protection (e.g. via a decision of adequacy by the European
        Commission for the respective country or via an agreement based on
        standard contractual clauses between the EU and the recipient), or that
        you have consented to the transfer.
      </Typography>

      <Typography variant="body2" gutterBottom>
        You are entitled to receive an overview of third country recipients and
        a copy of the specific provisions securing an appropriate level of data
        protection to which you have agreed. To receive this overview, get in
        touch with us using the details provided in the &ldquo; Data controller
        and contact &rdquo; section (see section 2 above).
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.7 Duration of storage; retention periods
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a general rule, we store your data for as long as necessary for the
        performance of our online services and the services connected to them,
        or for as long as we have a legitimate interest in storing the data (for
        example, we might still have a legitimate interest in postal-mail
        marketing on fulfillment of a contract). In all other cases, we delete
        your personal data with the exception of data we must store to fulfill
        legal obligations (for example, where we are obliged by tax and
        commercial law to retain documents such as contracts and invoices for a
        certain period of time).
      </Typography>

      <Typography variant="body2" gutterBottom>
        4. Use of cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.1 General
      </Typography>

      <Typography variant="body2" gutterBottom>
        Cookies are small text files that are saved on your computer when you
        use one of our online services. Should you access this online service
        again, your browser sends the cookies&rsquo; content back to us, thus
        allowing us to re-identify your terminal device. Reading the cookies we
        have set allows us to optimize our online services for you and make it
        easier for you to use them.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.2 Disabling and deleting cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        You can however use your browser to delete all cookies at any time. Your
        browser&rsquo;s help function gives information on how to do this.
        However, this may mean that individual functions are no longer available
        to you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        On the following webpage, you can also manage and disable the use of
        cookies by third parties:
      </Typography>

      <Typography variant="body2" gutterBottom>
        http://www.youronlinechoices.com/de/praferenzmanagement
      </Typography>

      <Typography variant="body2" gutterBottom>
        As this website is not operated by us, we do not accept any
        responsibifor it and have no influence over content and availability.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3. List of the cookies we use
      </Typography>

      <Typography variant="body2" gutterBottom>
        The following section contains a list of the cookies we use.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3.1 Essential cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        Certain cookies are essential for our online services to work properly.
        They include:
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies used to identify and authenticate our users;
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that temporarily save certain user information (such as
        the content of a shopping cart or of an online form);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save certain user preferences (such as search and
        language settings);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save data in order to ensure uninterrupted
        listening to audio content and viewing of video content.
      </Typography>

      <Typography variant="body2" gutterBottom>
        5. External links
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services may contain links to third-party websites that are
        operated by providers not connected to us. When you click these links,
        we have no influence on the collection, processing, and use of the
        personal data possibly transmitted to the third party (such as the IP
        address or the URL of the site on which the link is located), as the
        actions of third parties are by nature beyond our control. We do not
        assume any responsibility for the processing of such personal data by
        third parties.
      </Typography>

      <Typography variant="body2" gutterBottom>
        6. Duty to disclose information
      </Typography>

      <Typography variant="body2" gutterBottom>
        In the course of our business relationship with you, we require such
        personally identifiable information as is necessary for commencing and
        carrying out the business relationship and fulfilling the contractual
        duties connected with it, or that we are legally obliged to collect.
        Without such information, we will generally be unable to conclude a
        contract with you or to carry it out.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our online service, it is above all necessary for you to
        provide your IP address. Without your IP address, we cannot address your
        terminal device.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our contact form and service chat, you have to make such
        personally identifiable information available as is we need to answer
        your request and carry out your order. Without such information, we will
        generally not be able to answer requests, carry out orders, and in some
        cases bring communications to a close.
      </Typography>

      <Typography variant="body2" gutterBottom>
        7. Security
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our associates and the companies providing services on our behalf are
        obliged to uphold confidentiality and to comply with all applicable data
        protection laws.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We take all necessary technical and organizational measures to ensure an
        appropriate level of security and to protect any data of yours that we
        process from the risk of unintentional or unlawful destruction,
        manipulation, loss, change, or unauthorized disclosure or access. Our
        security measures are constantly being improved in line with
        technological developments.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8. Your rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        To assert your rights, please follow the instructions provided in
        section 2 (&ldquo;Data controller and contact&rdquo;). In doing so,
        please provide us with information we can use to personally identify
        you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.1 Right to information and access:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to obtain information from us about whether or not
        your personal data is being processed, and, where this is the case, to
        access your personal data.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.2 Right to correction and deletion:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand that we correct inaccurate personal data
        relating to you and &ndash; provided that legal requirements have been
        met &ndash; amend or delete it.
      </Typography>

      <Typography variant="body2" gutterBottom>
        This does not apply to data required for billing or accounting purposes
        or which is subject to a legal retention period. Where access to such
        data is not required, however, its processing is restricted (see the
        following).
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.3 Restriction of processing:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand &ndash; provided that legal requirements
        have been met &ndash; that the processing of your data be restricted.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.4 Data portability:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You continue to have the right to receive the data you have provided us
        in a structured, commonly used, and machine-readable format and &ndash;
        wherever technically feasible &ndash; to demand that the data be
        transmitted to a third party.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5 Right to object
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.1 Right to object based on individual situation
      </Typography>

      <Typography variant="body2" gutterBottom>
        If we process data on the basis of an overriding justified interest of
        the kind described in this data protection notice, you have the right to
        object, on grounds relating to your particular situation, at any time to
        this processing. We will no longer process your personal data unless, in
        accordance with legal requirements, we can demonstrate compelling and
        legitimate grounds for their further processing which override your
        interests, rights, and freedoms, or if their further processing serves
        to establish, exercise, or defend legal rights.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.2 Objection to data processing for the purposes of direct marketing
      </Typography>

      <Typography variant="body2" gutterBottom>
        In addition, you can object at any time to the processing of your
        personal data for advertising purposes. Please note that, for
        organizational reasons, there may be an overlap between your objection
        and the use of your data in a campaign that is already running.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.6 Right to withdraw consent
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you have consented to the processing of your data, you can withdraw
        this consent with future effect at any time. This also applies to
        withdrawal of consent given to us prior to the enactment of the General
        Data Protection Regulation (May 25, 2018). This does not affect the
        lawfulness of the processing of your data prior to withdrawal.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.7 Right of complaint with supervisory authority
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to lodge a complaint with a data protection
        authority. You can either contact the data protection authority
        responsible for your place of residence or the authority responsible for
        us. Die f&uuml;r uns zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde
        ist der Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit
        Baden-W&uuml;rttemberg.
      </Typography>

      <Typography variant="body2" gutterBottom>
        9.Children
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services are not aimed at children under 16 years of age.
      </Typography>

      <Typography variant="body2" gutterBottom>
        10. Changes to the data protection notice
      </Typography>

      <Typography variant="body2" gutterBottom>
        We reserve the right to amend our security and data protection measures
        where required to take account of technological developments. In such
        cases, we will amend our data protection policy accordingly. Please
        always consult the current version of our data protection policy, as
        this is subject to change.
      </Typography>

      <Typography variant="body2" gutterBottom>
        September 16, 2019Robert Bosch GmbH (henceforth &ldquo;Bosch,&rdquo;
        &ldquo;we,&rdquo; or &ldquo;us&rdquo;) appreciates your visit to our
        websites and mobile applications (collectively also referred to as our
        &ldquo;online services&rdquo;), as well as your interest in our company
        and our products.
      </Typography>

      <Typography variant="body2" gutterBottom>
        1. Bosch respects your privacy
      </Typography>

      <Typography variant="body2" gutterBottom>
        The protection of your privacy during the processing of personal data,
        as well as the security of all business data are very important to us.
        Our business processes take this into consideration. We process personal
        data that was gathered during your use of our online services
        confidentially and solely in accordance with the law.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Data privacy and information security are part of our corporate policy.
      </Typography>

      <Typography variant="body2" gutterBottom>
        2. Data controller and contact
      </Typography>

      <Typography variant="body2" gutterBottom>
        The controller responsible for the processing of your data is:
      </Typography>

      <Typography variant="body2" gutterBottom>
        Robert Bosch GmbH
      </Typography>

      <Typography variant="body2" gutterBottom>
        Robert-Bosch-Platz 1 70839 Gerlingen-Schillerh&ouml;he, Germany
      </Typography>

      <Typography variant="body2" gutterBottom>
        E-mail: kontakt@bosch.de
      </Typography>

      <Typography variant="body2" gutterBottom>
        Phone: +49 711 400 40990
      </Typography>

      <Typography variant="body2" gutterBottom>
        To assert your rights, report data-privacy incidents, make suggestions
        and complaints regarding the processing of your personal data, and
        withdraw your consent, we recommend that you contact our chief data
        protection officer:
      </Typography>

      <Typography variant="body2" gutterBottom>
        Matthias Goebel Chief data protection officer Information security and
        privacy Bosch Group (C/ISP) Robert Bosch GmbH Kronenstra&szlig;e 22
        70173 Stuttgart, Germany
      </Typography>

      <Typography variant="body2" gutterBottom>
        Alternatively, use the following link:
      </Typography>

      <Typography variant="body2" gutterBottom>
        https://www.bkms-system.net/bosch-datenschutz
      </Typography>

      <Typography variant="body2" gutterBottom>
        3. Processing of personal data
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.1 Categories of data processed
      </Typography>

      <Typography variant="body2" gutterBottom>
        The data processed are communication-related data such as name, phone
        number, e-mail address, street address, and IP address.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.2 Processing principles
      </Typography>

      <Typography variant="body2" gutterBottom>
        Personal data consists of all information related to an identified or
        identifiable natural person, e.g. names, addresses, phone numbers, and
        e-mail addresses which are an expression of a person&rsquo;s identity.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We only process personal data when there is either a legal basis to do
        so or you have given your consent to the processing or use of personal
        data, e.g. by registering.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3 Purposes of processing, legal bases
      </Typography>

      <Typography variant="body2" gutterBottom>
        We and the service providers we contract process your personal data for
        the following purposes:
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.1 Making this online service available
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: overriding, legitimate interest on our part in direct
        marketing, as long as this occurs in accordance with data protection and
        competition law
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.2 Making this online service available with registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: fulfillment of a contract or by consent
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.3 To investigate faults/errors and for security reasons
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal bases: fulfillment of our legal obligations with regard to data
        security and overriding, legitimate interest in resolving faults/errors
        and in the security of our services.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.4 Safeguarding and defense of our rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: legitimate interest on our part in asserting and defending
        our rights.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.3.5 Application in the context of a success story
      </Typography>

      <Typography variant="body2" gutterBottom>
        Legal basis: Fulfilment of our legal obligations in the field of data
        security and predominantly, justified interest in the rectification of
        malfunctions and the security of our offers.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.4 Registration
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you wish to use or get access to benefits requiring to enter into the
        fulfillment of a contract, we request your registration. With your
        registration we collect personal data necessary for entering into the
        fulfillment of the contract (e.g. first name, last name, date of birth,
        email address, if applicable, details on the preferred payment method or
        on the account holder) as well as further data on voluntary basis, if
        applicable. Mandatory information is marked with an *.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.5 Log files
      </Typography>

      <Typography variant="body2" gutterBottom>
        Every time you use the internet, your browser transmits certain
        information, which we store in so-called log files.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We save log files for a short time solely for error tracking and for
        security reasons (e.g. to investigate hacking attempts) before deleting
        them. Log files which need to be kept for evidence purposes are not
        deleted until the relevant incident has been completely resolved and
        may, on a case-by-case basis, be passed on to investigating authorities.
      </Typography>

      <Typography variant="body2" gutterBottom>
        In log files, the following information in particular is saved:
      </Typography>

      <Typography variant="body2" gutterBottom>
        IP address (internet protocol address) of the terminal device which is
        being used to access the online services; internet address of the
        website from which the online services have been accessed (so-called URL
        of origin or referrer URL); name of the service provider which is used
        to access the online services; name of the files or information
        accessed; name of the files or information accessed; amount of data
        transferred; operating system and information on the internet browser
        used including add-ons installed (e.g., Flash Player); http status code
        (e.g., &ldquo;request successful&rdquo; or &ldquo;requested file not
        found&rdquo;). 3.6 Data transfer
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.1 Data transfer to other controllers
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a matter of principle, your personal data is forwarded to other
        controllers only when this is required for the fulfillment of a
        contract, in cases where we or the third party have an overriding,
        legitimate interest in the data transfer, or when your consent has been
        given. Information regarding the legal bases can be found in section 3.3
        &ldquo; Purposes of processing and legal bases. &rdquo; Third parties
        may also be other companies of the Bosch Group. The transfer of data to
        third parties on the basis of an overriding, legitimate interest is
        explained in this data protection policy.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Additionally, data may be transferred to other controllers when we are
        obliged to do so due to legal provisions or enforceable administrative
        or court orders.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.2 Data transfer to service providers
      </Typography>

      <Typography variant="body2" gutterBottom>
        We contract external service providers to perform tasks such as
        marketing services, programming, data hosting, and hotline services. We
        have chosen these service providers carefully and review them regularly,
        especially regarding their careful handling and protection of data they
        have stored. All service providers are obliged to maintain
        confidentiality and to abide by legal provisions. Service providers may
        also be other Bosch Group companies.
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.6.3 Transfer to recipients outside the EEA
      </Typography>

      <Typography variant="body2" gutterBottom>
        We can also transfer personal data to recipients located outside the EEA
        in so-called third countries. In such cases, we ensure prior to the
        transfer either that the data recipient provides an appropriate level of
        data protection (e.g. via a decision of adequacy by the European
        Commission for the respective country or via an agreement based on
        standard contractual clauses between the EU and the recipient), or that
        you have consented to the transfer.
      </Typography>

      <Typography variant="body2" gutterBottom>
        You are entitled to receive an overview of third country recipients and
        a copy of the specific provisions securing an appropriate level of data
        protection to which you have agreed. To receive this overview, get in
        touch with us using the details provided in the &ldquo; Data controller
        and contact &rdquo; section (see section 2 above).
      </Typography>

      <Typography variant="body2" gutterBottom>
        3.7 Duration of storage; retention periods
      </Typography>

      <Typography variant="body2" gutterBottom>
        As a general rule, we store your data for as long as necessary for the
        performance of our online services and the services connected to them,
        or for as long as we have a legitimate interest in storing the data (for
        example, we might still have a legitimate interest in postal-mail
        marketing on fulfillment of a contract). In all other cases, we delete
        your personal data with the exception of data we must store to fulfill
        legal obligations (for example, where we are obliged by tax and
        commercial law to retain documents such as contracts and invoices for a
        certain period of time).
      </Typography>

      <Typography variant="body2" gutterBottom>
        4. Use of cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.1 General
      </Typography>

      <Typography variant="body2" gutterBottom>
        Cookies are small text files that are saved on your computer when you
        use one of our online services. Should you access this online service
        again, your browser sends the cookies&rsquo; content back to us, thus
        allowing us to re-identify your terminal device. Reading the cookies we
        have set allows us to optimize our online services for you and make it
        easier for you to use them.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.2 Disabling and deleting cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        You can however use your browser to delete all cookies at any time. Your
        browser&rsquo;s help function gives information on how to do this.
        However, this may mean that individual functions are no longer available
        to you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        On the following webpage, you can also manage and disable the use of
        cookies by third parties:
      </Typography>

      <Typography variant="body2" gutterBottom>
        http://www.youronlinechoices.com/de/praferenzmanagement
      </Typography>

      <Typography variant="body2" gutterBottom>
        As this website is not operated by us, we do not accept any
        responsibifor it and have no influence over content and availability.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3. List of the cookies we use
      </Typography>

      <Typography variant="body2" gutterBottom>
        The following section contains a list of the cookies we use.
      </Typography>

      <Typography variant="body2" gutterBottom>
        4.3.1 Essential cookies
      </Typography>

      <Typography variant="body2" gutterBottom>
        Certain cookies are essential for our online services to work properly.
        They include:
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies used to identify and authenticate our users;
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that temporarily save certain user information (such as
        the content of a shopping cart or of an online form);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save certain user preferences (such as search and
        language settings);
      </Typography>

      <Typography variant="body2" gutterBottom>
        &ndash; cookies that save data in order to ensure uninterrupted
        listening to audio content and viewing of video content.
      </Typography>

      <Typography variant="body2" gutterBottom>
        5. External links
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services may contain links to third-party websites that are
        operated by providers not connected to us. When you click these links,
        we have no influence on the collection, processing, and use of the
        personal data possibly transmitted to the third party (such as the IP
        address or the URL of the site on which the link is located), as the
        actions of third parties are by nature beyond our control. We do not
        assume any responsibility for the processing of such personal data by
        third parties.
      </Typography>

      <Typography variant="body2" gutterBottom>
        6. Duty to disclose information
      </Typography>

      <Typography variant="body2" gutterBottom>
        In the course of our business relationship with you, we require such
        personally identifiable information as is necessary for commencing and
        carrying out the business relationship and fulfilling the contractual
        duties connected with it, or that we are legally obliged to collect.
        Without such information, we will generally be unable to conclude a
        contract with you or to carry it out.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our online service, it is above all necessary for you to
        provide your IP address. Without your IP address, we cannot address your
        terminal device.
      </Typography>

      <Typography variant="body2" gutterBottom>
        When using our contact form and service chat, you have to make such
        personally identifiable information available as is we need to answer
        your request and carry out your order. Without such information, we will
        generally not be able to answer requests, carry out orders, and in some
        cases bring communications to a close.
      </Typography>

      <Typography variant="body2" gutterBottom>
        7. Security
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our associates and the companies providing services on our behalf are
        obliged to uphold confidentiality and to comply with all applicable data
        protection laws.
      </Typography>

      <Typography variant="body2" gutterBottom>
        We take all necessary technical and organizational measures to ensure an
        appropriate level of security and to protect any data of yours that we
        process from the risk of unintentional or unlawful destruction,
        manipulation, loss, change, or unauthorized disclosure or access. Our
        security measures are constantly being improved in line with
        technological developments.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8. Your rights
      </Typography>

      <Typography variant="body2" gutterBottom>
        To assert your rights, please follow the instructions provided in
        section 2 (&ldquo;Data controller and contact&rdquo;). In doing so,
        please provide us with information we can use to personally identify
        you.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.1 Right to information and access:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to obtain information from us about whether or not
        your personal data is being processed, and, where this is the case, to
        access your personal data.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.2 Right to correction and deletion:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand that we correct inaccurate personal data
        relating to you and &ndash; provided that legal requirements have been
        met &ndash; amend or delete it.
      </Typography>

      <Typography variant="body2" gutterBottom>
        This does not apply to data required for billing or accounting purposes
        or which is subject to a legal retention period. Where access to such
        data is not required, however, its processing is restricted (see the
        following).
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.3 Restriction of processing:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to demand &ndash; provided that legal requirements
        have been met &ndash; that the processing of your data be restricted.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.4 Data portability:
      </Typography>

      <Typography variant="body2" gutterBottom>
        You continue to have the right to receive the data you have provided us
        in a structured, commonly used, and machine-readable format and &ndash;
        wherever technically feasible &ndash; to demand that the data be
        transmitted to a third party.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5 Right to object
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.1 Right to object based on individual situation
      </Typography>

      <Typography variant="body2" gutterBottom>
        If we process data on the basis of an overriding justified interest of
        the kind described in this data protection notice, you have the right to
        object, on grounds relating to your particular situation, at any time to
        this processing. We will no longer process your personal data unless, in
        accordance with legal requirements, we can demonstrate compelling and
        legitimate grounds for their further processing which override your
        interests, rights, and freedoms, or if their further processing serves
        to establish, exercise, or defend legal rights.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.5.2 Objection to data processing for the purposes of direct marketing
      </Typography>

      <Typography variant="body2" gutterBottom>
        In addition, you can object at any time to the processing of your
        personal data for advertising purposes. Please note that, for
        organizational reasons, there may be an overlap between your objection
        and the use of your data in a campaign that is already running.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.6 Right to withdraw consent
      </Typography>

      <Typography variant="body2" gutterBottom>
        If you have consented to the processing of your data, you can withdraw
        this consent with future effect at any time. This also applies to
        withdrawal of consent given to us prior to the enactment of the General
        Data Protection Regulation (May 25, 2018). This does not affect the
        lawfulness of the processing of your data prior to withdrawal.
      </Typography>

      <Typography variant="body2" gutterBottom>
        8.7 Right of complaint with supervisory authority
      </Typography>

      <Typography variant="body2" gutterBottom>
        You have the right to lodge a complaint with a data protection
        authority. You can either contact the data protection authority
        responsible for your place of residence or the authority responsible for
        us. Die f&uuml;r uns zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde
        ist der Landesbeauftragte f&uuml;r Datenschutz und Informationsfreiheit
        Baden-W&uuml;rttemberg.
      </Typography>

      <Typography variant="body2" gutterBottom>
        9.Children
      </Typography>

      <Typography variant="body2" gutterBottom>
        Our online services are not aimed at children under 16 years of age.
      </Typography>

      <Typography variant="body2" gutterBottom>
        10. Changes to the data protection notice
      </Typography>

      <Typography variant="body2" gutterBottom>
        We reserve the right to amend our security and data protection measures
        where required to take account of technological developments. In such
        cases, we will amend our data protection policy accordingly. Please
        always consult the current version of our data protection policy, as
        this is subject to change.
      </Typography>

      <Typography variant="body2" gutterBottom>
        September 16, 2019
      </Typography>
    </>
  );
}

export default DataProtectionPolicy;
