import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Dialog from "@material-ui/core/Dialog";

import {
  CorporateInformation,
  LegalNotice,
  DataProtectionPolicy,
  FileViewer,
  CopyrightInformation,
} from "../index";

// import TermsOfUse from "../../assets/pdf/TermsOfUse.pdf";
// import PrivacyPolicy from "../../assets/pdf/PrivacyPolicy.pdf";
import * as axios from "axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    overflowX: "hidden",
  },
}));

const FooterTabs = ({ clickValue, open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [policyData, setPolicyData] = React.useState("");
  const [termCondData, setTermCondData] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  //   const prevProps = useRef({ clickValue, open, handleClose });
  React.useEffect(() => {
    // if (prevProps.clickValue !== props.clickValue
    if (policyData === "") {
      axios({
        url: `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/consent/privacypolicy`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          if (response.data) {
            const file = new File([response.data], "untitled", {
              type: "application/pdf",
            });

            setPolicyData(file);
          } else {
            return false;
          }
        })
        .catch(function (err) {
          //for any CORS/Error cases
          return false;
        });
    }
    if (!termCondData) {
      axios({
        url: `https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/user/consent/tnc`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          if (response.data) {
            const file = new File([response.data], "untitled", {
              type: "application/pdf",
            });

            setTermCondData(file);
          } else {
            return false;
          }
        })
        .catch(function (err) {
          //for any CORS/Error cases
          return false;
        });
    }
    {
      setValue(clickValue);
    }
  }, [clickValue]);

  return (
    <div className={classes.root}>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullWidth={true}
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="footer docs"
          >
            <Tab label="Corporate information" {...a11yProps(0)} />
            <Tab label="Privacy Policy" {...a11yProps(1)} />
            <Tab label="Legal Policy" {...a11yProps(2)} />
            <Tab label="Terms and conditions" {...a11yProps(3)} />
            <Tab label="Copyright" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          style={{ overflow: "inherit" }}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CorporateInformation />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {/* <FileViewer pdfFile={PrivacyPolicy} /> */}
            <FileViewer pdfFile={policyData} docType="policyData" />
            {/* <LegalNotice /> */}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <DataProtectionPolicy />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <FileViewer pdfFile={termCondData} docType="termCondData" />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <CopyrightInformation />
          </TabPanel>
        </SwipeableViews>
      </Dialog>
    </div>
  );
};

export default React.memo(FooterTabs);
