import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import ReactGA from "react-ga";

ReactGA.initialize("GTM-5RK9R65");

function LegalNotice() {
  useEffect(() => {
    ReactGA.event({
      category: "Legal Notice",
      action: "Viewing Legal Notice",
    });
    return () => {};
  }, []);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        <strong>Legal Notice:</strong>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <strong>&copy; Copyright</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        All rights reserved. Text, images, graphics, sound, animations, and
        videos as well as the arrangement of the same on Bosch websites are
        protected by copyright and other commercial protective rights. The
        content of these websites may not be copied, disseminated, altered, or
        made accessible to third parties for commercial purposes. In addition,
        some Bosch websites contain images that are subject to third-party
        copyrights.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Trademarks</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Unless specified otherwise, all trademarks on Bosch websites are
        protected by trademark law. This applies in particular to Bosch brands,
        nameplates, company logos, and emblems. The brands and design elements
        used on our pages are the intellectual property of Robert Bosch GmbH,
        Germany.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Disclaimer</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        These websites were compiled with the utmost care. Nonetheless, the
        accuracy or correctness of the information they contain cannot be
        guaranteed. Bosch shall not be liable for any loss or damage resulting
        directly or indirectly from use of these websites, unless caused
        intentionally or in gross negligence by Bosch.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Licensing</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        The intellectual property such as patents, marks, and copyrights
        contained on Bosch websites is protected. These websites do not grant a
        license for utilizing the intellectual property of companies of the
        Bosch Group (Bosch) or third parties.
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        <strong>Online dispute resolution (ODR)</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Information regarding online dispute resolution (ODR): The European
        Commission offers a platform for the resolution of online disputes. This
        platform is dedicated to facilitating the out-of-court settlement of
        disputes concerning contractual obligations in online sales and service
        agreements.
      </Typography>

      <Typography variant="body1" gutterBottom>
        The platform can be found at http://ec.europa.eu/consumers/odr/.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Information according to &sect; 36 Verbraucherstreitbeilegungsgesetz
        (VSBG) We do not engage in out-of-court settlements before consumer
        arbitration bodies.
      </Typography>
    </>
  );
}

export default LegalNotice;
