import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";

// import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
// import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
// import { setSnackbar } from "../../js/reducers/snackbar";
// import { setAlertDialog } from "../../js/reducers/alertDialog";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import { OAuth as OAuthActions } from "../../js/actions";
import { roleIcon } from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  avatar: {
    backgroundColor: "#01579B",
  },
  present: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
  absent: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
}));

const ManageUsers = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [dataSet, setDataSet] = React.useState([]);
  useEffect(async () => {
    await dispatch(OAuthActions.getRole()).then(function (roleDetails) {
      let newDataSet = [];
      for (let index = 0; index < roleDetails.length; index++) {
        const element = {
          userName: roleDetails[index].name,
          description: roleDetails[index].description,
          status: 1,
        };
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  }, []);

  const columns = [
    {
      title: "Role Title",
      field: "userName",
      render: (rowData) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Avatar size={40} className={classes.avatar}>
              {rowData.userName.includes("Super Admin") ? (
                <ImportantDevicesIcon />
              ) : rowData.userName.includes("Admin") ? (
                <SupervisorAccountIcon />
              ) : rowData.userName.includes("Analyst") ? (
                <PersonIcon />
              ) : rowData.userName.includes("Technician") ? (
                <BuildIcon />
              ) : null}
            </Avatar>
          </Grid>
          <Grid item>{rowData.userName}</Grid>
        </Grid>
      ),
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.userName}
          onChange={(e) => {
            rowData.onChange(e.target.value);
          }}
          size="small"
          autoFocus
        />
      ),
    },
    {
      title: "Role description",
      field: "description",
      editable: "never",
    },

    {
      width: 150,
      title: "Status",
      field: "status",

      lookup: {
        0: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.absent}
          >
            <Grid item>
              <CloseIcon />
            </Grid>
            <Grid item>De-activated</Grid>
          </Grid>
        ),
        1: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.present}
          >
            <Grid item>
              <CheckIcon />
            </Grid>
            <Grid item>Activated</Grid>
          </Grid>
        ),
      },

      editComponent: (rowData) => (
        <Select
          defaultValue={rowData.rowData.status}
          onChange={handlestatus}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={1}>Activated</MenuItem>
          <MenuItem value={0}>Deactivated</MenuItem>
        </Select>
      ),
    },
  ];

  // const handleAddRole = (event) => {
  //   props.history.push(_appConstants.useCommonDefaults.navRef.role.addRole);
  // };

  const updateData = (newData, oldData) => {
    console.log("updateData", newData, oldData);
    return true;
  };
  const onRowClick = (event, rowData) => {
    console.log("onRowClick", rowData);
    return true;
  };

  const handlestatus = (event) => {
    //
  };

  // const handlegrade = (event) => {};
  // const handlecourseName = (event) => {};

  // const handleComments = (event) => {};

  // const canceledClicked = (event) => {};

  const customTableData = {
    isReadOnly: true,
    isOnclick: true,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: updateData,
    onRowClick: onRowClick,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item p={0} m={0}>
                <img
                  src={roleIcon}
                  style={{ height: "2em", margin: "0 1em" }}
                />
              </Grid>
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h2">
                    <strong>Manage Roles:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                {/* <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddRole}
                  startIcon={<AddIcon />}
                >
                  Add Role
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageUsers;
