import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { onboardNewPlantIcon, onboardNewPlant } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";

import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";
import { useHistory } from "react-router-dom";

import { OAuth as OAuthActions } from "../../js/actions";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
}));
const OnboardNewPlant = (props) => {
  const dispatch = useDispatch();

  const [plantid, setPlantid] = useState("");
  const [plantIdHelper, setPlantIdHelper] = useState("");
  const [plantname, setPlantname] = useState("");
  const [plantNameHelper, setPlantNameHelper] = useState("");
  const [address, setAddress] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [makeId, setMakeId] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [countrySelected, setCountrySelected] = useState(false);
  const [stateSelected, setStateSelected] = useState(false);
  const [makeSelected, setMakeSelected] = useContext(Context);
  const history = useHistory();

  React.useEffect(async () => {
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //     setMakeId(makeId.uid);
    //     console.log("make", makeID);
    //   }
    // });
    await dispatch(OAuthActions.getCountry()).then(function (data) {
      if (data) {
        setCountryData(data.country);
      }
    });
  }, [makeSelected]);

  const getSelectedState = (countryUID) => {
    dispatch(OAuthActions.getState(countryUID)).then(function (data) {
      if (data) {
        setStateData(data.state);
        setTimeout(() => {
          setCountrySelected(true);
        }, 1000);
      }
    });
  };

  const getSelectedCity = (stateUID) => {
    dispatch(OAuthActions.getCity(stateUID)).then(function (data) {
      if (data) {
        setCityData(data.city);
        setTimeout(() => {
          setStateSelected(true);
        }, 1000);
      }
    });
  };

  const classes = useStyles();
  const handleChange = async (event) => {
    await setcountry(event.target.value);
    if (event.target.value) {
      getSelectedState(event.target.value);

      if (event.target.value !== country) {
        setStateSelected(false);
        setstate("");
        setcity("");
      }
    } else {
      setCountrySelected(false);
      setStateSelected(false);
      setstate("");
      setcity("");
    }
  };
  const handleState = async (event) => {
    await setstate(event.target.value);
    if (event.target.value) {
      getSelectedCity(event.target.value);

      if (event.target.value !== state) {
        setcity("");
      }
    } else {
      setStateSelected(false);
      setcity("");
    }
  };
  const handleCity = async (event) => {
    await setcity(event.target.value);
  };
  const handlePlantId = (event) => {
    setPlantid(event.target.value);
    const valid = /^[a-zA-Z0-9]+$/.test(event.target.value);
    // if (event.target.value.length == 0) {
    //   setPlantIdHelper("Enter Letter or Digits only");
    // }
    // else if (event.target.value.length > 10) {
    //   setPlantIdHelper("Plant Id length cannot be more than 10");
    // }
    // else {
    //   setPlantIdHelper("");
    // }
  };
  const handlePlantName = (event) => {
    setPlantname(event.target.value);
    const valid = /^[a-zA-Z0-9_\s]+$/.test(event.target.value);
    if (!valid && event.target.value.length !== 0) {
      setPlantNameHelper("Name contains only Alphabets,digits and underscore");
    } else {
      setPlantNameHelper("");
    }
  };
  const handleAddress = (event) => {
    setAddress(event.target.value);
  };

  const submit = () => {
    dispatch(
      OAuthActions.createPlant(
        plantid,
        plantname,
        address,
        country,
        state,
        city,
        makeSelected.uid
      )
    ).then(function (success) {
      if (success["error"] === undefined) {
        dispatch(setSnackbar(true, "success", "Add Plant Successful"));
        history.push("/dashboard");
      } else dispatch(setAlertDialog(true, null, "error", "Add Plant:", "Add plant unsuccessful.\nDetails Of Failure: " + success.message, "Ok", ""));
    });
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={onboardNewPlantIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Onboard a new plant:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} lg={2}>
            <img
              src={onboardNewPlant}
              style={{ height: "14vh", margin: "1em" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              spacing={3}
            >
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  required
                  error={plantNameHelper.length !== 0}
                  helperText={plantNameHelper}
                  id="filled-required"
                  label="Plant Name"
                  defaultValue={plantname}
                  onChange={handlePlantName}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  // required
                  // error={plantIdHelper.length !== 0}
                  //helperText={plantIdHelper}
                  id="filled-required"
                  label="Plant Id"
                  defaultValue={plantid}
                  onChange={handlePlantId}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  id="filled-multiline-static"
                  fullWidth
                  label="Address"
                  multiline
                  rows={4}
                  defaultValue={address}
                  onChange={handleAddress}
                />
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Country"
                  labelId="country-label"
                  id="country-select"
                  value={country}
                  onChange={handleChange}
                >
                  {countryData !== undefined &&
                    countryData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="State"
                  labelId="state-label"
                  id="state-select"
                  value={state}
                  onChange={handleState}
                  disabled={!countrySelected}
                >
                  {stateData !== undefined &&
                    stateData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={9} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="city"
                  labelId="city-label"
                  id="city-select"
                  value={city}
                  onChange={handleCity}
                  disabled={!stateSelected}
                >
                  {cityData !== undefined &&
                    cityData.map((data) => (
                      <MenuItem key={data.uid} value={data.uid}>
                        {data.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} xs={6} sm={12}>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={submit}
                      disabled={
                        plantname == "" ||
                        plantNameHelper.length !== 0 ||
                        plantIdHelper.length !== 0
                      }
                    >
                      Onboard New Plant
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default OnboardNewPlant;
