import * as axios from "axios";
import { OAuth as OAuthActions } from "../actions";

import TokenStorage from "./tokenStorage";
import { useDispatch } from "react-redux";

//load indicator will not show for refresh_token
let loadingIndicatorFlag = true;
// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    try {
      // if (config?.headers === undefined) {
      //
      // config.headers = {
      //   Authorization: `Bearer ${TokenStorage.getToken()}`,
      // };
      // }
      loadingIndicatorFlag =
        config.hasOwnProperty("data") &&
        config.data.includes("grant_type=refresh_token")
          ? false
          : true;
    } catch (error) {
      loadingIndicatorFlag = true;
    }

    if (loadingIndicatorFlag) document.body.classList.add("loading-indicator");
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    const dispatch = useDispatch();
    if (error.code === 401) {
      dispatch(OAuthActions.refreshOAuthToken(TokenStorage.getRefreshToken()));
    }
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (loadingIndicatorFlag)
      document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    if (loadingIndicatorFlag)
      document.body.classList.remove("loading-indicator");
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
