import React, { useState, useEffect } from "react";
import { omLogo } from "../../assets/images/";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/styles/styles";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import { OAuth as OAuthActions } from "../../js/actions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TokenStorage from "../../js/utils/tokenStorage";
// var qs = require("qs");

const CardLogin = ({ onForgotPassword }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); //Password@1
  // const [email, setEmail] = useState("super.admin@bosch.com");
  // const [password, setPassword] = useState("Password@1");
  const [emailHelper, setEmailHelper] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(async () => {
    const token = TokenStorage.getToken();
    const cookieData = TokenStorage.getMultiTabData();
    if (token) {
      axios.defaults.headers.common["Authorization"] =
        cookieData.token_type + " " + cookieData.access_token;
      await dispatch(OAuthActions.multiTabCookieHandler(cookieData));
      history.push(_appConstants.useCommonDefaults.navRef.dashboard);
    }
    // var input = document.getElementById("password");
    // input.addEventListener("keyup", function (event) {
    //   if (event.keyCode === 13) {
    //     event.preventDefault();
    //     document.getElementById("loginClick").click();
    //   }
    // });
    return () => {
      console.log("I AM HERE!!!");
    };
  }, []);
  // useSelector((state) => {
  //
  // });
  const onChange = (event) => {
    let valid;
    var input = document.getElementById("password");
    input.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("loginClick").click();
      }
    });
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          event.target.value
        );
        if (!valid && event.target.value.length !== 0) {
          setEmailHelper("Invalid email address");
        } else {
          setEmailHelper("");
        }
        break;
      case "password":
        setPassword(event.target.value);
        valid = /(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          event.target.value
        );
        if (!valid && event.target.value.length !== 0) {
          setPasswordHelper(
            "The password must contain at least 1 number,1 special character(!,@,#,$,%,^,&,*) and must be at least 8 characters in length"
          );
        } else {
          setPasswordHelper("");
        }
        break;
    }
  };
  const history = useHistory();
  const submit = () => {
    if (
      email.length &&
      password.length &&
      emailHelper.length === 0 &&
      passwordHelper.length === 0
    ) {
      dispatch(OAuthActions.getOAuthToken(email, password)).then(function (
        loginSuccess
      ) {
        if (loginSuccess["error"] === undefined) {
          console.log("loginSuccess", loginSuccess);
          dispatch(OAuthActions.getLoginUser(loginSuccess.user_uid)).then(
            function (userDetailsSuccess) {
              console.log("loginSuccess getLoginUser", userDetailsSuccess);
              if (userDetailsSuccess.make == null) {
                TokenStorage.setCurrentMakeSelected({
                  uid: "",
                  name: "Not Selected",
                });
              } else
                TokenStorage.setCurrentMakeSelected(userDetailsSuccess.make);
              history.push(_appConstants.useCommonDefaults.navRef.dashboard);

              dispatch(setSnackbar(true, "success", "Login Successful"));
            }
          ); //
        } else {
          dispatch(
            setAlertDialog(
              true,
              null,
              "error",
              "Login:",
              "Login unsuccessful. Please check your username and password. If account locked, please click on the forgot password link to reset your password.\nDetails Of Failure: " +
                loginSuccess.error_description,
              "Ok",
              ""
            )
          );
        }
      });
    } else {
      if (email.length === 0) {
        setEmailHelper("Please enter email");
      }
      if (password.length === 0) {
        setPasswordHelper("Please enter password");
      }
    }
  };

  return (
    <div className={classes.parentDiv}>
      <div className={classes.loginCard}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className={classes.cardHeader}>
              <img
                src={omLogo}
                alt="login_logo"
                className={classes.omLogoCard}
              />
            </div>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              className={classes.textfieldHeader}
              error={emailHelper.length !== 0}
              helperText={emailHelper}
              id="email"
              label="Username"
              placeholder="Enter your Email Id"
              value={email}
              onChange={(event) => onChange(event)}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              required
              error={passwordHelper.length !== 0}
              helperText={passwordHelper}
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your Password"
              value={password}
              onChange={(event) => onChange(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      // onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              fullWidth
              size="large"
              id="loginClick"
              variant="contained"
              onClick={submit}
            >
              Login
            </Button>
          </Grid>

          <Grid item>
            <Typography
              variant="h5"
              align="center"
              className={classes.pointerLink}
              onClick={onForgotPassword}
            >
              Forgot Password/New Registration?
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default React.memo(CardLogin);
