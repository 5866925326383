import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  inspectionJobReportIcon,
  createInspectionJob,
  omLogo,
  boschLogo,
  boschBanner,
} from "../../assets/images";
// import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { setSnackbar } from "../../js/reducers/snackbar";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhotoSizeSelectActualOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActualOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import IconButton from "@material-ui/core/IconButton";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as _ from "lodash";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import Viewer from "react-viewer/dist/index.js";
import * as axios from "axios";
import { FileViewer } from "../../components/index";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  PDFDownloadLink,
  BlobProvider,
  usePDF,
  pdf,
} from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
  newTable: {
    borderBottom: "none",
    padding: "2px",
  },
  spanColor: {
    marginLeft: "10px",
    color: "gray",
  },
  parentDiv: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: "999",
  },
  ok: {
    backgroundColor: "#1abc9c",
    color: "#fff",
    width: "90px",
  },
  notOk: {
    backgroundColor: "#e74c3c",
    color: "#fff",
    width: "90px",
  },
  notYet: {
    backgroundColor: "#f1c40f",
    color: "#fff",
    width: "90px",
  },
  textStyle: {
    fontWeight: 600,
  },
}));

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 40;
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  title: {
    fontSize: 24,
    margin: 12,
    textAlign: "center",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  imagetext: {
    fontSize: 12,
    margin: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    marginVertical: 15,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  text: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
    backgroundColor: "#bfbfbf",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    backgroundColor: "#bfbfbf",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

const JobDetails = (props) => {
  // const images =
  // let makeID;
  let roleID;
  const dispatch = useDispatch();
  const history = useHistory();

  // const MyDoc = (
  //   <Document>
  //     <Page>// My document data</Page>
  //   </Document>
  // );
  // const [instance, updateInstance] = usePDF({ document: MyDoc });

  const [technicians, setTechnicians] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [chaptersData, setChaptersData] = useState([]);
  const classes = useStyles();
  const userRole = useSelector((state) => state.OAuth.roles);
  const [vin, setVin] = useState("");
  const [details, setDetails] = useState({
    jobId: "",
    status: "",
    inspectionDate: "",
    year: "",
    modelName: "",
    market: "",
    plantDetails: "",
    endDate: "",
  });
  const [expanded, setExpanded] = React.useState(false);
  const [exportOptions, setExportOptions] = useState(false);
  const [exportType, setExportType] = React.useState("excel");
  const [exportValue, setExportValue] = React.useState("all");

  const [imageVisible, setImageVisible] = useState(false);
  const [inspectImages, setInspectImages] = useState({});
  const [referencePdf, setReferencePdf] = useState();
  const [onPdfClick, setOnPdfClick] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    if (panel !== "all") {
      setExpanded(isExpanded ? panel : false);
    } else {
      setExpanded(!expanded);
    }
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  if (props && props.location && props.location.rowdata === undefined) {
    history.replace("/inspection-job-report");
  }
  const [filterStatus, setFilterStatus] = useState("All");
  useEffect(async () => {
    if (props && props.location && props.location.rowdata !== undefined) {
      await dispatch(OAuthActions.getRole()).then(function (roles) {
        if (roles) {
          roleID = roles.find((c) => c.name === "Technician").uid;
        }
      });
      let technicianList = {};
      // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
      //   if (makeId) {
      //     makeID = makeId.uid;
      //   }
      // });
      await dispatch(
        OAuthActions.getJobById(props.location.rowdata.jobId)
      ).then(function (details) {
        console.log("details", details);
        technicianList = details.jobBasicDetailsDto.technicianList;
        setVin(details.vin ? details.vin : "No Vin Added");
        setDetails(details.jobBasicDetailsDto);

        const stringData = technicianList.reduce((result, item, index) => {
          return index === 0
            ? `${result}${item.firstName} ${item.lastName}`
            : `${result},${item.firstName} ${item.lastName}`;
        }, "");
        setTechnicians(stringData);

        // let newDataSet = [];
        // console.log("RefreshDataSet");
        // for (let index = 0; index < details.chapters.length; index++) {
        //   const element = {
        //     id: index,
        //     parentOnly: index,
        //     chapter: details.chapters[index].name,
        //     result: "",
        //   };
        //   newDataSet.push(element);
        //   for (
        //     let childIndex = 0;
        //     childIndex < details.chapters[index].topics.length;
        //     childIndex++
        //   ) {
        //     const innerElement = {
        //       parentId: index,
        //       topic: details.chapters[index].topics[childIndex].name,
        //       reference:
        //         details.chapters[index].topics[childIndex].manualLocation,
        //     };
        //     newDataSet.push(innerElement);
        //   }
        // }
        // console.log("newDataSet", newDataSet);
        setChapters(details.chapters);
        const chapterdeep = _.cloneDeep(details.chapters);
        setChaptersData(chapterdeep);
      });
    }
  }, []);

  // const renderImages = (details, index) => {
  //   console.log("renderAccordion", chapters);
  //   let dataSet = details.topics;
  //   return (

  //   );
  // };
  const trial = async (imageUrl) => {
    window.open(imageUrl, imageUrl.toString());
    var randomnumber = Math.floor(Math.random() * 100 + 1);
    window.open(
      "yoururl",
      "_blank",
      "PopUp" +
        randomnumber +
        ",scrollbars=1,menubar=0,resizable=1,width=850,height=500"
    );
  };
  const getImageView = async (details) => {
    // debugger;
    console.log("details getImagView", details);
    let allImageUrls = details.images;
    let images = [];

    if (allImageUrls && allImageUrls !== null)
      for (const image of allImageUrls) {
        // allImageUrls.forEach((image) => {
        console.log("allImageUrls.forEac", image);
        let axiosData = await axios({
          url: image,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            // debugger;
            if (response.data !== null) {
              if (response.data) {
                const file = new File([response.data], {
                  type: "image/jpeg",
                });
                var imageUrl = URL.createObjectURL(file);
                console.log("Data then", imageUrl);
                return imageUrl;

                //
                return imageUrl;
                // trial(imageUrl);
                // setInspectImages(images);
                // console.log("imageUrl", imageUrl);
              }
            }
          })
          .catch(function (err) {
            //for any CORS/Error cases
          });
        console.log("Data returned", axiosData);
        images.push({ src: axiosData });
      }
    console.log("Data returned images", images);

    console.log("Images from axios images", images);
    if (images.length > 0) {
      setInspectImages(images);
      setImageVisible(true);
    } else
      dispatch(setSnackbar(true, "info", "No Images Present under this topic"));
    debugger;

    // setInspectImages(images);
    // console.log("URL Data ", images);
  };
  const getPDFView = (details) => {
    if (details?._links?.manualLink?.href) {
      axios({
        url: details._links.manualLink.href,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          if (response.data) {
            const file = new File([response.data], "untitled", {
              type: "application/pdf",
            });

            setReferencePdf(file);
            setOnPdfClick(true);
          } else {
            return false;
          }
        })
        .catch(function (err) {
          //for any CORS/Error cases
          return false;
        });
    }
  };

  const getImages = async (chapterImages) => {
    let allImageUrls = chapterImages;
    let images = [];

    if (allImageUrls && allImageUrls !== null)
      for (const image of allImageUrls) {
        // allImageUrls.forEach((image) => {
        console.log("allImageUrls.forEac", image);
        let axiosData = await axios({
          url: image,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            debugger;
            if (response.data !== null) {
              if (response.data) {
                const file = new File([response.data], {
                  type: "image/jpeg",
                });
                var imageUrl = URL.createObjectURL(file);
                console.log("Data then", imageUrl);
                return imageUrl;

                //
                return imageUrl;
                // trial(imageUrl);
                // setInspectImages(images);
                // console.log("imageUrl", imageUrl);
              }
            }
          })
          .catch(function (err) {
            //for any CORS/Error cases
          });
        console.log("Data returned", axiosData);
        images.push({ src: axiosData });
      }
    return images;
  };

  const NewDocument = async () => {
    console.log("details.topics;", chapters);
    let chapterName = "";
    let allChapters = [];
    let allimages = [];
    for (const element of chapters) {
      // chapters.map((element) => {
      chapterName = element.name;
      console.log("NewDocument chapterName", chapterName);
      for (const topics of element.topics) {
        // element.topics.map((topics) => {
        console.log("NewDocument chaptersData", topics);
        let topicImages = await getImages(topics.images);
        console.log("await getImages(topics.images);", topicImages);
        let topicData = {
          Chapter: chapterName,
          topic: topics.name,
          remarks: topics.remarks,
          results:
            topics.result == null
              ? "Yet to start"
              : topics.result
              ? "Pass"
              : "Fail",
        };
        if (exportValue == "failed" && topics.result == false) {
          allChapters.push(topicData);
        } else if (exportValue == "all") {
          allChapters.push(topicData);
        }
        // });
      }
    }
    // });

    if (allChapters.length == 0) {
      dispatch(
        setSnackbar(true, "info", "No data present in the file to export")
      );
    }

    setExportOptions(false);

    return (
      <Document>
        <Page style={styles.body}>
          <Text style={styles.title}>Job Summary Report</Text>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              top: "25%",
            }}
          >
            <Text style={styles.subtitle}>Job Details:</Text>
            <View style={styles.table}>
              {/* Job ID */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Job Id :</Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{details.jobId}</Text>
                </View>
              </View>
              {/* technicians */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Technicians :</Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{technicians}</Text>
                </View>
              </View>
              {/* VIN */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>VIN Number :</Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{vin}</Text>
                </View>
              </View>
              {/* Year */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Year : </Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{details.year}</Text>
                </View>
              </View>
              {/* Model */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Model : </Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{details.modelName}</Text>
                </View>
              </View>
              {/* Market */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Market : </Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{details.market}</Text>
                </View>
              </View>
              {/* Plant */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Plant : </Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{details.plantDetails}</Text>
                </View>
              </View>
              {/* Inspection Start Date */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Inspection Start Date :</Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {details.inspectionDate
                      ? moment(details.inspectionDate).format("DD/MM/YYYY")
                      : ""}
                  </Text>
                </View>
              </View>
              {/* Inspection End Date */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Inspection End Date :</Text>
                </View>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    {details.endDate
                      ? moment(details.endDate).format("DD/MM/YYYY")
                      : ""}
                  </Text>
                </View>
              </View>
            </View>
            {/* <Text style={styles.text}>Job Id : {details.jobId} </Text>
            <Text style={styles.text}>Technicians : {technicians}</Text>
            <Text style={styles.text}>VIN : {vin}</Text>
            <Text style={styles.text}>Year : {details.year}</Text>
            <Text style={styles.text}>Model : {details.modelName}</Text>
            <Text style={styles.text}>Market : {details.market}</Text>
            <Text style={styles.text}>Plant : {details.plantDetails}</Text>
            <Text style={styles.text}>
              Inspection Start Date :{" "}
              {details.inspectionDate
                ? moment(details.inspectionDate).format("DD/MM/YYYY")
                : ""}
            </Text>
            <Text style={styles.text}>
              Inspection End Date :{" "}
              {details.endDate
                ? moment(details.endDate).format("DD/MM/YYYY")
                : ""}
            </Text> */}
          </View>
        </Page>
        <Page style={styles.body}>
          <Text style={styles.subtitle}>Vehicle Inspection Details:</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1Header}>
                <Text style={styles.tableCellHeader}>Chapter</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Topic</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Results</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Remarks</Text>
              </View>
            </View>
            {allChapters.length > 0 ? (
              allChapters.map(function (row, i) {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{row.Chapter}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row.topic}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row.results}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{row.remarks}</Text>
                    </View>
                  </View>
                );
              })
            ) : (
              <View></View>
            )}
          </View>
        </Page>
      </Document>
    );
  };

  const getPdfBlob = async () => {
    try {
      const blobPdf = await pdf(await NewDocument()).toBlob();

      return blobPdf;
    } catch (err) {
      console.log(err);
    }
  };

  const exportToPDF = async () => {
    const pdf = await getPdfBlob();
    console.log("exportToPDF pdf", pdf);
    FileSaver.saveAs(pdf, details.jobId + ".pdf");
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportData = () => {
    console.log("exportType", exportType);
    console.log("exportValue", exportValue);
    //
    console.log("chapters", chapters);
    debugger;
    if (exportType == "pdf") {
      exportToPDF();
    }
    let allChapters = [];
    let chapterName = "";
    if (exportType == "excel") {
      chapters.map((element) => {
        chapterName = element.name;
        element.topics.map((topics) => {
          console.log("chaptersData", topics);

          let topicData = {
            Chapter: chapterName,
            topic: topics.name,
            remarks: topics.remarks,
            results:
              topics.result == null
                ? "Yet to start"
                : topics.result
                ? "Pass"
                : "Fail",
          };
          if (exportValue == "failed" && topics.result == false) {
            allChapters.push(topicData);
          } else if (exportValue == "all") {
            allChapters.push(topicData);
          }
        });
      });
      if (allChapters.length > 0) {
        exportToCSV(allChapters, details.jobId);
      } else
        dispatch(
          setSnackbar(true, "info", "No data present in the file to export")
        );
    }
    setExportOptions(false);
  };

  const renderAccordion = (details, index) => {
    console.log("renderAccordion", chapters);
    chapters.forEach((element) => {
      let inspectionImages = element.topics[0].images;
      if (inspectionImages !== null)
        inspectionImages.forEach((image) => {
          console.log("iMages", image);
        });
    });
    let dataSet = details.topics;
    return (
      <Accordion
        key={index}
        expanded={expanded === `panel${index}` || expanded === true}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{details.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Topics</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>Pictures</TableCell>
                  <TableCell>Reference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSet.length > 0 ? (
                  dataSet.map(function (row, i) {
                    return (
                      <TableRow key={row.name}>
                        <TableCell>{row.name}</TableCell>
                        {
                          <TableCell>
                            {row.result !== null ? (
                              row.result === true ? (
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={1}
                                  className={classes.ok}
                                >
                                  <Grid item>
                                    <ThumbUpOutlinedIcon />
                                  </Grid>
                                  <Grid item>PASS</Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  container
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                                  spacing={1}
                                  className={classes.notOk}
                                >
                                  <Grid item>
                                    <ThumbDownAltOutlinedIcon />
                                  </Grid>
                                  <Grid item>FAIL</Grid>
                                </Grid>
                              )
                            ) : (
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                spacing={1}
                                className={classes.notYet}
                              >
                                <Grid item>Yet To Start</Grid>
                              </Grid>
                            )}
                          </TableCell>
                        }
                        <TableCell
                          className={!row.remarks ? classes.textStyle : null}
                        >
                          {row.remarks ? row.remarks : "- - - - -"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              getImageView(row);
                            }}
                          >
                            <PhotoSizeSelectActualOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              getPDFView(row);
                              // setOnPdfClick(true);
                            }}
                          >
                            <AttachFileOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    No Record to show
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <div>
        <Dialog open={exportOptions} onClose={() => setExportOptions(false)}>
          <DialogTitle
            id="export-dialog-title"
            style={{ fontWeight: "bold", color: "rgb(0, 86, 145)" }}
          >
            Export Options
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="flex-start"
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Type of document you want to export:
                </FormLabel>
                <RadioGroup
                  value={exportType}
                  onChange={(event) => setExportType(event.target.value)}
                >
                  <FormControlLabel
                    value="excel"
                    control={<Radio />}
                    label="Excel"
                  />
                  <FormControlLabel
                    value="pdf"
                    control={<Radio />}
                    label="PDF"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Show all or only failed topics:
                </FormLabel>
                <RadioGroup
                  value={exportValue}
                  onChange={(event) => setExportValue(event.target.value)}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="Show All"
                  />
                  <FormControlLabel
                    value="failed"
                    control={<Radio />}
                    label="Only Failed"
                  />
                </RadioGroup>
              </FormControl>
              {/* <a href={instance.url} download="test.pdf">
                Download
              </a> */}
            </Grid>
            {/* <BlobProvider
              document={
                <Document>
                  <Page size="A4">
                    <View>
                      <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUp2DM2rdMBklpCqGmWWp3Wf0D5n54TiA-ID01j6q0wONODJN8k6CfhGh8NVz7ykIj-tA&usqp=CAU" />
                      <Text>Name: 'AAasAA'</Text>
                      <Text>Email:'AAAAA'</Text>
                    </View>
                  </Page>
                </Document>
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </BlobProvider> */}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setExportOptions(false)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={exportData}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={onPdfClick}
          onClose={() => setOnPdfClick(false)}
          maxWidth={"lg"}
          fullWidth={true}
        >
          <FileViewer pdfFile={referencePdf} />
        </Dialog>
        <Viewer
          styles={{ zIndex: 99999 }}
          visible={imageVisible}
          onClose={() => {
            setImageVisible(false);
          }}
          images={inspectImages}
        />
      </div>
      <div className={classes.root}>
        <Grid container>
          <Grid container direction="row" justify="space-between">
            <Grid item sm={10}>
              <div style={{ display: "flex" }}>
                <img
                  src={inspectionJobReportIcon}
                  style={{ height: "2rem", margin: "0 1rem" }}
                />
                <Typography variant="h2">
                  <strong>Job Details:</strong>
                </Typography>
              </div>
              <hr className={classes.border} color="whitesmoke" />
              <br />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                startIcon={<CloudUploadOutlinedIcon />}
                onClick={() => setExportOptions(true)}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={2}>
            <img src={createInspectionJob} style={{ height: "16vh" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h1 style={{ color: "#005691", fontSize: "15px" }}>Job Details</h1>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Job Id
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {details.jobId}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      Technicians
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      <Grid container direction="row">
                        {technicians}
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                    >
                      VIN
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      {vin}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>
        <Grid
          direction="row"
          container
          style={{ backgroundColor: "whitesmoke", marginTop: "4px" }}
        >
          <h1 style={{ color: "#005691", fontSize: "15px" }}>More Details</h1>
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray", padding: "1rem" }}
                    >
                      <span className={classes.spanColor}>Model: </span>
                      {details.modelName}
                    </TableCell>
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray", padding: "1rem" }}
                    >
                      <span className={classes.spanColor}>Market: </span>
                      {details.market}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                      style={{
                        borderRight: "1px solid gray",
                        padding: "1rem",
                      }}
                    >
                      <span className={classes.spanColor}>Year: </span>
                      {details.year}
                    </TableCell>
                    <TableCell className={classes.newTable} align="left">
                      <span className={classes.spanColor}>Plant: </span>
                      {details.plantDetails}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.newTable}
                      style={{ borderRight: "1px solid gray", padding: "1rem" }}
                    >
                      <span className={classes.spanColor}>Start: </span>
                      {details.inspectionDate
                        ? moment(details.inspectionDate).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell
                      className={classes.newTable}
                      align="left"
                      style={{ borderRight: "1px solid gray", padding: "1rem" }}
                    >
                      <span className={classes.spanColor}>End: </span>
                      {details.endDate
                        ? moment(details.endDate).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          direction="row"
          justify="space-between"
          container
          style={{ backgroundColor: "whitesmoke", marginTop: "4px" }}
        >
          <h1 style={{ color: "#005691", fontSize: "15px", marginTop: "10px" }}>
            Chapter Information
          </h1>
          <Button onClick={handleChange("all")}>
            {expanded === true ? "Collapse All" : "Expand All"}
          </Button>
          <Grid style={{ marginRight: "10px" }}>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={filterStatus}
            >
              <FormControlLabel
                value="notOk"
                control={<Radio color="primary" />}
                label="View Failed "
                labelPlacement="start"
                onClick={() => {
                  setFilterStatus("notOk");
                  const data = _.cloneDeep(chapters);
                  data.forEach((a) => {
                    a.topics = a.topics.filter((g) => g.result === false);
                  });
                  console.log(data);
                  let finalData = [];
                  finalData = data.filter((a) => a.topics.length !== 0);
                  setChaptersData(finalData);
                }}
              />
              <FormControlLabel
                value="All"
                control={<Radio color="primary" />}
                label="View All"
                labelPlacement="start"
                onClick={() => {
                  setFilterStatus("All");
                  const data = _.cloneDeep(chapters);
                  setChaptersData(data);
                }}
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {chaptersData.length !== 0 ? (
            chaptersData.map(renderAccordion)
          ) : (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              No Records to Display
            </Grid>
          )}
          {/* <MaterialTable
            data={chapters}
            columns={[
              { title: "Chapter", field: "chapter" },
              { title: "Topic", field: "topic" },
              { title: "Status", field: "result" },
              { title: "Remarks", field: "type" },
              { title: "Pictures", field: "pictures" },
              { title: "Reference", field: "reference" },
            ]}
            // parentChildData={(row, rows) =>
            //   rows.find((a) => a.id === row.parentId)
            // }
            options={{
              showTitle: false,
              showFirstLastPageButtons: false,
              rowStyle: (rowData) => ({
                backgroundColor: !rowData.parentOnly ? "#EEE" : "white",
              }),
            }}
          /> */}
        </Grid>
      </div>
    </>
  );
};
export default JobDetails;
