import React, { useEffect, useState } from "react";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  scrollToTop: {
    position: "fixed",
    bottom: "1vh",
    right: "2vw",
    animation: "fadeIn 700ms ease-in-out 1s both",
    cursor: "pointer",
  },
}));
const ScrollToTop = ({ isVisible }) => {
  const classes = useStyles();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const drawerElement = document.getElementById("drawercontainer");
    if (drawerElement != null) {
      let progressBarHandler = () => {
        const totalScroll = drawerElement.scrollTop;
        // const windowHeight =   drawerElement.scrollHeight -  drawerElement.clientHeight;
        setScroll(totalScroll === 0 ? false : true);
      };

      try {
        drawerElement.addEventListener("scroll", progressBarHandler);

        return () =>
          drawerElement.removeEventListener("scroll", progressBarHandler);
      } catch (e) {}
    }
  });

  const scrollToTop = () => {
    document.getElementById("drawercontainer").scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return scroll ? (
    <div className={classes.scrollToTop}>
      <Fab color="primary" aria-label="add" onClick={scrollToTop}>
        <NavigationIcon />
      </Fab>
    </div>
  ) : (
    <></>
  );
};
export default ScrollToTop;
