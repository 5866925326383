// import logo from './logo.svg';
import "./App.css";
import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./assets/theme";
import Routes from "./Routes";
import "./js/utils/axiosInterceptor";
import { Provider } from "react-redux";
import store from "./js/store";
import AuthLayout from "./AuthLayout";
import { Snackbar, AlertDialog } from "./components/index";
const browserHistory = createBrowserHistory();
function App() {
  // var condition = navigator.onLine ? "online" : "offline";
  // if (condition === "online") {
  //   console.log("ONLINE");
  //   fetch("https://www.google.com/", {
  //     // Check for internet connectivity
  //     mode: "no-cors",
  //   })
  //     .then(() => {
  //       console.log("CONNECTED TO INTERNET");
  //     })
  //     .catch(() => {
  //       console.log("INTERNET CONNECTIVITY ISSUE");
  //     });
  // } else {
  //   console.log("OFFLINE");
  // }
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AlertDialog />
        <Snackbar />
        <Router history={browserHistory}>
          <Routes />
          <AuthLayout history={browserHistory} />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
