import React, { useState, useReducer, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { userIcon } from "../../assets/images";
import AddIcon from "@material-ui/icons/Add";
import { CustomTable } from "../../components";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import Avatar from "@material-ui/core/Avatar";

import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../js/reducers/snackbar";
import { setAlertDialog } from "../../js/reducers/alertDialog";

import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";

import { OAuth as OAuthActions } from "../../js/actions";
import { Context } from "../../js/utils/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  present: {
    backgroundColor: "#B3E5FC",
    padding: "0.75em",
    color: "#01579B",
  },
  absent: {
    backgroundColor: "#ffcdd2",
    padding: "0.75em",
    color: "#c62828",
  },
  avatar: {
    backgroundColor: "#01579B",
  },
}));

const ManageUsers = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [makeSelected, setMakeSelected] = useContext(Context);

  const [dataSet, setDataSet] = React.useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  let makeID;
  const handleClick = () => {
    forceUpdate();
  };

  const RefreshDataSet = async () => {
    // await dispatch(OAuthActions.getMakeId()).then(function (makeId) {
    //   if (makeId) {
    //     makeID = makeId.uid;
    //   }
    // });
    dispatch(OAuthActions.getUsersAll(makeSelected.uid, 0, 999)).then(function (
      userDetails
    ) {
      let newDataSet = [];
      console.log("RefreshDataSet");
      for (
        let index = 0;
        index < userDetails?._embedded?.userLists.length;
        index++
      ) {
        const element = {
          uid: userDetails?._embedded?.userLists[index].uid,
          firstName: userDetails?._embedded?.userLists[index].firstName,
          lastName: userDetails?._embedded?.userLists[index].lastName,
          email: userDetails?._embedded?.userLists[index].userName,
          status: userDetails?._embedded?.userLists[index].isActive,
          role: userDetails?._embedded?.userLists[index].role.description,
          plantDetails: userDetails?._embedded?.userLists[index].make.name,
          daysSinceLastLogin:
            userDetails?._embedded?.userLists[index].daysSinceLastLogin,
        };
        newDataSet.push(element);
      }
      setDataSet(newDataSet);
    });
  };
  React.useEffect(() => {
    RefreshDataSet();
  }, [makeSelected]);

  const updateDataSet = async (dataSet) => {
    const validFirstName = /^[a-zA-Z\s]+$/.test(dataSet.firstName);
    const validLastName = /^[a-zA-Z\s]+$/.test(dataSet.lastName);
    if (
      validFirstName &&
      dataSet.firstName.length !== 0 &&
      validLastName &&
      dataSet.lastName.length !== 0
    ) {
      const newData = {
        uid: dataSet.uid,
        firstName: dataSet.firstName,
        lastName: dataSet.lastName,
        status: dataSet.status,
      };

      await dispatch(
        OAuthActions.updateUsers(
          newData.uid,
          newData.firstName,
          newData.lastName,
          newData.status
        )
      ).then(function (userDetails) {
        if (userDetails["error"] === undefined) {
          RefreshDataSet();
          dispatch(setSnackbar(true, "success", "Update Successful"));
        } else dispatch(setAlertDialog(true, null, "error", "Update User:", "Update unsuccessful.\nDetails Of Failure: " + userDetails.message, "Ok", ""));
      });
    } else {
      dispatch(
        setAlertDialog(
          true,
          null,
          "error",
          "Update User:",
          "Please enter only Alphabets in First Name and Last Name",
          "Ok",
          ""
        )
      );
    }
    // const newData = {
    //   uid: dataSet.uid,
    //   firstName: dataSet.firstName,
    //   lastName: dataSet.lastName,
    //   status: dataSet.status,
    // };

    // await dispatch(
    //   OAuthActions.updateUsers(
    //     newData.uid,
    //     newData.firstName,
    //     newData.lastName,
    //     newData.status
    //   )
    // ).then(function (userDetails) {
    //   if (userDetails["error"] === undefined) {
    //     RefreshDataSet();
    //     dispatch(setSnackbar(true, "success", "Update Successful"));
    //   } else dispatch(setAlertDialog(true, null, "error", "Update User:", "Update unsuccessful.\nDetails Of Failure: " + userDetails.message, "Ok", ""));
    // });
  };

  const columns = [
    {
      title: "First Name",
      field: "firstName",
      render: (rowData) => (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Avatar size={40} className={classes.avatar}>
              {rowData.role.includes("Super Admin") ? (
                <ImportantDevicesIcon />
              ) : rowData.role.includes("Admin") ? (
                <SupervisorAccountIcon />
              ) : rowData.role.includes("Analyst") ? (
                <PersonIcon />
              ) : rowData.role.includes("Technician") ? (
                <BuildIcon />
              ) : null}
            </Avatar>
          </Grid>
          <Grid item>{rowData.firstName}</Grid>
        </Grid>
      ),
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.firstName}
          onChange={(e) => {
            // setFirstName(e.target.value);
            // const valid = /^[a-zA-Z]+$/.test(
            //   e.target.value
            // );
            // if (!valid && e.target.value.length !== 0) {
            //   setFirstNameHelper(true);
            // } else {
            //   setFirstNameHelper(false);
            // }
            rowData.onChange(e.target.value);
          }}
          size="small"
          autoFocus
        />
      ),
    },

    {
      title: "Last Name",
      field: "lastName",

      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.lastName}
          onChange={(e) => {
            // setLastName(e.target.value);
            rowData.onChange(e.target.value);
          }}
          size="small"
          autoFocus
        />
      ),
    },
    {
      title: "Email ID",
      field: "email",
      editable: "never",
    },
    {
      title: "Role",
      field: "role",
      editable: "never",
    },
    {
      title: "Make",
      field: "plantDetails",
      editable: "never",
      editComponent: (rowData) => (
        <TextField
          variant="outlined"
          defaultValue={rowData.rowData.plantDetails}
          onChange={handlecourseName}
          size="small"
        />
      ),
    },
    {
      title: "Last Login(days)",
      field: "daysSinceLastLogin",
      editable: "never",
      cellStyle: (e, rowData) => {
        if (rowData.daysSinceLastLogin > 60) {
          return { color: "#e74c3c", fontWeight: "bold" };
        }
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: {
        false: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.absent}
          >
            <Grid item>
              <CloseIcon />
            </Grid>
            <Grid item>De-activated</Grid>
          </Grid>
        ),
        true: (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.present}
          >
            <Grid item>
              <CheckIcon />
            </Grid>
            <Grid item>Activated</Grid>
          </Grid>
        ),
      },

      editComponent: (rowData) => (
        <Select
          defaultValue={rowData.rowData.status}
          onChange={(e) => {
            //
            rowData.onChange(e.target.value);
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={true}>Activated</MenuItem>
          <MenuItem value={false}>Deactivated</MenuItem>
        </Select>
      ),
    },
  ];

  const handleAddUser = (event) => {
    props.history.push(_appConstants.useCommonDefaults.navRef.users.addUser);
  };

  const updateData = (newData, oldData) => {
    console.log("updateData", newData, oldData);
    if (
      newData.firstName === oldData.firstName &&
      newData.lastName === oldData.lastName &&
      newData.status === oldData.status
    ) {
    } else {
      updateDataSet(newData);
    }
    return true;
  };
  const onRowClick = (event, rowData) => {
    console.log("onRowClick", rowData);
    return true;
  };

  const handlestatus = (event) => {
    //
  };

  const handlegrade = (event) => {};
  const handlecourseName = (event) => {};

  const handleComments = (event) => {};

  const canceledClicked = (event) => {};

  const customTableData = {
    isReadOnly: false,
    isOnclick: false,
    dataSet: dataSet,
    columns: columns,
    exportOption: false,
    updateData: updateData,
    onRowClick: onRowClick,
    searchState: true,
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={10}>
                <div style={{ display: "flex" }}>
                  <img
                    src={userIcon}
                    style={{ height: "2em", margin: "0 1em" }}
                  />
                  <Typography variant="h2">
                    <strong>Manage User:</strong>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleAddUser}
                  startIcon={<AddIcon />}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <hr />
            <CustomTable {...customTableData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ManageUsers;
