import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import * as d3 from "d3";
import * as _ from "lodash";
const PieComponent = forwardRef((props, ref) => {
  const width = 300;
  const height = 300;
  // const data = { New: 9, Inprogress: 20, Completed: 30, Cancelled: 8 };
  let radius;
  let svg;
  let color;
  let pie;
  let arc;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };
  const rotate = true;
  let anim;
  let eventObj;
  const definition = () => {
    // color = d3.scaleOrdinal()
    //     .domain(data)
    //     .range(["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56"]);
    let total = 0;
    const totalWidth = width + margin.left + margin.right;
    const totalHeight = height + margin.left + margin.right;
    radius = Math.min(width, height) / 2 - 20;
    color = ["#16a085", "#2980b9", "#2c3e50", "#c0392b"];
    pie = d3.pie().value(function (d) {
      return d.value;
    });
    const data_ready = pie(d3.entries(props.pieDta.deta));
    total = _.sumBy(data_ready, "value");
    data_ready.forEach((a, i) => {
      a.index = i;
      const percentage = (a.value / total) * 100;
      a.percentage = Number(percentage).toFixed(1);
    });
    arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius / 2.5);
    anim = (path, dir) => {
      path = d3.select(path);
      switch (dir) {
        case 0:
          path
            .transition()
            .duration(400)
            .ease(d3.easeBounce)
            .attr("d", (e) => {
              return arc(e);
            });
          break;

        case 1:
          path
            .transition()
            .attr(
              "d",
              d3
                .arc()
                .outerRadius(radius * 1.08)
                .innerRadius(radius / 2.5)
            )
            .style("stroke-width", 1)
            .style("opacity", 1);
          break;
      }
    };
    eventObj = {
      mouseover: (path) => {
        anim(path, 1);
      },

      mouseout: (path) => {
        if (!d3.select(path).classed("clicked")) {
          anim(path, 0);
        }
      },

      click: (path, key) => {
        const thisPath = d3.select(path);
        const clicked = thisPath.classed("clicked");
        const paths = d3.select(`#pieChart svg`).selectAll("path");
        thisPath.classed("clicked", !clicked);
        anim(thisPath, !clicked);
        paths.nodes().forEach((p) => {
          const cPath = d3.select(p);
          cPath.classed("opaque", false);
        });
        if (clicked) {
          eventObj.mouseout(path);
        } else {
          paths.nodes().forEach((p) => {
            if (p !== path) {
              anim(p, 0);
              const cPath = d3.select(p);
              cPath.classed("clicked", false);
              cPath.classed("opaque", true);
            }
          });
          thisPath.classed("opaque", clicked);
        }
        props.pieDta.changeStatus({ name: key, clicked: !clicked });
      },
      deselectall: () => {
        const paths = d3.select(`#pieChart svg`).selectAll("path");
        paths.nodes().forEach((p) => {
          anim(p, 0);
          const cPath = d3.select(p);
          cPath.classed("clicked", false);
          cPath.classed("opaque", true);
        });
        props.pieDta.changeStatus({ name: "All", clicked: false });
      },
    };
    d3.select(`#pieChart svg, #pieChart .no-data`).remove();
    svg = d3
      .select(`#pieChart`)
      .append("svg")
      .attr("width", totalWidth)
      .attr("height", totalHeight)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    const g = svg.selectAll("whatever").data(data_ready).enter().append("g");
    const path = g
      .append("path")
      .attr("d", arc)
      // .attr('fill', function (d) { return (color(d.data.key)) })
      .style("fill", (d, i) => {
        return color[i]; //  Set color to pie
      })
      .style("stroke-width", 1)
      .style("opacity", 1)
      .on("mouseover", function (d) {
        eventObj.mouseover(this);
      })
      .on("mouseout", function () {
        eventObj.mouseout(this);
      })
      .on("click", function (d) {
        eventObj.click(this, d.data.key);
      });
    g.append("text")
      .attr("transform", (d) => {
        const arcD = arc.centroid(d);
        const start = d.startAngle;
        const end = d.endAngle;
        const midAngle =
          end < Math.PI ? start / 2 + end / 2 : start / 2 + end / 2 + Math.PI;
        const rAngle1 = rotate ? -90 : 0;
        const rAngle2 = rotate ? (midAngle * 180) / Math.PI : 0;
        return `translate(${arcD[0]},${arcD[1]}) rotate(${rAngle1}) rotate(${rAngle2})`;
      })
      .attr("dy", ".35em")
      .attr("font-size", `9px`)
      .attr("fill", "#ffffff")
      .attr("dy", ".35em")
      .style("text-anchor", "middle")
      .text((d) => {
        if (d.percentage > 0) {
          const name = `${d.data.key} ${d.percentage}%`;
          return name;
        }
        return ``;
      })
      .on("click", (d) => {
        eventObj.click(path.nodes()[d.index], d.data.key);
      })
      .on("mouseover", function (d) {
        const index =
          d.data.key === "New"
            ? 0
            : d.data.key === "Inprogress"
            ? 1
            : d.data.key === "Completed"
            ? 2
            : 3;
        eventObj.mouseover(path.nodes()[index]);
      })
      .on("mouseout", function (d) {
        const index =
          d.data.key === "New"
            ? 0
            : d.data.key === "Inprogress"
            ? 1
            : d.data.key === "Completed"
            ? 2
            : 3;
        eventObj.mouseout(path.nodes()[index]);
      });
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", "1em")
      .attr("font-weight", 600)
      .attr("y", 0)
      .text("Total")
      .on("click", (d) => {
        eventObj.deselectall();
      });
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", ".80em")
      .attr("y", 15)
      .text(total);
  };
  useEffect(() => {
    definition();
  }, [props.pieDta.deta]);
  useImperativeHandle(ref, () => ({
    showAlert(string, flag) {
      definition();
      const paths = d3.select(`#pieChart svg`).selectAll("path");
      const selectedPath = paths.nodes()[parseInt(string)];
      d3.select(selectedPath).classed("clicked", true);
      if (flag) {
        eventObj.mouseover(selectedPath);
      } else {
        eventObj.mouseout(selectedPath);
      }
    },
  }));
  return (
    <>
      <div
        id="pieChart"
        style={{ width: "100%", height: "280px", position: "relative" }}
      ></div>
    </>
  );
});
export default PieComponent;
