import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { userIcon, addUser } from "../../assets/images";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    height: "100%",
    backgroundColor: "white",
    border: "2px solid whitesmoke",
  },
  parentDiv: {
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  },
  border: {
    margin: "1vh 0",
  },
}));
const AddRole = (props) => {
  const [plantid, setPlantid] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const handlePlantId = (event) => {
    setPlantid(event.target.value);
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ display: "flex" }}>
              <img
                src={userIcon}
                style={{ height: "2rem", margin: "0 1rem" }}
              />
              <Typography variant="h2">
                <strong>Add Role:</strong>
              </Typography>
            </div>
            <hr className={classes.border} color="whitesmoke" />
            <br />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" spacing={2}>
          <Grid item xs={12} sm={2}>
            <img src={addUser} style={{ height: "14em", margin: "1em" }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid
              container
              style={{ margin: 0, padding: 0 }}
              className={classes.parentDiv}
              justify="space-around"
              spacing={3}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="filled-required"
                  label="Role Title"
                  defaultValue={plantid}
                  onChange={handlePlantId}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="typography">
                    <Typography>
                      <strong>Add Role:</strong>
                    </Typography>
                  </FormLabel>
                  <br />

                  <FormGroup>
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Grid item xs={6} spacing={3}>
                        <FormControlLabel
                          spacing={1}
                          control={<Checkbox name="UPLOAD_MANUAL" />}
                          label="UPLOAD MANUAL"
                        />
                        <br />
                        <FormControlLabel
                          control={<Checkbox name="REPORTS" />}
                          label="REPORTS"
                        />
                        <br />
                        <FormControlLabel
                          control={<Checkbox name="MANAGE_PLANT" />}
                          label="MANAGE PLANT"
                        />
                        <FormControlLabel
                          control={<Checkbox name="MANAGE_USER" />}
                          label="MANAGE USER"
                        />
                        <br />
                      </Grid>
                      <Grid item xs={6} spacing={3}>
                        <FormControlLabel
                          control={<Checkbox name="MANAGE_ROLE" />}
                          label="MANAGE ROLE"
                        />
                        <br />
                        <FormControlLabel
                          control={<Checkbox name="MANAGE_INSPECTION_JOB" />}
                          label="MANAGE INSPECTION JOB"
                        />
                        <br />
                        <FormControlLabel
                          control={<Checkbox name="antoine" />}
                          label="MOBILE_APPLICATION"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Button size="large" variant="contained" disabled>
                      Register
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AddRole;
