import React, { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  Paper,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";

import {
  userIcon,
  roleIcon,
  onboardNewPlantIcon,
  createInspectionIcon,
  contentUploadIcon,
  assignTransferJobIcon,
  inspectionJobReportIcon,
  forwardArrow,
  navProfile,
} from "../../assets/images";
import PagesNavigation from "../../components/PagesNavigation/PagesNavigation";
import { OAuth as OAuthActions } from "../../js/actions";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";
import TokenStorage from "../../js/utils/tokenStorage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Context } from "../../js/utils/Context";
import { setSnackbar } from "../../js/reducers/snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  icon: {
    height: "5vh",
    width: "5vh",
    background: "white",
    borderRadius: "50%",
    margin: "1vh auto 2vh",
  },
  arrow: {
    height: "2vh",
    width: "10vw",
    margin: "1vh auto 2vh",
  },
  admin: {
    fontSize: "0.75rem",
    color: "#005691",
  },
  imageAlign: {
    transform: "translate(0px, 1vh)",
    height: "50%",
    width: "50%",
  },
  maxLength: {
    color: "white",
    overflow: "hidden",
    textoverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
    height: "3rem",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
}));
function performAction(icon) {
  console.log(icon);
}

const ConditionalLink = ({ children, to, condition }) =>
  !!condition && to ? <Link to={to}>{children}</Link> : <>{children}</>;

const Dashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [makeSelected, setMakeSelected] = useContext(Context);
  const [makeList, setMakeList] = useState([]);
  // const userRole = useSelector((state) => {
  //   debugger;
  // });
  const token = TokenStorage.getToken();
  const tokenDetails = jwt(token);
  let sidebarOptions = [];
  var a = _appConstants.menuOptions.forEach((element, index) => {
    if (index == 0) {
      sidebarOptions.push(element);
    }

    if (tokenDetails.authorities.includes(element.role)) {
      element.id = sidebarOptions.length;
      sidebarOptions.push(element);
    }
  });
  const icon = sidebarOptions;
  console.log("icon", icon);
  //debugger;
  // [
  //   {
  //     id: 1,
  //     imageDashboard: userIcon,
  //     subTitle: "Manage Users",
  //     title: "Add or edit user details",
  //     color1: "#E1061A",
  //     color2: "#BB0478",
  //     href: "manage-users",
  //   },
  //   {
  //     id: 2,
  //     imageDashboard: roleIcon,
  //     subTitle: "Manage Roles",
  //     title: "Add or update Role details",
  //     color1: "#BB0478",
  //     color2: "#4D267F",
  //     href: "manage-roles",
  //   },
  //   {
  //     id: 3,
  //     imageDashboard: onboardNewPlantIcon,
  //     subTitle: "Onboard a New Plant",
  //     title: "You can create plant here",
  //     color1: "#4D267F",
  //     color2: "#00629A",
  //     href: "onboard-new-plant",
  //   },
  //   {
  //     id: 4,
  //     imageDashboard: createInspectionIcon,
  //     subTitle: "Create Inspection Job",
  //     title: "Create inspection jobs here",
  //     color1: "#00629A",
  //     color2: "#0D99D6",
  //     href: "create-inspection-job",
  //   },
  //   {
  //     id: 5,
  //     imageDashboard: contentUploadIcon,
  //     subTitle: "Upload Content",
  //     title: "Upload Job files here",
  //     color1: "#0D99D6",
  //     color2: "#05A9B2",
  //     href: "content-upload",
  //   },
  //   {
  //     id: 6,
  //     imageDashboard: assignTransferJobIcon,
  //     subTitle: "Manage Technician",
  //     title: "View, Assign Transfer Technicians",
  //     color1: "#05A9B2",
  //     color2: "#6FB543",
  //     href: "assign-Transfer-job",
  //   },
  //   {
  //     id: 7,
  //     imageDashboard: inspectionJobReportIcon,
  //     subTitle: "Inspection Job Report",
  //     title: "View, Download Job Reports",
  //     color1: "#6FB543",
  //     color2: "#047058",
  //     href: "inspection-job-report",
  //   },
  // ];
  useEffect(() => {
    // setDetails(props.location.rowdata);
    let technicianList = {};
    try {
      const selectedMake = "";
      //  JSON.parse(
      //   JSON.stringify(JSON.parse(TokenStorage.getCurrentMakeSelected()))
      // );
      debugger;
      if (selectedMake.uid !== undefined) {
        setMakeSelected(selectedMake);
      }
      debugger;
      const make = TokenStorage.getMake();
      console.log("make Dashboard! " + selectedMake.uid);
      debugger;
      dispatch(OAuthActions.getOemDashboard(make)).then(function (flag) {
        debugger;
        console.log("Dashboard: getOemDashboard", flag);
        setMakeList(flag);
      });
      if (token && loginUserId === "") {
        const cookieData = TokenStorage.getMultiTabData();
        loginUserId = cookieData.user_uid;
      }
      dispatch(OAuthActions.getAllRole()).then(function (flag) {});

      dispatch(OAuthActions.getLoginUser(loginUserId)).then(function (flag) {});

      console.log("makeSelected.uid", JSON.stringify(makeSelected.uid));
    } catch (error) {
      console.log("error useeffect", error);
    }

    // dispatch(OAuthActions.getOems(null)).then(function (flag) {
    //   console.log("dashboard getOems", flag);
    // });
  }, []);

  let loginUserId = useSelector((state) => {
    return state?.OAuth?.authData?.user_uid || "";
  });
  const loginUserDetails = useSelector((state) => {
    let authData = state?.OAuth?.authData?.user_name || "";
    if (
      state?.OAuth?.loginUserData?.firstName !== undefined ||
      state?.OAuth?.loginUserData?.firstName !== ""
    )
      return (
        state?.OAuth?.loginUserData?.firstName +
          " " +
          state?.OAuth?.loginUserData?.lastName || ""
      );
    else return authData;
  });

  const loginRole = useSelector(
    (state) => state?.OAuth?.authData?.role.replaceAll("_", " ") || "Admin"
  );

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3" align="center" display="inline">
                  Welcome {loginUserDetails}!
                </Typography>
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <img style={{ height: "1.375rem" }} src={navProfile} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      align="center"
                      display="inline"
                    >
                      {loginRole}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {makeList.length >= 1 &&
              makeList &&
              loginRole != "UTILITY ADMIN" ? (
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Select OEM:"
                    id="model-select"
                    value={makeSelected.uid ? makeSelected.uid : ""}
                    onChange={(event) => {
                      const makeSet = makeList.filter(function (data) {
                        return data.uid == event.target.value;
                      });
                      console.log("selection:", event, event.target.value);
                      console.log("Filter", makeSet[0]);
                      let val;
                      if (makeSet[0] == undefined)
                        val = {
                          uid: "",
                          name: "Not Selected",
                        };
                      else val = makeSet[0];
                      setMakeSelected(val);

                      TokenStorage.setCurrentMakeSelected(val);
                    }}
                  >
                    {makeList.length &&
                      makeList.map((data, i) => {
                        return (
                          <MenuItem
                            key={data.uid}
                            value={data.uid}
                            name={data.name}
                          >
                            {data.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {icon.length > 1 ? (
            icon.map((value) =>
              value.id !== 0 ? (
                <Grid
                  key={value.id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={async () => {
                    if (makeSelected.uid == "") {
                      dispatch(
                        setSnackbar(
                          true,
                          "info",
                          "Please select an OEM before starting to utilize the application's features."
                        )
                      );
                    } else {
                      await dispatch(OAuthActions.ClickHandler(value.id));
                    }
                  }}
                >
                  <Link to={makeSelected.uid == "" ? "" : value.href}>
                    <Paper
                      className={classes.paper}
                      style={{
                        background: `linear-gradient(to right,  ${value.color1} 0%,${value.color2} 100%)`,
                      }}
                    >
                      <div className={classes.icon}>
                        <img
                          src={
                            require(`../../assets/images/svg/${value.icon}.svg`)
                              .default
                          }
                          className={classes.imageAlign}
                        />
                      </div>
                      <Typography variant="h4" className={classes.maxLength}>
                        <>{value.title}</>
                      </Typography>
                      <Typography variant="h6" className={classes.maxLength}>
                        {value.subTitle}
                      </Typography>
                      <img
                        className={classes.arrow}
                        src={forwardArrow}
                        onClick={() => {
                          performAction(value);
                        }}
                      />
                    </Paper>
                  </Link>
                </Grid>
              ) : (
                <></>
              )
            )
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <br />
              <br />
              {/* <Typography variant="h3" align="center" display="inline">
                Currently you do not have any access/privileges to view the
                content of this page.
              </Typography> */}
              <br />
              <br />
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {/* <Link to="https://ownermanual.bosch-automotive.com/ownersmanual/api/v1/vehicle/manual/inspection/job/mobile/app/download" target="_blank" download> */}
                    <CloudDownloadIcon
                      color={"primary"}
                      className={classes.avatar}
                      onClick={async () => {
                        await dispatch(OAuthActions.downloadAPK()).then(
                          function (response) {
                            console.log(response);
                          }
                        );
                      }}
                    />
                    {/* </Link> */}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      align="center"
                      display="inline"
                      style={{ width: "" }}
                    >
                      To download the Technician App , click on the download
                      icon.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
export default Dashboard;
